<template>
  <div class="select_opt_area" :id="'oog_area_' + cntrSeq">
    <!-- Case02. OT/FR 시작 -->
    <div class="select_opt_area mt10">
      <table class="tbl_col t2">
        <colgroup>
          <col style="width: 15%" />
          <col style="width: 15%" />
          <col style="width: 15%" />
          <col style="width: 15%" />
          <col style="width: 13%" />
          <col style="width: auto" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="col">Length</th>
            <th scope="col">Width</th>
            <th scope="col">Height</th>
            <th scope="col">Cargo Weight</th>
            <th scope="col">Tare Included</th>
            <th scope="col">SIMULATION</th>
          </tr>
          <tr>
            <td class="vat">
              <e-input-number
                :value="oogInfo.orgLength"
                :is-comma="true"
                :input-class="'col_8'"
                :id="'orgLength_' + cntrSeq"
                maxlength="6"
                @input="checkSize('orgLength', ...arguments)"
              >
              </e-input-number>
              ㎝
              <div v-if="overLength !== '' && overLength > 0" class="txt_size">
                Over Size: (F) +{{ setComma(overLength) }}㎝<br />(B) +{{
                  setComma(overLength)
                }}㎝
              </div>
            </td>
            <td class="vat">
              <e-input-number
                :value="oogInfo.orgWidth"
                :is-comma="true"
                :input-class="'col_8'"
                :id="'orgWidth_' + cntrSeq"
                maxlength="6"
                @input="checkSize('orgWidth', ...arguments)"
              >
              </e-input-number>
              ㎝
              <div v-if="overWidth !== '' && overWidth > 0" class="txt_size">
                Over Size: (L) +{{ setComma(overWidth) }}㎝<br />(R) +{{
                  setComma(overWidth)
                }}㎝
              </div>
            </td>
            <td class="vat">
              <e-input-number
                :value="oogInfo.orgHeight"
                :is-comma="true"
                :input-class="'col_8'"
                :id="'orgHeight_' + cntrSeq"
                maxlength="6"
                @input="checkSize('orgHeight', ...arguments)"
              >
              </e-input-number>
              ㎝
              <div v-if="overHeight !== '' && overHeight > 0" class="txt_size">
                Over Size: +{{ setComma(overHeight) }}㎝
              </div>
            </td>
            <td class="vat">
              <e-input-number
                :id="'frgtWt_' + cntrSeq"
                :value="oogInfo.frgtWt"
                :is-comma="true"
                :input-class="'col_8'"
                :point="3"
                maxlength="10"
                @input="checkValidationOOG('frgtWt', ...arguments)"
              >
              </e-input-number>
              kg
            </td>
            <td class="vat tac">
              <input
                :id="'tareWtYn_' + cntrSeq"
                :checked="oogInfo.tareWtYn === 'Y'"
                type="checkbox"
                true-value="Y"
                false-value="N"
                @change="handleChange('tareWtYn', $event.target.checked)"
              />
              <label :for="'tareWtYn_' + cntrSeq" />
            </td>
            <td rowspan="3">
              <div class="img_simulation">
                <div :class="'div_simulation div_' + simulation"></div>
              </div>
              <p
                v-if="$ekmtcCommon.isNotEmpty(deadSpace)"
                class="text_center mt5"
              >
                Dead Space : <span class="font_medium">{{ deadSpace }}TEU</span>
              </p>
            </td>
          </tr>
          <tr>
            <th scope="col">ITEM Q’TY</th>
            <th scope="col">Stowage Request</th>
            <th scope="col">Break Bulk</th>
            <th scope="col" colspan="2">Commodity</th>
          </tr>
          <tr>
            <td>
              <e-input-number
                :id="'itemQty_' + cntrSeq"
                :value="oogInfo.itemQty"
                :is-comma="true"
                :input-class="'itemQty'"
                @input="checkValidationOOG('itemQty', ...arguments)"
              >
              </e-input-number>
            </td>
            <td>
              <select
                :id="'underDckCd_' + cntrSeq"
                :value="oogInfo.underDckCd"
                @change="setContainer('underDckCd', $event.target.value)"
              >
                <option
                  v-for="cvo in unserDckList"
                  :key="cvo.cd"
                  :value="cvo.cd"
                >
                  {{ cvo.cdNm }}
                </option>
              </select>
            </td>
            <td>
              <select
                :value="oogInfo.bulkYn"
                :id="'bulkYn_' + cntrSeq"
                @change="checkValidationOOG('bulkYn', ...arguments)"
              >
                <option value="">{{ $t("msg.CSBK100.062") }}</option>
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td colspan="2">
              <e-auto-complete-commodity
                :ctr-cd="usrCtrCd"
                :cd-nm="oogInfo.cmdtDsc"
                class="auto-area position_relative"
                :id="'cmdtDsc_' + cntrSeq"
                @change="changeCmdtDsc"
                @input="checkValidationOOG('cmdtDsc')"
                :focus-force="true"
              />
            </td>
          </tr>
        </tbody>
        <tbody v-if="saveFlag !== 'PICKUP'">
          <tr>
            <th scope="col">File Upload</th>
            <td colspan="2">
              <div class="filebox">
                <a
                  @click="uploadPopup()"
                  :class="
                    $ekmtcCommon.isEmpty(oogInfo.reqRno)
                      ? 'button sm gray file'
                      : 'button sm blue'
                  "
                >
                  {{ $t("msg.CSBK100.205") }}
                </a>
              </div>
            </td>
            <th scope="col">
              <span class="span_blue">Proper Lashing Method</span>
            </th>
            <td colspan="3">
              <div class="filebox down_btn_area">
                <a
                  @click.prevent="oogFileDown(oogInfo.cmdtCd)"
                  class="button sm gray file"
                >
                  {{ $t("msg.CSBK100.206") }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="col">Request Remark</th>
            <td colspan="5">
              <textarea
                :value="oogInfo.itemCont"
                @input="setContainer('itemCont', $event.target.value)"
              >
              </textarea>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="desc_list mt0">
                <ul>
                  <li>
                    {{ $t("msg.CSBK100.207") }}<br />
                    {{ $t("msg.CSBK100.207_1") }}<br />
                    {{ $t("msg.CSBK100.207_2") }}<br />
                    {{ $t("msg.CSBK100.208") }}<br />
                    {{ $t("msg.CSBK100.208_1") }}
                  </li>
                  <li>{{ $t("msg.CSBK100.209") }}</li>
                  <li>{{ $t("msg.CSBK100.210") }}</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-show="stsAndAppNoYn !== 'N'">
            <th scope="row">Status</th>
            <td colspan="2">
              <span class="span_scg">{{ oogInfo.apvStsNm }}</span>
            </td>
            <th scope="row">Ref No.</th>
            <td colspan="2">
              <span class="span_scg">{{ oogInfo.scgAppNo }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">KMTC Remark</th>
            <td colspan="5" id="apvCont">
              <textarea
                class="txt_border_none"
                v-model="oogInfo.apvCont"
                readonly
              >
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Case02. OT/FR 끝 -->
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePoup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import oogs from '@/api/rest/trans/oogs'
import EInputNumber from '@/components/common/EInputNumber'
export default {
  name: 'BookingTableOog',
  components: {
    EInputNumber,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'dg-oog-certi-pop': () => import('@/pages/trans/popup/DgOogCertiPop'),
    'e-auto-complete-commodity': () => import('@/components/common/EAutoCompleteCommodity'),
    'oog-lashing-pop': () => import('@/pages/trans/popup/OogLashingPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: null
    },
    kmdVslCheck: {
      type: String,
      default: 'N'
    },
    emptyFlag: {
      type: String,
      default: 'F'
    },
    lang: {
      type: String,
      default: ''
    },
    cgoTypCd: {
      type: String,
      default: ''
    },
    saveFlag: {
      type: String,
      default: ''
    },
    cntrTypCd: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: null
    },
    stsAndAppNoYn: {
      type: String,
      default: ''
    },
    grsWtTon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      popParams: {},
      selectFunc: null,
      customComponent: null,
      commonCodes: {},
      cmdtCdOogList: [],
      cmdtCdOogDscList: [],
      unserDckList: [],
      overLength: '',
      overWidth: '',
      overHeight: '',
      cntrSeq: '',
      oldCgoTypCd: '',
      oldEmptyFlag: '',
      oldCntrTypCd: '',
      simulation: '',
      deadSpace: '',
      oogInfo: {
        idx: '',
        orgLength: '',
        orgWidth: '',
        orgHeight: '',
        itemQty: '',
        underDckCd: '',
        bulkYn: 'N',
        oogCmdtNm: '',
        frgtWt: '',
        tareWtYn: 'N',
        cmdtCd: '',
        itemCont: '',
        apvStsCd: '',
        apvStsNm: '',
        reqRno: ''
      },
      overSizeInfo: {
        FR20: { length: 560, width: 243, height: 221 },
        FR40: { length: 1177, width: 243, height: 195 },
        OT20: { length: 588, width: 234, height: 231 },
        OT40: { length: 1204, width: 235, height: 234 }
      },
      oldGrsWtTon: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    async parentInfo () {
      console.log('@@@@ watch parentInfo')
      await this.initPage()
    },
    cgoTypCd () {
      if (this.cgoTypCd !== this.oldCgoTypCd) {
        this.resetData()
      }
    },
    emptyFlag () {
      if (this.emptyFlag !== this.oldEmptyFlag) {
        this.resetData()
      }
    },
    cntrTypCd () {
      if (this.cntrTypCd !== this.oldCntrTypCd) {
        this.resetData()
      }
    },
    grsWtTon () {
      if (this.grsWtTon !== this.oldGrsWtTon) {
        this.oldGrsWtTon = this.grsWtTon

        if (this.cgoTypCd === '03' &&
          this.cntrTypCd === 'HC' &&
          this.cntrSzCd === '45') {
          this.oogInfo.frgtWt = this.grsWtTon * 1000
        }
      }
    }
  },
  async created () {
    console.log('@@@@ created')
    const arrCdId = ['VS081', '01208']
    this.commonCodes = await commons.getCommonsCodesCommon(arrCdId)
    if (
      this.commonCodes['01208'] !== undefined &&
      this.commonCodes['01208'] !== null
    ) {
      this.unserDckList = await this.commonCodes['01208'].filter((vo) => {
        return vo.cd === '01' || vo.cd === '02'
      })
    }
    this.usrCtrCd = this.memberDetail.userCtrCd
    await this.initPage()
  },
  destroyed () {
    console.log('@@@@ destroyed')
  },
  methods: {
    async initPage () {
      const tempCmdtCdOogList = this.commonCodes.VS081

      if (tempCmdtCdOogList !== undefined && tempCmdtCdOogList !== null) {
        this.cmdtCdOogList = tempCmdtCdOogList.filter((item1, idx) => {
          return (
            tempCmdtCdOogList.findIndex((item2) => {
              return item1.cdNm === item2.cdNm
            }) === idx
          )
        })
      }

      this.$nextTick(async () => {
        await this.initParam()
      })
    },
    async initParam () {
      const parentOog = {
        orgLength: this.parentInfo.orgLength || '',
        orgWidth: this.parentInfo.orgWidth || '',
        orgHeight: this.parentInfo.orgHeight || '',
        itemQty: this.parentInfo.itemQty || '',
        underDckCd: this.parentInfo.underDckCd || '',
        bulkYn: this.parentInfo.bulkYn || 'N',
        oogCmdtNm: this.parentInfo.oogCmdtNm || '',
        frgtWt: this.parentInfo.frgtWt || '',
        tareWtYn: this.parentInfo.tareWtYn || 'N',
        cmdtCd: this.parentInfo.cmdtCd || '',
        cmdtDsc: this.parentInfo.cmdtDsc || '',
        itemCont: this.parentInfo.itemCont || '',
        apvStsCd: this.parentInfo.apvStsCd || '',
        apvStsNm: this.parentInfo.apvStsNm || '',
        scgAppNo: this.parentInfo.scgAppNo || '',
        apvCont: this.parentInfo.apvCont || '',
        reqRno: this.parentInfo.reqRno || ''
      }

      this.simulation = this.parentInfo._simulation || ''
      this.deadSpace = this.parentInfo._deadSpace || ''

      this.overLength = this.parentInfo._overLength || ''
      this.overWidth = this.parentInfo._overWidth || ''
      this.overHeight = this.parentInfo._overHeight || ''

      console.log('initParam parentOog >>> ', parentOog)

      // this.cntrSeq = this.parentInfo.cntrSeq
      this.cntrSeq = this.index + 1
      this.cntrTypCd = this.parentInfo.cntrTypCd
      this.cntrSzCd = this.parentInfo.cntrSzCd
      this.oogInfo = parentOog

      if (
        this.lang === 'KOR' &&
        this.$ekmtcCommon.isEmpty(this.parentInfo.underDckCd)
      ) {
        this.oogInfo.underDckCd = this.kmdVslCheck === 'Y' ? '02' : '01'
      } else if (this.$ekmtcCommon.isEmpty(this.parentInfo.underDckCd)) {
        this.oogInfo.underDckCd = '01'
      }

      if (
        this.oogInfo.orgLength !== '' &&
        this.oogInfo.orgWidth !== '' &&
        this.oogInfo.orgHeight !== ''
      ) {
        // this.checkSize()
        this.getSimulation()
      }

      if (this.cgoTypCd === '03' &&
        this.cntrTypCd === 'HC' &&
        this.cntrSzCd === '45') {
        this.oogInfo.frgtWt = this.grsWtTon * 1000

        $('.itemQty').eq(this.index).attr('disabled', true)
        $('[id*=orgLength_]').eq(this.index).attr('disabled', true)
        $('[id*=orgWidth_]').eq(this.index).attr('disabled', true)
        $('[id*=orgHeight_]').eq(this.index).attr('disabled', true)
      } else {
        $('.itemQty').eq(this.index).attr('disabled', false)
        $('[id*=orgLength_]').eq(this.index).attr('disabled', false)
        $('[id*=orgWidth_]').eq(this.index).attr('disabled', false)
        $('[id*=orgHeight_]').eq(this.index).attr('disabled', false)
      }
    },
    getSimulation () {
      if (this.cntrTypCd !== 'SR') {
        const cntrInfo = this.cntrTypCd + this.cntrSzCd
        console.log('@@@@ cntrInfo: ', cntrInfo)
        const overSizeInfo = this.overSizeInfo[cntrInfo]
        console.log('@@@@ overSizeInfo: ', overSizeInfo)
        let val = null
        val = this.oogInfo.orgLength
        if (Number(val) > overSizeInfo.length) {
          this.overLength = Math.round((val - overSizeInfo.length) / 2)
        } else {
          this.overLength = 0
        }

        val = this.oogInfo.orgWidth
        if (Number(val) > overSizeInfo.width) {
          this.overWidth = Math.round((val - overSizeInfo.width) / 2)
        } else {
          this.overWidth = 0
        }

        val = this.oogInfo.orgHeight
        console.log('@@@@ Number(val):', Number(val))
        if (Number(val) > overSizeInfo.height) {
          this.overHeight = val - overSizeInfo.height
        } else {
          this.overHeight = 0
        }

        if (this.cntrTypCd === 'FR') {
          if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) === 0 &&
            Number(this.overHeight) === 0
          ) {
            this.simulation = 'ol'
            this.deadSpace = '2'
          } else if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) === 0
          ) {
            this.simulation = 'owol'
            this.deadSpace = this.cntrSzCd === '20' ? '4' : '10'
          } else if (
            Number(this.overLength) === 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) === 0
          ) {
            this.simulation = 'ow'
            this.deadSpace = this.cntrSzCd === '20' ? '2' : '4'
          } else if (
            Number(this.overLength) === 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'ohow'
            this.deadSpace = this.cntrSzCd === '20' ? '5' : '10'
          } else if (
            Number(this.overLength) === 0 &&
            Number(this.overWidth) === 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'oh'
            this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
          } else if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) === 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'ohol'
            this.deadSpace = this.cntrSzCd === '20' ? '5' : '6'
          } else if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'ohowol'
            this.deadSpace = this.cntrSzCd === '20' ? '9' : '22'
          } else {
            this.simulation = ''
            this.deadSpace = ''
          }
        } else if (this.cntrTypCd === 'OT') {
          if (Number(this.overHeight) > 0) {
            this.simulation = 'open'
            this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
          } else {
            this.simulation = ''
            this.deadSpace = ''
          }
        }
      }
    },
    resetData () {
      this.oldCgoTypCd = this.cgoTypCd
      this.oldEmptyFlag = this.emptyFlag
      this.oldCntrTypCd = this.cntrTypCd

      this.checkSize('orgLength')
      this.checkSize('orgWidth')
      this.checkSize('orgHeight')

      this.checkValidationOOGAll()
    },
    checkValidationOOGAll () {
      console.log('@@@@ enter checkValidationOOGAll')
      let prefix = []

      if (this.cgoTypCd === '03' &&
        this.cntrTypCd === 'HC' &&
        this.cntrSzCd === '45') {
        prefix = [
          'frgtWt',
          'bulkYn',
          'cmdtDsc'
        ]
      } else {
        prefix = [
          'orgLength',
          'orgWidth',
          'orgHeight',
          'frgtWt',
          'itemQty',
          'bulkYn',
          'cmdtDsc'
        ]
      }

      let isOk = true
      prefix.forEach((el) => {
        if (!this.checkValidationOOG(el)) {
          isOk = false
        }
      })
      console.log('@@@@ isOk: ', isOk)
      return isOk
    },
    checkValidationOOG (prefix, payload) {
      console.log('@@@@ enter checkValidationOOG')
      console.log('@@@@ prefix: ', prefix)
      console.log('@@@@ payload: ', payload)
      let isOk = true
      // const cntrSeq = this.cntrSeq
      const cntrSeq = this.index + 1
      const frm = document.querySelector('#frm')
      const id = prefix + '_' + cntrSeq
      const selector = frm.querySelector('#' + id)

      const cntrSzCd = this.cntrSzCd
      const cntrTypCd = this.cntrTypCd
      const cgoTypCd = this.cgoTypCd
      const emptyFlag = this.emptyFlag
      const oogInfo = this.oogInfo
      if (payload !== undefined) {
        oogInfo[prefix] = payload
      }
      console.log('@@@@ oogInfo: ', oogInfo)

      this.$ekmtcCommon.hideErrorTooltip(selector)
      let isLengthError = false
      let isWidthError = false

      // FLAT RACK 일 경우 세가지를 한번에 처리
      if (cntrTypCd === 'FR') {
        //
        if (
          (prefix === 'orgLength' ||
            prefix === 'orgWidth' ||
            prefix === 'orgHeight') &&
          this.$ekmtcCommon.isNotEmpty(selector.value) &&
          oogInfo.orgHeight !== '' &&
          oogInfo.orgLength !== '' &&
          oogInfo.orgWidth !== ''
        ) {
          //
          console.log('@@@@ 조건 통과')
          if (prefix === 'orgLength') {
            if (
              emptyFlag !== 'E' &&
              this.$ekmtcCommon.isEmpty(selector.value)
            ) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('msg.CSBK100.147')
              )
              isOk = false
            }

            if (cntrTypCd === 'FR' && this.kmdVslCheck === 'N') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 560) {
                  this.$ekmtcCommon.showErrorTooltip(
                    selector,
                    this.$t('msg.CSBK100.199')
                  ) // 'Length(cm)은 560까지 입력 가능합니다.'
                  isOk = false
                  isLengthError = true
                }
              } else if (Number(oogInfo.orgLength) > 1177) {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('msg.CSBK100.200')
                ) // 'Length(cm)은 1,177까지 입력 가능합니다.'
                isOk = false
                isLengthError = true
              }
            }

            if (cntrTypCd === 'OT') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 588) {
                  this.$ekmtcCommon.showErrorTooltip(
                    selector,
                    this.$t('msg.CSBK100.201')
                  ) // 'Length(cm)은 588까지 입력 가능합니다.'
                  isOk = false
                  isLengthError = true
                }
              } else {
                if (Number(oogInfo.orgLength) > 1204) {
                  this.$ekmtcCommon.showErrorTooltip(
                    selector,
                    this.$t('msg.CSBK100.202')
                  ) // 'Length(cm)은 1,204까지 입력 가능합니다.'
                  isOk = false
                  isLengthError = true
                }
              }
            }
          }

          if (prefix === 'orgWidth') {
            if (
              emptyFlag !== 'E' &&
              this.$ekmtcCommon.isEmpty(selector.value)
            ) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('msg.CSBK100.147')
              )
              isOk = false
            }

            if (cntrTypCd === 'OT') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgWidth) > 234) {
                  console.log('width here1')
                  this.$ekmtcCommon.showErrorTooltip(
                    selector,
                    this.$t('msg.CSBK100.203')
                  )
                  isOk = false
                  isWidthError = true
                }
              } else {
                if (Number(oogInfo.orgWidth) > 235) {
                  console.log('width here2')
                  this.$ekmtcCommon.showErrorTooltip(
                    selector,
                    this.$t('msg.CSBK100.204')
                  )
                  isOk = false
                  isWidthError = true
                }
              }
            }
          }

          if (
            prefix === 'orgHeight' &&
            this.$ekmtcCommon.isNotEmpty(selector.value)
          ) {
            let flagCnt = 0
            if (cgoTypCd === '02' && emptyFlag !== 'E') {
              // OOG
              if (cntrTypCd === 'FR') {
                if (cntrSzCd === '20') {
                  if (Number(oogInfo.orgLength) > 560) {
                    flagCnt++
                  }
                  if (Number(oogInfo.orgWidth) > 243) {
                    flagCnt++
                  }
                  if (Number(oogInfo.orgHeight) > 221) {
                    flagCnt++
                  }
                } else {
                  if (Number(oogInfo.orgLength) > 1177) {
                    flagCnt++
                  }
                  if (Number(oogInfo.orgWidth) > 243) {
                    flagCnt++
                  }
                  if (Number(oogInfo.orgHeight) > 195) {
                    flagCnt++
                  }
                }
              } else if (cntrTypCd === 'OT') {
                if (cntrSzCd === '20') {
                  if (Number(oogInfo.orgHeight) > 231) {
                    flagCnt++
                  }
                } else {
                  if (Number(oogInfo.orgHeight) > 234) {
                    flagCnt++
                  }
                }
              }
              console.log('@@@@ flagCnt: ', flagCnt)
              if (flagCnt === 0) {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  'Please check cargo size again! (Out of-gauge cargo)'
                )
                isOk = false
              } else {
                if (payload) {
                  console.log('@@@@ hide 처리')
                  const length = frm.querySelector('#orgLength_' + cntrSeq)
                  isLengthError || this.$ekmtcCommon.hideErrorTooltip(length)
                  const width = frm.querySelector('#orgWidth_' + cntrSeq)
                  isWidthError || this.$ekmtcCommon.hideErrorTooltip(width)
                  const height = frm.querySelector('#orgHeight_' + cntrSeq)
                  this.$ekmtcCommon.hideErrorTooltip(height)
                }
              }
            } else if (cgoTypCd === '03' && emptyFlag !== 'E') {
              const length = frm.querySelector('#orgLength_' + cntrSeq)
              const width = frm.querySelector('#orgWidth_' + cntrSeq)
              const height = frm.querySelector('#orgHeight_' + cntrSeq)
              if (cntrTypCd === 'FR') {
                if (cntrSzCd === '20') {
                  if (Number(oogInfo.orgLength) > 560) {
                    this.$ekmtcCommon.showErrorTooltip(
                      length,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgWidth) > 243) {
                    this.$ekmtcCommon.showErrorTooltip(
                      width,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgHeight) > 221) {
                    this.$ekmtcCommon.showErrorTooltip(
                      height,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                } else {
                  if (Number(oogInfo.orgLength) > 1177) {
                    this.$ekmtcCommon.showErrorTooltip(
                      length,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgWidth) > 243) {
                    this.$ekmtcCommon.showErrorTooltip(
                      width,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgHeight) > 195) {
                    this.$ekmtcCommon.showErrorTooltip(
                      height,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                }
              } else if (cntrTypCd === 'OT') {
                if (cntrSzCd === '20') {
                  if (Number(oogInfo.orgLength) > 588) {
                    this.$ekmtcCommon.showErrorTooltip(
                      length,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgWidth) > 234) {
                    this.$ekmtcCommon.showErrorTooltip(
                      width,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgHeight) > 231) {
                    this.$ekmtcCommon.showErrorTooltip(
                      height,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                } else {
                  if (Number(oogInfo.orgLength) > 1204) {
                    this.$ekmtcCommon.showErrorTooltip(
                      length,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgWidth) > 235) {
                    this.$ekmtcCommon.showErrorTooltip(
                      width,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                  if (Number(oogInfo.orgHeight) > 234) {
                    this.$ekmtcCommon.showErrorTooltip(
                      height,
                      'Please check cargo size again! (In-gauge cargo)'
                    )
                    isOk = false
                  }
                }
              }
            }
          }
        } else {
          // 조건 미통과 시 모든 에러 제거
          console.log('@@@@ 조건 미통과')
          console.log('@@@@ oogInfo.orgHeight: ', oogInfo.orgHeight)
          console.log('@@@@ oogInfo.orgLength: ', oogInfo.orgLength)
          console.log('@@@@ oogInfo.orgWidth: ', oogInfo.orgWidth)
          const length = frm.querySelector('#orgLength_' + cntrSeq)
          const width = frm.querySelector('#orgWidth_' + cntrSeq)
          const height = frm.querySelector('#orgHeight_' + cntrSeq)
          if (oogInfo.orgHeight === '' && oogInfo.orgLength === '' && oogInfo.orgWidth === '') {
            isOk = false

            this.$ekmtcCommon.showErrorTooltip(length, this.$t('msg.CSBK100.147'))
            this.$ekmtcCommon.showErrorTooltip(width, this.$t('msg.CSBK100.147'))
            this.$ekmtcCommon.showErrorTooltip(height, this.$t('msg.CSBK100.147'))
          } else {
            console.log('@@@@ length: ', length)
            this.$ekmtcCommon.hideErrorTooltip(length)
            console.log('@@@@ width: ', length)
            this.$ekmtcCommon.hideErrorTooltip(width)
            console.log('@@@@ height: ', length)
            this.$ekmtcCommon.hideErrorTooltip(height)
          }
        }
      } else {
        //
        if (prefix === 'orgLength') {
          if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.147')
            )
            isOk = false
          }

          if (cntrTypCd === 'FR' && this.kmdVslCheck === 'N') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 560) {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('msg.CSBK100.199')
                ) // 'Length(cm)은 560까지 입력 가능합니다.'
                isOk = false
                isLengthError = true
              }
            } else if (Number(oogInfo.orgLength) > 1177) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                this.$t('msg.CSBK100.200')
              ) // 'Length(cm)은 1,177까지 입력 가능합니다.'
              isOk = false
              isLengthError = true
            }
          }

          if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 588) {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('msg.CSBK100.201')
                ) // 'Length(cm)은 588까지 입력 가능합니다.'
                isOk = false
                isLengthError = true
              }
            } else {
              if (Number(oogInfo.orgLength) > 1204) {
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('msg.CSBK100.202')
                ) // 'Length(cm)은 1,204까지 입력 가능합니다.'
                isOk = false
                isLengthError = true
              }
            }
          }
        }
        console.log('@@@@ checkValidationOOG return 1')
        console.log('@@@@ isOk: ', isOk)

        if (prefix === 'orgWidth') {
          if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.147')
            )
            isOk = false
          }

          if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgWidth) > 234) {
                console.log('width here1')
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('msg.CSBK100.203')
                )
                isOk = false
                isWidthError = true
              }
            } else {
              if (Number(oogInfo.orgWidth) > 235) {
                console.log('width here2')
                this.$ekmtcCommon.showErrorTooltip(
                  selector,
                  this.$t('msg.CSBK100.204')
                )
                isOk = false
                isWidthError = true
              }
            }
          }
        }
        console.log('@@@@ checkValidationOOG return 2')
        console.log('@@@@ isOk: ', isOk)

        // if ((prefix === 'orgLength' || prefix === 'orgWidth' || prefix === 'orgHeight') && this.$ekmtcCommon.isNotEmpty(selector.value)) {
        if (
          prefix === 'orgHeight' &&
          this.$ekmtcCommon.isNotEmpty(selector.value)
        ) {
          let flagCnt = 0
          if (cgoTypCd === '02' && emptyFlag !== 'E') {
            // OOG
            if (cntrTypCd === 'FR') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 560) {
                  flagCnt++
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  flagCnt++
                }
                if (Number(oogInfo.orgHeight) > 221) {
                  flagCnt++
                }
              } else {
                if (Number(oogInfo.orgLength) > 1177) {
                  flagCnt++
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  flagCnt++
                }
                if (Number(oogInfo.orgHeight) > 195) {
                  flagCnt++
                }
              }
            } else if (cntrTypCd === 'OT') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgHeight) > 231) {
                  flagCnt++
                }
              } else {
                if (Number(oogInfo.orgHeight) > 234) {
                  flagCnt++
                }
              }
            }
            console.log('@@@@ flagCnt: ', flagCnt)
            if (flagCnt === 0) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                'Please check cargo size again! (Out of-gauge cargo)'
              )
              isOk = false
            } else {
              if (payload) {
                console.log('@@@@ hide 처리')
                // const length = frm.querySelector('#orgLength_' + cntrSeq)
                // isLengthError || this.$ekmtcCommon.hideErrorTooltip(length)
                // const width = frm.querySelector('#orgWidth_' + cntrSeq)
                // isWidthError || this.$ekmtcCommon.hideErrorTooltip(width)
                const height = frm.querySelector('#orgHeight_' + cntrSeq)
                this.$ekmtcCommon.hideErrorTooltip(height)
              }
            }
          } else if (cgoTypCd === '03' && emptyFlag !== 'E') {
            const length = frm.querySelector('#orgLength_' + cntrSeq)
            const width = frm.querySelector('#orgWidth_' + cntrSeq)
            const height = frm.querySelector('#orgHeight_' + cntrSeq)
            if (cntrTypCd === 'FR') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 560) {
                  this.$ekmtcCommon.showErrorTooltip(
                    length,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  this.$ekmtcCommon.showErrorTooltip(
                    width,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 221) {
                  this.$ekmtcCommon.showErrorTooltip(
                    height,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
              } else {
                if (Number(oogInfo.orgLength) > 1177) {
                  this.$ekmtcCommon.showErrorTooltip(
                    length,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  this.$ekmtcCommon.showErrorTooltip(
                    width,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 195) {
                  this.$ekmtcCommon.showErrorTooltip(
                    height,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
              }
            } else if (cntrTypCd === 'OT') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 588) {
                  this.$ekmtcCommon.showErrorTooltip(
                    length,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 234) {
                  this.$ekmtcCommon.showErrorTooltip(
                    width,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 231) {
                  this.$ekmtcCommon.showErrorTooltip(
                    height,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
              } else {
                if (Number(oogInfo.orgLength) > 1204) {
                  this.$ekmtcCommon.showErrorTooltip(
                    length,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 235) {
                  this.$ekmtcCommon.showErrorTooltip(
                    width,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 234) {
                  this.$ekmtcCommon.showErrorTooltip(
                    height,
                    'Please check cargo size again! (In-gauge cargo)'
                  )
                  isOk = false
                }
              }
            }
          }
        }
      }
      console.log('@@@@ checkValidationOOG return 3')
      console.log('@@@@ isOk: ', isOk)

      if (
        cntrTypCd !== 'FR' &&
        prefix === 'orgHeight' &&
        this.$ekmtcCommon.isEmpty(selector.value) &&
        emptyFlag !== 'E'
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }
      console.log('@@@@ isOk: ', isOk)

      if (prefix === 'itemQty') {
        if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        }
      }
      console.log('@@@@ isOk: ', isOk)

      if (prefix === 'bulkYn') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        }
      }
      console.log('@@@@ isOk: ', isOk)

      if (prefix === 'frgtWt') {
        if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        } else if (
          Number(selector.value) !== 0 &&
          Number(selector.value) < 100
        ) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('js.restriction.023')
          )
          isOk = false
        }

        if (cntrTypCd === 'OT') {
          if (
            (Number(oogInfo.frgtWt) > 21980 && cntrSzCd === '20') ||
            (Number(oogInfo.frgtWt) > 26500 && cntrSzCd === '40')
          ) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              "Regarding cargo's weight exceeds container's maximum capacity. Please proceed this cargo as Break Bulk."
            )
            isOk = false
          }
        } else if (cntrTypCd === 'FR') {
          let overCnt = 0

          if (cntrSzCd === '20') {
            if (Number(oogInfo.frgtWt) > 31260) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                "Regarding cargo's weight exceeds container's maximum capacity. Please proceed this cargo as Break Bulk."
              )
              isOk = false
            }
          } else if (cntrSzCd === '40') {
            if (Number(oogInfo.frgtWt) > 39400) {
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                "Regarding cargo's weight exceeds container's maximum capacity. Please proceed this cargo as Break Bulk."
              )
              isOk = false
            }
          }
        }
      }
      console.log('@@@@ isOk: ', isOk)

      if (prefix === 'cmdtDsc') {
        if (this.$ekmtcCommon.isEmpty(oogInfo.cmdtDsc)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.389')
          )
          isOk = false
        }
      }
      console.log('@@@@ isOk: ', isOk)

      console.log('@@@@ prefix: ', prefix)
      console.log('@@@@ payload: ', payload)

      if (prefix === 'bulkYn') {
        if (payload) {
          console.log('@@@@ payload: ', payload.target.value)
          payload = payload.target.value
        }
      }

      if (payload !== undefined) {
        this.setContainer(prefix, payload)
      }

      return isOk
    },
    checkSize (flag, payload) {
      console.log('@@@@ enter checkSize')
      console.log('@@@@ flag: ', flag)
      console.log('@@@@ payload: ', payload)
      if (this.cntrTypCd !== 'SR') {
        const cntrInfo = this.cntrTypCd + this.cntrSzCd
        const overSizeInfo = this.overSizeInfo[cntrInfo]
        console.log('@@@@ overSizeInfo: ', overSizeInfo)

        if (flag === 'orgLength') {
          const val = payload
          if (Number(val) > overSizeInfo.length) {
            this.overLength = Math.round((val - overSizeInfo.length) / 2)
          } else {
            this.overLength = 0
          }
        } else if (flag === 'orgWidth') {
          const val = payload
          console.log('@@@@ val: ', val)
          if (Number(val) > overSizeInfo.width) {
            this.overWidth = Math.round((val - overSizeInfo.width) / 2)
          } else {
            this.overWidth = 0
          }
        } else if (flag === 'orgHeight') {
          const val = payload
          if (Number(val) > overSizeInfo.height) {
            this.overHeight = val - overSizeInfo.height
          } else {
            this.overHeight = 0
          }
        }

        if (this.cntrTypCd === 'FR') {
          if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) === 0 &&
            Number(this.overHeight) === 0
          ) {
            this.simulation = 'ol'
            this.deadSpace = '2'
          } else if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) === 0
          ) {
            this.simulation = 'owol'
            this.deadSpace = this.cntrSzCd === '20' ? '4' : '10'
          } else if (
            Number(this.overLength) === 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) === 0
          ) {
            this.simulation = 'ow'
            this.deadSpace = this.cntrSzCd === '20' ? '2' : '4'
          } else if (
            Number(this.overLength) === 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'ohow'
            this.deadSpace = this.cntrSzCd === '20' ? '5' : '10'
          } else if (
            Number(this.overLength) === 0 &&
            Number(this.overWidth) === 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'oh'
            this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
          } else if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) === 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'ohol'
            this.deadSpace = this.cntrSzCd === '20' ? '5' : '6'
          } else if (
            Number(this.overLength) > 0 &&
            Number(this.overWidth) > 0 &&
            Number(this.overHeight) > 0
          ) {
            this.simulation = 'ohowol'
            this.deadSpace = this.cntrSzCd === '20' ? '9' : '22'
          } else {
            this.simulation = ''
            this.deadSpace = ''
          }
        } else if (this.cntrTypCd === 'OT') {
          if (Number(this.overHeight) > 0) {
            this.simulation = 'open'
            this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
          } else {
            this.simulation = ''
            this.deadSpace = ''
          }
        }

        let _containerList = [...this.$store.state.schedule.containerList]

        this.$store.commit('schedule/defaultAssign', {
          key: 'containerList',
          value: [
            ..._containerList.slice(0, this.index),
            {
              ..._containerList[this.index],
              _simulation: this.simulation,
              _deadSpace: this.deadSpace,
              _overLength: this.overLength,
              _overWidth: this.overWidth,
              _overHeight: this.overHeight
            },
            ..._containerList.slice(this.index + 1)
          ]
        })

        this.checkValidationOOG(flag, payload)
      }
      console.log('@@@@ flag: ', flag)
      console.log('@@@@ payload: ', payload)

      payload && this.setContainer(flag, payload)
    },
    setContainer (key, payload) {
      //
      let _containerList = [...this.$store.state.schedule.containerList]

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, this.index),
          {
            ..._containerList[this.index],
            [key]: payload
          },
          ..._containerList.slice(this.index + 1)
        ]
      })
    },
    handleChange (flag, payload) {
      console.log('@@@@ e:', flag)
      console.log('@@@@ e:', payload)
      // console.log('@@@@ v: ', this.oogInfo.tareWtYn)

      let _payload = payload ? 'Y' : 'N'
      this.setContainer(flag, _payload)
    },
    setComma (num) {
      return this.$ekmtcCommon.changeNumberFormat(num, { isComma: true })
    },
    uploadPopup () {
      this.popParams = {
        taskCatCd: 'B',
        type: 'O',
        bkgNo: this.parentInfo.bkgNo !== undefined ? this.parentInfo.bkgNo : '',
        scgAppNo:
          this.parentInfo.scgAppNo !== undefined
            ? this.parentInfo.scgAppNo
            : '',
        reqRno: this.oogInfo.reqRno !== undefined ? this.oogInfo.reqRno : '',
        dgUploadFlag: this.saveFlag,
        count: this.cntrSeq
      }

      this.selectFunc = this.uploadComplete
      this.openPopup('dg-oog-certi-pop')
    },
    async oogFileDown (cmdtCd) {
      if (!this.$ekmtcCommon.isEmpty(cmdtCd)) {
        await oogs.oogFileDown(cmdtCd).then(res => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(res.data, trdFileInfo.fileNm)
          } else {
            if (res.data) {
              const header_dis = res.headers['content-disposition']
              if (header_dis) {
                const name = header_dis.split('filename=')[1]
                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', name)
                document.body.appendChild(link)
                link.click()
                link.remove()

                window.URL.revokeObjectURL(url)
              } else {
                this.selectFunc = ''
                this.openPopup('oog-lashing-pop')
              }
            }
          }
        }).catch(() => {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        })
      } else {
        this.selectFunc = ''
        this.openPopup('oog-lashing-pop')
      }
    },
    uploadComplete (returnVo) {
      if (returnVo.deleteAllYn === 'Y') {
        this.oogInfo.reqRno = '' // 전체 삭제했을 경우 reqRno 초기화
      } else {
        this.oogInfo.reqRno = returnVo.reqRno
      }
      this.setContainer('reqRno', this.oogInfo.reqRno)

      this.$ekmtcCommon.layerClose(
        '#oog_area_' + this.cntrSeq + ' > .popup_dim'
      )
    },
    openPopup (compNm) {
      this.customComponent = null
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen(
        '#oog_area_' + this.cntrSeq + ' > .popup_dim'
      )
    },
    closePoup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose(
        '#oog_area_' + this.cntrSeq + ' > .popup_dim'
      )
    },
    changeCmdtDsc (vo) {
      this.oogInfo.cmdtCd = vo.cd
      this.oogInfo.cmdtDsc = vo.cdNm

      this.setContainer('cmdtCd', vo.cd)
      this.setContainer('cmdtDsc', vo.cdNm)

      if (this.$ekmtcCommon.isNotEmpty(vo.cd)) {
        const frm = document.querySelector('#frm')
        const selector = frm.querySelector('#cmdtDsc_' + this.cntrSeq)
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }
    }
  }
}
</script>

<style scoped>
.down_btn_area > label {
  margin-left: 0px;
}
.txt_border_none {
  border: none;
}
.span_scg {
  color: #075bb9;
}
</style>
