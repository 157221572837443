var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "select_opt_area",
      attrs: { id: "oog_area_" + _vm.cntrSeq },
    },
    [
      _c("div", { staticClass: "select_opt_area mt10" }, [
        _c("table", { staticClass: "tbl_col t2" }, [
          _vm._m(0),
          _c("tbody", [
            _vm._m(1),
            _c("tr", [
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      value: _vm.oogInfo.orgLength,
                      "is-comma": true,
                      "input-class": "col_8",
                      id: "orgLength_" + _vm.cntrSeq,
                      maxlength: "6",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkSize("orgLength", ...arguments)
                      },
                    },
                  }),
                  _vm._v(" ㎝ "),
                  _vm.overLength !== "" && _vm.overLength > 0
                    ? _c("div", { staticClass: "txt_size" }, [
                        _vm._v(
                          " Over Size: (F) +" +
                            _vm._s(_vm.setComma(_vm.overLength)) +
                            "㎝"
                        ),
                        _c("br"),
                        _vm._v(
                          "(B) +" + _vm._s(_vm.setComma(_vm.overLength)) + "㎝ "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      value: _vm.oogInfo.orgWidth,
                      "is-comma": true,
                      "input-class": "col_8",
                      id: "orgWidth_" + _vm.cntrSeq,
                      maxlength: "6",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkSize("orgWidth", ...arguments)
                      },
                    },
                  }),
                  _vm._v(" ㎝ "),
                  _vm.overWidth !== "" && _vm.overWidth > 0
                    ? _c("div", { staticClass: "txt_size" }, [
                        _vm._v(
                          " Over Size: (L) +" +
                            _vm._s(_vm.setComma(_vm.overWidth)) +
                            "㎝"
                        ),
                        _c("br"),
                        _vm._v(
                          "(R) +" + _vm._s(_vm.setComma(_vm.overWidth)) + "㎝ "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      value: _vm.oogInfo.orgHeight,
                      "is-comma": true,
                      "input-class": "col_8",
                      id: "orgHeight_" + _vm.cntrSeq,
                      maxlength: "6",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkSize("orgHeight", ...arguments)
                      },
                    },
                  }),
                  _vm._v(" ㎝ "),
                  _vm.overHeight !== "" && _vm.overHeight > 0
                    ? _c("div", { staticClass: "txt_size" }, [
                        _vm._v(
                          " Over Size: +" +
                            _vm._s(_vm.setComma(_vm.overHeight)) +
                            "㎝ "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "vat" },
                [
                  _c("e-input-number", {
                    attrs: {
                      id: "frgtWt_" + _vm.cntrSeq,
                      value: _vm.oogInfo.frgtWt,
                      "is-comma": true,
                      "input-class": "col_8",
                      point: 3,
                      maxlength: "10",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationOOG("frgtWt", ...arguments)
                      },
                    },
                  }),
                  _vm._v(" kg "),
                ],
                1
              ),
              _c("td", { staticClass: "vat tac" }, [
                _c("input", {
                  attrs: {
                    id: "tareWtYn_" + _vm.cntrSeq,
                    type: "checkbox",
                    "true-value": "Y",
                    "false-value": "N",
                  },
                  domProps: { checked: _vm.oogInfo.tareWtYn === "Y" },
                  on: {
                    change: function ($event) {
                      return _vm.handleChange("tareWtYn", $event.target.checked)
                    },
                  },
                }),
                _c("label", { attrs: { for: "tareWtYn_" + _vm.cntrSeq } }),
              ]),
              _c("td", { attrs: { rowspan: "3" } }, [
                _c("div", { staticClass: "img_simulation" }, [
                  _c("div", { class: "div_simulation div_" + _vm.simulation }),
                ]),
                _vm.$ekmtcCommon.isNotEmpty(_vm.deadSpace)
                  ? _c("p", { staticClass: "text_center mt5" }, [
                      _vm._v(" Dead Space : "),
                      _c("span", { staticClass: "font_medium" }, [
                        _vm._v(_vm._s(_vm.deadSpace) + "TEU"),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._m(2),
            _c("tr", [
              _c(
                "td",
                [
                  _c("e-input-number", {
                    attrs: {
                      id: "itemQty_" + _vm.cntrSeq,
                      value: _vm.oogInfo.itemQty,
                      "is-comma": true,
                      "input-class": "itemQty",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.checkValidationOOG("itemQty", ...arguments)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("td", [
                _c(
                  "select",
                  {
                    attrs: { id: "underDckCd_" + _vm.cntrSeq },
                    domProps: { value: _vm.oogInfo.underDckCd },
                    on: {
                      change: function ($event) {
                        return _vm.setContainer(
                          "underDckCd",
                          $event.target.value
                        )
                      },
                    },
                  },
                  _vm._l(_vm.unserDckList, function (cvo) {
                    return _c(
                      "option",
                      { key: cvo.cd, domProps: { value: cvo.cd } },
                      [_vm._v(" " + _vm._s(cvo.cdNm) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c("td", [
                _c(
                  "select",
                  {
                    attrs: { id: "bulkYn_" + _vm.cntrSeq },
                    domProps: { value: _vm.oogInfo.bulkYn },
                    on: {
                      change: function ($event) {
                        return _vm.checkValidationOOG("bulkYn", ...arguments)
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                    ]),
                    _c("option", { attrs: { value: "Y" } }, [_vm._v("Yes")]),
                    _c("option", { attrs: { value: "N" } }, [_vm._v("No")]),
                  ]
                ),
              ]),
              _c(
                "td",
                { attrs: { colspan: "2" } },
                [
                  _c("e-auto-complete-commodity", {
                    staticClass: "auto-area position_relative",
                    attrs: {
                      "ctr-cd": _vm.usrCtrCd,
                      "cd-nm": _vm.oogInfo.cmdtDsc,
                      id: "cmdtDsc_" + _vm.cntrSeq,
                      "focus-force": true,
                    },
                    on: {
                      change: _vm.changeCmdtDsc,
                      input: function ($event) {
                        return _vm.checkValidationOOG("cmdtDsc")
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm.saveFlag !== "PICKUP"
            ? _c("tbody", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("File Upload"),
                  ]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c("div", { staticClass: "filebox" }, [
                      _c(
                        "a",
                        {
                          class: _vm.$ekmtcCommon.isEmpty(_vm.oogInfo.reqRno)
                            ? "button sm gray file"
                            : "button sm blue",
                          on: {
                            click: function ($event) {
                              return _vm.uploadPopup()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.205")) + " ")]
                      ),
                    ]),
                  ]),
                  _vm._m(3),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("div", { staticClass: "filebox down_btn_area" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button sm gray file",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.oogFileDown(_vm.oogInfo.cmdtCd)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.206")) + " ")]
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v("Request Remark"),
                  ]),
                  _c("td", { attrs: { colspan: "5" } }, [
                    _c("textarea", {
                      domProps: { value: _vm.oogInfo.itemCont },
                      on: {
                        input: function ($event) {
                          return _vm.setContainer(
                            "itemCont",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _c("div", { staticClass: "desc_list mt0" }, [
                      _c("ul", [
                        _c("li", [
                          _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.207"))),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.207_1"))),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.207_2"))),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.208"))),
                          _c("br"),
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.CSBK100.208_1")) + " "
                          ),
                        ]),
                        _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.209")))]),
                        _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.210")))]),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.stsAndAppNoYn !== "N",
                        expression: "stsAndAppNoYn !== 'N'",
                      },
                    ],
                  },
                  [
                    _c("th", { attrs: { scope: "row" } }, [_vm._v("Status")]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("span", { staticClass: "span_scg" }, [
                        _vm._v(_vm._s(_vm.oogInfo.apvStsNm)),
                      ]),
                    ]),
                    _c("th", { attrs: { scope: "row" } }, [_vm._v("Ref No.")]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("span", { staticClass: "span_scg" }, [
                        _vm._v(_vm._s(_vm.oogInfo.scgAppNo)),
                      ]),
                    ]),
                  ]
                ),
                _c("tr", [
                  _c("th", { attrs: { scope: "row" } }, [
                    _vm._v("KMTC Remark"),
                  ]),
                  _c("td", { attrs: { colspan: "5", id: "apvCont" } }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.oogInfo.apvCont,
                          expression: "oogInfo.apvCont",
                        },
                      ],
                      staticClass: "txt_border_none",
                      attrs: { readonly: "" },
                      domProps: { value: _vm.oogInfo.apvCont },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.oogInfo, "apvCont", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePoup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "13%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Length")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Width")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Height")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Cargo Weight")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Tare Included")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("SIMULATION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("ITEM Q’TY")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Stowage Request")]),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Break Bulk")]),
      _c("th", { attrs: { scope: "col", colspan: "2" } }, [
        _vm._v("Commodity"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "col" } }, [
      _c("span", { staticClass: "span_blue" }, [
        _vm._v("Proper Lashing Method"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }