<template>
  <div class="capture_area">
    <form id="srForm">
      <div class="content_box" style="padding-bottom:10px;" v-if="seqNo !== ''">
        <h2 class="content_title" style="float:left;">S/R No.</h2>
        <select id="seqNo" style="width:150px;margin-left:10px;">
          <option :value="seqNo" selected>{{ seqNo }}</option>
        </select>
      </div>
      <div class="content_box" style="padding-bottom:35px;">
        <!-- content_box -->
        <div class="booking_call_wrap" v-if="templateYn == 'Y'">
          <div class="call_search">
            <label for="">{{ $t('msg.CSBK100.393') }}<!-- 불러오기 --></label>
            <input type="text" placeholder="SR No." v-model="templateNo" @keypress="templateEnter">
            <button type="button" class="search_btn01" @click.prevent="templateSearch()"><span class="offscreen">{{ $t('msg.CSBK100.121') }}</span></button>
            <a class="button blue sh ml15" @click.prevent="setTemplateInfo()">{{ $t('btn.CMBTK010') }}<!-- 적용 --></a>
          </div>
        </div>
        <div class="booking_call_wrap" v-else>
          <div style="display: inline-block;">
            <h2 class="content_title" style="float: left;">Booking No : {{ detailBkgNo }} </h2>
            <div style="width:3%;float: left;">
              <a class="btn_copy ml3" @click.prevent="fnCopy()">copy</a>
            </div>
          </div>
          <div class="line">
            <div style="width:19%;float: left;">
              <h2 class="content_title" style="float:left;">S/R No.</h2>
              <select id="srList" @change="fnChangeBL($event)" style="width:150px;margin-left:10px;">
                <option value="">{{ this.$t('msg.CSBK100.050') }}</option>
                <option v-for="(vo, idx) in srList" :key="'srList_' + idx" :value="vo" :selected="vo == pageSrRno">{{ vo }}</option>
              </select>
            </div>
            <div style="width:auto;float: left;">
              <a class="button sm mr10" @click="fnCommonBtnAction()">Log {{ $t('btn.CMBTK011') }}</a>
            <!-- <a class="button sm ml2 blue" @click.prevent="fnAddSrNumber('PBL')" v-if="cgoCount === 0 && oogCount === 0 && reCount === 0 && regParams.dlyCtrCd != 'IR'">Part S/R+</a>
            <a class="button sm ml2" v-else>Part S/R+</a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="content_box mt10">
        <!-- content_box -->
        <h2 class="content_title">{{ $t('msg.NEWB020G010.001') }}<!-- email Notice --></h2>
        <span></span>
        <div class="e_mail_notice">
          <p class="ic_txt">
            {{ $t('msg.NEWB020G010.002') }}
            <!-- Results will be automatically informed to you by email. Do you want this service? -->
          </p>
          <span class="inp_form">
            <input type="radio" id="emailcheck_y" v-model="regParams.emailcheck" value="Y" @change="fnEmailInput()">
            <label for="emailcheck_y" class="mr10">YES</label>
          </span>
          <span class="inp_form">
            <input type="radio" id="emailcheck_n" v-model="regParams.emailcheck" value="N" @change="fnEmailInput()">
            <label for="emailcheck_n" class="mr10">No</label>
          </span>
        </div>
        <table class="tbl_row">
          <colgroup>
            <col style="width:100px">
            <col style="width:auto">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.NEWB020G010.003') }}<!-- email --></th>
              <td>
                <select style="width:120px;" v-model="tempParams.bizPicEmlAddr" @change="fnChangeEmail()" :readonly="isEmailReadonly('select')">
                  <option value="">{{ this.$t('msg.CSBK100.050') }}</option>
                  <option v-for="(str, idx) in srInitData.findEmail" :key="'email_' + idx" :value="str">{{ str }}</option>
                </select>
                <input type="text" id="bizPicEmlAddr"
                       class="wid500"
                       :class="(isEmailReadonly('input') ? 'readonly' : '')"
                       :placeholder="$t('msg.CSBK100.152')"
                       v-model="regParams.bizPicEmlAddr" maxlength="100" :readonly="isEmailReadonly('input')"
                       @blur="checkValidationOne('bizPicEmlAddr')"
                >
              </td>
            </tr>
          </tbody>
        </table>
        <p class="txt_desc">
          {{ $t("msg.CSBK100.150") }}
        </p>
      </div>

      <div class="content_box mt10">
        <!-- content_box -->
        <h2 class="content_title">{{ $t('msg.NEWB020G010.004') }}<!-- Shipper Information --></h2>
        <table class="tbl_row">
          <colgroup>
            <col style="width:auto">
            <col style="width:20%">
            <col style="width:30%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">{{ $t('msg.NEWB020G010.005') }}<!-- Shipper Name / Address --></th>
              <th colspan="2" scope="col">{{ $t('msg.NEWB020G010.006') }}<!-- Customer Name --></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <e-trans-address-input
                  id="shprCstEnm"
                  ref="shprCstEnm"
                  v-model="regParams.shprCstEnm"
                  :is-required="true"
                  message-required="Please Input Shipper name."
                  :is-char="true"
                  :max-length="50"
                  @input="checkShpperPOA"
                />
              </td>
              <td colspan="2">{{ srInitData.custInfo.cstNm }}</td>
            </tr>
            <tr>
              <td rowspan="3">
                <e-trans-address-textarea id="shprCstAddr"
                                          ref="shprCstAddr"
                                          :max-line="5"
                                          :column-length="50"
                                          v-model="regParams.shprCstAddr"
                                          :is-char="true"
                                          :max-length="2000"
                                          @input="checkShpperPOA"
                />
                <div v-if="isShipperLOIPlc && isShipperLOI" class="shp_msg">
                  <span v-if="$ekmtcCommon.isEmpty(saveShipperLOI)" class="shp_msg_txt">{{ $t('msg.CSBL200.222') }}</span>
                  <div class="d_inline_flex">
                    <a class="button sm mt5" @click="fnCommonBtnAction('TRD_SHIPPER')">{{ $t('msg.CSBL200.272') }}</a>
                    <a v-if="$ekmtcCommon.isNotEmpty(saveShipperLOI)" class="btn_doc mt5" @click.prevent="fnCommonBtnAction('TRD_SHIPPER_FILE')">Shipper LOI 첨부 확인</a>
                  </div>
                </div>
                <div v-if="isShipperPOA" class="shp_msg">
                  <div class="d_inline_flex">
                    <a class="button sm mt5" @click="fnCommonBtnAction('POA_SHIPPER')">{{ $t('msg.CSBL200.287') }}</a>
                    <a v-if="$ekmtcCommon.isNotEmpty(saveShipperPOA)" class="btn_doc mt5" @click.prevent="fnCommonBtnAction('POA_SHIPPER_FILE')">Power of Attorney 첨부 확인</a>
                  </div>
                </div>
              </td>
              <th scope="row">{{ $t('msg.NEWB020G010.007') }}<!-- P.I.C --></th>
              <td>
                {{ srInitData.custInfo.pic }}
              </td>
            </tr>
            <tr>
              <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.008') }}<!-- Tel No. --></th>
              <td>
                {{ srInitData.custInfo.telPlc }}-{{ srInitData.custInfo.telOfc }}
              </td>
            </tr>
            <tr>
              <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.009') }}<!-- FAX No. --></th>
              <td>
                {{ srInitData.custInfo.faxPlc }}-{{ srInitData.custInfo.faxOfc }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Consignee -->
        <div class="mt10">
          <table class="tbl_row">
            <colgroup>
              <col style="width:auto">
              <col style="width:20%">
              <col style="width:30%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{{ $t('msg.NEWB020G010.010') }}<!-- Consignee Name / Address --></th>
                <th colspan="2" scope="col">{{ $t('msg.NEWB020G010.011') }}<!-- Contact Point --></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <e-trans-address-input
                    id="cneCstEnm"
                    ref="cneCstEnm"
                    v-model="regParams.cneCstEnm"
                    :is-required="true"
                    message-required="Please Input Consignee name."
                    :is-char="true"
                    :max-length="50"
                    @input="checkSanction('cneCstEnm')"
                  />
                </td>
                <th scope="row">{{ $t('msg.NEWB020G010.012') }}<!-- Consignee PIC --></th>
                <td>
                  <e-trans-string-check-cnee-input id="cnePicNm"
                                                   v-model="regParams.cnePicNm"
                                                   maxlength="100"
                  />
                </td>
              </tr>
              <tr>
                <td rowspan="3">
                  <e-trans-address-textarea id="cneCstAddr"
                                            ref="cneCstAddr"
                                            :max-line="5"
                                            :column-length="50"
                                            v-model="regParams.cneCstAddr"
                                            :is-char="true"
                                            :max-length="2000"
                  />
                </td>
                <th scope="row">{{ $t('msg.NEWB020G010.013') }}<!-- Consignee Tel --></th>
                <td>
                  <e-trans-string-check-cnee-input id="cnePicTelNo"
                                                   v-model="regParams.cnePicTelNo"
                                                   maxlength="30"
                  />
                </td>
              </tr>
              <tr>
                <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.014') }}<!-- Consignee Fax --></th>
                <td>
                  <e-trans-string-check-cnee-input id="cnePicFaxNo"
                                                   v-model="regParams.cnePicFaxNo"
                                                   maxlength="30"
                  />
                </td>
              </tr>
              <tr>
                <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.015') }}<!-- Consignee email --></th>
                <td>
                  <input type="text" id="cnePicEmlAddr" v-model="regParams.cnePicEmlAddr" maxlength="100" @input="checkValidationOne('cnePicEmlAddr')">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt10">
          <table class="tbl_row">
            <colgroup>
              <col style="width:auto">
              <col style="width:20%">
              <col style="width:30%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{{ $t('msg.NEWB020G010.016') }}<!-- Notify Name / Address --></th>
                <th colspan="2" scope="col">{{ $t('msg.NEWB020G010.017') }}<!-- Notify Name / Address --></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" name="cneeCopy" id="cneeCopy" :checked="regParams.consYn === 'Y'" @click="changeCnee">
                  <label for="cneeCopy">{{ $t('msg.NEWB020G010.018') }}<!-- Copy Consignee --></label>
                  <div class="mt5">
                    <e-trans-address-input
                      id="ntifCstEnm"
                      ref="ntifCstEnm"
                      v-model="regParams.ntifCstEnm"
                      :is-required="true"
                      message-required="Please Input Notify name."
                      :is-char="true"
                      :max-length="50"
                      @input="checkSanction('ntifCstEnm')"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <select v-model="regParams.bookingAgent" :disabled="srInitData.bkgAgntYn == 'Y' || memberDetail.bkgPlcCd == 'NBO'">
                    <template v-if="memberDetail.bkgPlcCd == 'NBO'">
                      <option value="">Select</option>
                      <option :value="memberDetail.cstCd">{{ srInitData.custInfo.cstEnm }}</option>
                    </template>
                    <template v-else-if="srInitData.bkgAgntList != null && srInitData.bkgAgntList.length > 0">
                      <option v-for="(agentVo, idx) in srInitData.bkgAgntList " :key="'agent_' + idx" :value="agentVo.cstNo">{{ agentVo.cstNm }}</option>
                    </template>
                    <template v-else>
                      <option :value="memberDetail.cstCd">{{ memberDetail.cstCd }}</option>
                    </template>
                  </select>
                  <!-- <select v-model="regParams.bookingAgent" v-show="(srInitData.bkgAgntYn !== 'Y' && memberDetail.bkgPlcCd !== 'NBO')">
                    <template v-if="srInitData.bkgAgntList != null && srInitData.bkgAgntList.length > 0">
                      <option v-for="(agentVo, idx) in srInitData.bkgAgntList " :key="'agent_' + idx" :value="agentVo.cstNo">{{ agentVo.cstNm }}</option>
                    </template>
                    <template v-else>
                      <option :value="memberDetail.cstCd" selected="selected">{{ memberDetail.cstCd }}</option>
                    </template>
                  </select> -->
                </td>
              </tr>
              <tr>
                <td :rowspan="regParams.fixdCgoYn === 'Y'? '7' : '6'">
                  <e-trans-address-textarea id="ntifCstAddr"
                                            ref="ntifCstAddr"
                                            :max-line="5"
                                            :column-length="50"
                                            v-model="regParams.ntifCstAddr"
                                            :is-char="true"
                                            :max-length="2000"
                  />
                </td>
                <th v-show="bkgShprCodAreaYn === 'Y'" scope="row">Booking Shipper Code<!-- Booking Shipper Code. --></th>
                <td v-show="bkgShprCodAreaYn === 'Y'">
                  <div class="tbl_form">
                    <div>
                      <span class="pr5">
                        <input id="bkgShprCstNo"
                               type="text"
                               v-model="regParams.bkgShprCstNo"
                               :maxlength="6"
                               @blur="getBkgShpr()"
                               @input="regParams.bkgShprCstNo=regParams.bkgShprCstNo.toUpperCase()"
                        />
                      </span>
                      <span class="col_8">
                        <e-trans-string-check-input id="bkgShprCstEnm"
                                                    v-model="regParams.bkgShprCstEnm"
                                                    :is-upper-case="false"
                                                    :readonly="true"
                        />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.019') }}<!-- Shipment no. --></th>
                <td>
                  <e-trans-string-check-input id="shpmNo"
                                              v-model="regParams.shpmNo"
                                              :is-upper-case="true"
                                              maxlength="10"
                  />
                </td>
              </tr>
              <tr>
                <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.020') }}<!-- POL HS Code --></th>
                <td>
                  <e-trans-string-check-input id="hsCd"
                                              v-model="regParams.hsCd"
                                              :is-upper-case="true"
                                              maxlength="6"
                  />
                </td>
              </tr>
              <tr>
                <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.021') }}<!-- POD HS Code --></th>
                <td>
                  <e-trans-string-check-input id="podHsCd"
                                              v-model="regParams.podHsCd"
                                              :is-upper-case="true"
                                              :maxlength="tempParams.maxlengthPodHsCd"
                                              :error-message="tempParams.hscdErrorMessage"
                                              @input="chkHscd($event)"
                  />
                </td>
              </tr>
              <tr>
                <th class="bor-left" scope="row">{{ $t('msg.NEWB020G010.022') }}<!-- Chemical or Not --></th>
                <td>
                  <input type="radio" id="chemicalY" v-model="regParams.chemicalYn" name="chemicalYn" value="Y" true-value="Y" false-value="N" @click="checkValidationOne('chemicalYn')" />
                  <label for="chemicalY" class="mr10"><span></span>Y</label>
                  <input type="radio" id="chemicalN" v-model="regParams.chemicalYn" name="chemicalYn" value="N" true-value="N" false-value="Y" @click="checkValidationOne('chemicalYn')" />
                  <label for="chemicalN" class="mr10"><span></span>N</label>
                  &nbsp;
                  <button v-show="seqNo =='' && regParams.chemicalYn === 'Y'" type="button" :class="isChemicalFileUpload() ? 'blue' : 'gray'" class="button sm" @click="openUploadPopup('chemical')">
                    {{ $t('msg.CSBK100.205') }} <!-- 파일첨부/확인 -->
                  </button>
                </td>
              </tr>
              <tr>
                <th class="bor-left" scope="row">Fix Cargo</th>
                <!-- actShprCstNo: '' -->
                <td>
                  <input type="radio" id="fixdCgoY" v-model="regParams.fixdCgoYn" name="fixdCgoYn" value="Y" true-value="Y" false-value="N" @change="getActShprCst" />
                  <label for="fixdCgoY" class="mr10"><span></span>Y</label>
                  <input type="radio" id="fixdCgoN" v-model="regParams.fixdCgoYn" name="fixdCgoYn" value="N" true-value="N" false-value="Y" />
                  <label for="fixdCgoN" class="mr10"><span></span>N</label>
                </td>
              </tr>
              <tr v-show="regParams.fixdCgoYn === 'Y'">
                <th class="bor-left" scope="row">B/L Shipper</th>
                <td>
                  <select id="actShprCstNo" v-model="regParams.actShprCstNo" @change="checkValidationActShprCst">
                    <template v-if="actShprCstList != null && actShprCstList.length > 0">
                      <option v-for="(actShprCstVo, idx) in actShprCstList " :key="'actShprCst_' + idx" :value="actShprCstVo.actShprCstNo">{{ actShprCstVo.actShprCstEnm }}</option>
                    </template>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="testData.testYn == 'Y'">
        <!-- Local 에서만 노출됨 -->
        <div class="content_box mt10">
          <select id="selectTestData" @change="scheduleTest($event)">
            <option value="">:: 선택 ::</option>
            <option v-for="(tvo, idx) in testData.schedule" :key="'test01_' + idx" :value="tvo.key">{{ '[' + tvo.key + ']' + tvo.text }}</option>
          </select>
        </div>
      </div>

      <div class="content_box mt10">
        <!-- content_box -->
        <h2 class="content_title">{{ $t('msg.NEWB020G010.023') }}<!-- Service Information --></h2>
        <table class="tbl_row">
          <colgroup>
            <col style="width:12%">
            <col style="width:21%">
            <col style="width:12%">
            <col style="width:21%">
            <col style="width:12%">
            <col style="width:auto">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">{{ $t('msg.NEWB020G010.024') }}<!-- Place of Receipt --></th>
              <td>
                <div>
                  <input type="text" readonly="readonly" v-model="regParams.porCtrCd" @click="searchSchedule();">
                </div>
                <div class="mt5">
                  <input type="text" readonly="readonly" v-model="regParams.porPlcNm" @click="searchSchedule();">
                </div>
              </td>
              <th scope="row">{{ $t('msg.NEWB020G010.025') }}<!-- Place of Delivery --></th>
              <td>
                <div>
                  <input type="text" readonly="readonly" v-model="regParams.dlyCtrCd" @click="searchSchedule();">
                </div>
                <div class="mt5">
                  <input type="text" readonly="readonly" v-model="regParams.dlyPlcNm" @click="searchSchedule();">
                </div>
              </td>
              <th scope="row">{{ $t('msg.NEWB020G010.026') }}<!-- Port of Loading --></th>
              <td>
                <div>
                  <input type="text" readonly="readonly" v-model="regParams.polCtrCd" @click="searchSchedule();">
                </div>
                <div class="mt5">
                  <input type="text" readonly="readonly" v-model="regParams.polPortNm" @click="searchSchedule();">
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.NEWB020G010.027') }}<!-- Vessel / Voyage --></th>
              <td>
                <div>
                  <input type="text" readonly="readonly" v-model="regParams.vslNm" @click="searchSchedule();">
                </div>
                <div class="search_btn mt5" v-if="seqNo == ''">
                  <input class="wid80p" type="text" readonly="readonly" v-model="regParams.voyNo" @click="searchSchedule();">
                  <span>
                    <button class="search_btn01" type="button" @click.prevent="searchSchedule();"></button>
                    <span></span>
                  </span>
                </div>
                <div class="search_btn mt5" v-else>
                  <input class="wid80p" type="text" readonly="readonly" v-model="regParams.voyNo">
                </div>
              </td>
              <th scope="row">{{ $t('msg.NEWB020G010.028') }}<!-- Port of Discharge --></th>
              <td>
                <div>
                  <input type="text" readonly="readonly" v-model="regParams.podCtrCd" @click="searchSchedule();">
                </div>
                <div class="mt5">
                  <input type="text" readonly="readonly" v-model="regParams.podPortNm" @click="searchSchedule();">
                </div>
              </td>
              <th scope="row">{{ $t('msg.NEWB020G010.029') }}<!-- Final Destination --></th>
              <td>
                <div v-if="seqNo == ''">
                  <div>
                    <select id="lstDestCtrCd" v-model="regParams.lstDestCtrCd" @change="fnChangeCtrCd('lstDestCtrCd', 'onchange')">
                      <option value="">Select a country</option>
                      <option v-for="(cvo, idx) in defaultCtrCd" :key="'lstDestCtrCd_' + idx" :value="cvo.ctrCd">{{ cvo.ctrEnm }}</option>
                    </select>
                  </div>
                  <div class="mt5">
                    <select id="lstDestPlcCd" v-model="regParams.lstDestPlcCd">
                      <option value="">Select Place</option>
                      <option v-for="(cvo, idx) in objPlace['lstDestCtrCd']" :key="'lstDestPlcCd_' + idx" :value="cvo.plcCd">{{ cvo.plcEnmOnly }}</option>
                    </select>
                  </div>
                </div>
                <div v-else>
                  <div>
                    <input type="text" id="lstDestCtrCd" v-model="regParams.lstDestCtrCd" readonly="readonly">
                  </div>
                  <div class="mt5">
                    <input type="text" id="lstDestPlcCd" v-model="regParams.lstDestPlcCd" readonly="readonly">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('msg.NEWB020G010.030') }}<!-- Service Term --></th>
              <td>
                <div>
                  <div>
                    <select id="polShtmCd" v-model="regParams.polShtmCd" @change="changePolShtmCd">
                      <template v-for="(cvo, idx) in commonCodes['01033']">
                        <option v-if="seqNo == '' || (seqNo != '' && regParams.polShtmCd == cvo.cd)" :key="'polShtmCd_' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                      </template>
                    </select>
                  </div>
                  <div class="mt5">
                    <select id="podShtmCd" v-model="regParams.podShtmCd">
                      <template v-for="(cvo, idx) in commonCodes['01033']">
                        <option v-if="seqNo == '' || (seqNo != '' && regParams.podShtmCd == cvo.cd)" :key="'podShtmCd_' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                      </template>
                    </select>
                  </div>
                </div>
              </td>
              <th scope="row">{{ $t('msg.NEWB020G010.031') }}<!-- Place of B/L Issue --></th>
              <td>
                <div>
                  <div>
                    <select id="issCtrCd" v-model="regParams.issCtrCd" @change="fnChangeCtrCd('issCtrCd', 'onchange');checkValidationOne('issCtrCd')">
                      <option v-if="seqNo == ''" value="">select a country</option>
                      <template v-for="(cvo, idx) in defaultCtrCd">
                        <option v-if="seqNo == '' || (seqNo != '' && regParams.issCtrCd == cvo.ctrCd)" :key="'issCtrCd_' + idx" :value="cvo.ctrCd">{{ cvo.ctrEnm }}</option>
                      </template>
                    </select>
                  </div>
                  <div class="mt5">
                    <select id="issPlcCd" v-model="regParams.issPlcCd" @change="checkValidationOne('issPlcCd')">
                      <option v-if="seqNo == ''" value="">select Place</option>
                      <template v-for="(cvo, idx) in objPlace['issCtrCd']">
                        <option v-if="seqNo == '' || (seqNo != '' && regParams.issPlcCd == cvo.plcCd)" :key="'issPlcCd_' + idx" :value="cvo.plcCd">{{ cvo.plcEnmOnly }}</option>
                      </template>
                    </select>
                  </div>
                </div>
              </td>
              <th scope="row">{{ $t('msg.NEWB020G010.032') }}<!-- Freight --></th>
              <td>
                <div v-if="seqNo == ''">
                  <div>
                    <select id="frtPncCd" v-model="regParams.frtPncCd" @change="fnFrtPncChange()">
                      <option value="P">Prepaid</option>
                      <option value="C">Collect</option>
                    </select>
                  </div>
                  <div class="mt5" v-if="regParams.frtPncCd == 'C'">
                    <span class="unit">{{ $t('msg.NEWB020G010.033') }}<!-- SC NO --> :&nbsp;</span>
                    <input type="text" id="soNo" v-model="regParams.soNo" class="wid50p" maxlength="9" @blur="regParams.soNo = regParams.soNo.toUpperCase(), findIotCntrYn('S')" @keyup="checkValidationOne('soNo')">
                  </div>
                </div>
                <div v-else>
                  <div>
                    <select id="frtPncCd" v-model="regParams.frtPncCd" @change="fnFrtPncChange()">
                      <option value="P" v-if="regParams.frtPncCd == 'P'">Prepaid</option>
                      <option value="C" v-if="regParams.frtPncCd == 'C'">Collect</option>
                    </select>
                  </div>
                  <div class="mt5" v-if="regParams.frtPncCd == 'C'">
                    <span class="unit">{{ $t('msg.NEWB020G010.033') }}<!-- SC NO --> :&nbsp;</span>
                    <input type="text" id="soNo" v-model="regParams.soNo" class="wid50p" readonly="readonly">
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt10">
          <table class="tbl_row">
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.NEWB020G010.034') }}<!-- No of Package Code --></th>
                <td>
                  <div class="tbl_form">
                    <div>
                      <span class="pr5">
                        <e-input-number :is-comma="true" v-model="regParams.pkgQty" id="pkgQty" maxlength="10" @input="checkValidationOne('pkgQty')" @blur="fnPackage('CHANGE')" />
                      </span>
                      <span class="col_8">
                        <select id="pkgCd" v-model="regParams.pkgCd" @change="fnPackage('CHANGE')">
                          <template v-for="(cvo, idx) in commonCodes['01130']">
                            <option v-if="cvo.cd != ' '" :key="'pkgCd_' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                          </template>
                        </select>
                      </span>
                    </div>
                  </div>
                </td>
                <th scope="row">{{ $t('msg.NEWB020G010.035') }}<!-- Commodity --></th>
                <td>
                  <div class="tbl_form">
                    <span>
                      <e-auto-complete-commodity
                        id="oprRmk"
                        @change="(vo) => { regParams.oprRmk = vo.cdNm;checkValidationAutocomplete('oprRmk') }"
                        :ctr-cd="memberDetail.userCtrCd"
                        :cd-nm="regParams.oprRmk"
                        :disabled="regParams.emptyFlag === 'E'"
                        :is-value="(regParams.emptyFlag === 'E' ? false : true )"
                        :is-char="true"
                        :focus-force="true"
                      />
                    </span>
                    <span class="tar">
                      <input
                        id="emptyFlag"
                        v-model="regParams.emptyFlag"
                        type="checkbox"
                        true-value="E"
                        false-value="F"
                        @click="changeEmptyFlag"
                      >
                      <label for="emptyFlag">{{ $t('msg.NEWB020G010.036') }}<!-- Empty 선적 --></label>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">{{ $t('msg.NEWB020G010.037') }}<!-- Gross Weight --></th>
                <td>
                  <e-input-number :is-comma="true" :point="3" v-model="regParams.grsWt" id="grsWt" maxlength="16" class="wid85p" @input="checkValidationOne('grsWt')" :digit-max="10" />
                  <span class="unit">Kg</span>
                </td>
                <th scope="row">{{ $t('msg.NEWB020G010.046') }}<!-- Measurement --></th>
                <td>
                  <e-input-number :is-comma="true" :point="4" v-model="regParams.grsCbm" id="grsCbm" maxlength="13" class="wid85p" @input="checkValidationOne('grsCbm')" :digit-max="4" />
                  <span class="unit">CBM</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="content_box mt10" v-if="srStat == 'B'">
        <!-- content_box -->
        <h2 class="content_title">Tally Data Information</h2>
        <table class="tbl_col">
          <colgroup>
            <col width="15%">
            <col width="8%">
            <col width="5%">
            <col width="15%">
            <col width="15%">
            <col width="15%">
            <col width="auto">
            <col width="5%">
          </colgroup>
          <thead>
            <tr>
              <th>Container No</th>
              <th>Size </th>
              <th>Type </th>
              <th>PKG CD</th>
              <th>PKG QTY</th>
              <th>Weight </th>
              <th>SEAL_NO</th>
              <th scope="col"><button class="tbl_icon plus" type="button" @click="addTally()">plus</button></th>
            </tr>
          </thead>
          <tbody v-for="(vo, idx) in regParams.tallyList" :key="'tr_tally_'+ idx" :id="'tallyList_'+ idx">
            <tr>
              <td>
                <input v-model="vo.tallCntrNo" id="tallCntrNo" maxlength="11" class="wid85p" @blur="vo.tallCntrNo = vo.tallCntrNo.toUpperCase()" />
              </td>
              <td>
                <select v-model="vo.tallCntrSize" :id="'tallCntrSize_' + idx">
                  <option v-for="option in tallycntSzList" :key="option.cd" :value="option.cd">
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td>
                <select v-model="vo.tallCntrTypCd" :id="'tallCntrTypCd_' + idx">
                  <option v-for="option in cntrTypeList" :key="option.cd" :value="option.cd">
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td>
                <select v-model="vo.tallPkgCd" :id="'tallPkgCd_' + idx">
                  <option v-for="option in commonCodes['01130']" :key="option.cd" :value="option.cd">
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td>
                <e-input-number :is-comma="true" :point="3" v-model="vo.tallPkgQty" id="tallpkgQty" maxlength="10" class="wid85p" />
              </td>
              <td>
                <e-input-number :is-comma="true" :point="3" v-model="vo.tallWeight" id="tallWeight" maxlength="13" class="wid85p" @input="checkValidationOne('tallWeight')" />
              </td>
              <td>
                <input type="text" v-model="vo.tallSealNo" maxlength="20" class="wid85p" />
              </td>
              <td class="tac">
                <button
                  class="tbl_icon minus"
                  type="button"
                  @click="removeTally(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="content_box mt10">
        <!-- content_box -->

        <h2 class="content_title">
          {{ $t('msg.NEWB020G010.038') }}
          <!-- Shipper/Consignee/Notify Additional Information -->
        </h2>
        <table class="tbl_col">
          <colgroup>
            <col style="width:12%">
            <col style="width:21%">
            <col style="width:21%">
            <col style="width:21%">
            <col style="width:auto">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" rowspan="2"></th>
              <th scope="col">{{ $t('msg.NEWB020G010.039') }}<!-- 지역 --></th>
              <th scope="col">{{ $t('msg.NEWB020G010.040') }}<!-- City (시/군) --></th>
              <th scope="col">{{ $t('msg.NEWB020G010.041') }}<!-- State / Province(도) --></th>
              <th scope="col">{{ $t('msg.NEWB020G010.042') }}<!-- Postal Code(우편번호) --></th>
            </tr>
            <tr>
              <th class="bor-left" scope="col">{{ $t('msg.CMBK100_M1.023_1') }}<!-- 담당자 --></th>
              <th scope="col">{{ $t('msg.CMBA100.00108') }}<!-- 전화번호 --></th>
              <th scope="col">{{ $t('msg.CMBA100.00109') }}<!-- Fax No. --></th>
              <th scope="col">{{ $t('msg.NEWB020G010.003') }}<!-- email --></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" rowspan="2">{{ $t('msg.NEWB020G010.043') }}<!-- Shipper --></th>
              <td class="full">
                <select id="actShprCstCtrCd" v-model="regParams.actShprCstCtrCd" @change="fnChangeActCatId(regParams.actShprCstCtrCd);fnAddInfoStateSet(regParams.actShprCstCtrCd, 'ACT', 'CHANGE')">
                  <option value="">select</option>
                  <option v-for="(cvo, idx) in defaultCtrCd" :key="'actShprCstCtrCd_' + idx" :value="cvo.ctrCd">{{ cvo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input id="actShprCstCityNm"
                                                 v-model="regParams.actShprCstCityNm"
                                                 maxlength="100"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="actShprStateNm"
                                                 v-show="tempParams.showActShprStateNm"
                                                 v-model="regParams.actShprStateNm"
                                                 maxlength="35"
                />
                <select id="actShprStateCd"
                        v-show="!tempParams.showActShprStateNm"
                        v-model="regParams.actShprStateCd"
                        @change="fnStateNmSet(regParams.actShprStateCd, 'ACT')"
                >
                  <option value="">select</option>
                  <option v-for="(cvo, idx) in commonCodes['CS038']" :key="'actShprStateCd_' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input id="actShprPostNo"
                                                 v-model="regParams.actShprPostNo"
                                                 maxlength="6"
                />
              </td>
            </tr>
            <tr>
              <td>
                <e-trans-string-check-cnee-input id="actShprCstPicNm"
                                                 v-model="regParams.actShprCstPicNm"
                                                 maxlength="100"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="actShprCstTelNo"
                                                 v-model="regParams.actShprCstTelNo"
                                                 maxlength="30"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="actShprCstFaxNo"
                                                 v-model="regParams.actShprCstFaxNo"
                                                 maxlength="30"
                />
              </td>
              <td>
                <div class="position_relative">
                  <input type="text" id="actShprCstEmlAddr" v-model="regParams.actShprCstEmlAddr" @input="checkValidationActShprCstEmlAddr()" maxlength="100" />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="2">{{ $t('msg.NEWB020G010.044') }}<!-- Consignee --></th>
              <td class="full">
                <select id="cneCstCtrCd" v-model="regParams.cneCstCtrCd" @change="fnChangeCneCatId(regParams.cneCstCtrCd);fnAddInfoStateSet(regParams.cneCstCtrCd, 'CNE', 'CHANGE')">
                  <option value="">select</option>
                  <option v-for="(cvo, idx) in defaultCtrCd" :key="'cneCstCtrCd_' + idx" :value="cvo.ctrCd">{{ cvo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input id="cneCstCityNm"
                                                 v-model="regParams.cneCstCityNm"
                                                 maxlength="100"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="cneCstStateNm"
                                                 v-show="tempParams.showCneCstStateNm"
                                                 v-model="regParams.cneCstStateNm"
                                                 maxlength="35"
                />
                <select id="cneCstStateCd"
                        v-show="!tempParams.showCneCstStateNm"
                        v-model="regParams.cneCstStateCd"
                        @change="fnStateNmSet(regParams.cneCstStateCd, 'CNE')"
                >
                  <option value="">select</option>
                  <option v-for="(cvo, idx) in commonCodes['CS038']" :key="'cneCstStateCd_' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input id="cneCstPostNo"
                                                 v-model="regParams.cneCstPostNo"
                                                 maxlength="6"
                />
              </td>
            </tr>
            <tr>
              <td>
                <e-trans-string-check-cnee-input id="cneCstPicNm"
                                                 v-model="regParams.cneCstPicNm"
                                                 maxlength="100"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="cneCstTelNo"
                                                 v-model="regParams.cneCstTelNo"
                                                 maxlength="30"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="cneCstFaxNo"
                                                 v-model="regParams.cneCstFaxNo"
                                                 maxlength="30"
                />
              </td>
              <td>
                <div class="position_relative">
                  <input type="text" id="cneCstEmlAddr" v-model="regParams.cneCstEmlAddr" @input="checkValidationCneCstEmlAddr()" maxlength="100" />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="2">{{ $t('msg.NEWB020G010.045') }}<!-- Notify --></th>
              <td class="full">
                <select id="ntifCstCtrCd" v-model="regParams.ntifCstCtrCd" @change="fnChangeNotiCatId(regParams.ntifCstCtrCd);fnAddInfoStateSet(regParams.ntifCstCtrCd, 'NTIF', 'CHANGE')">
                  <option value="">select</option>
                  <option v-for="(cvo, idx) in defaultCtrCd" :key="'ntifCstCtrCd_' + idx" :value="cvo.ctrCd">{{ cvo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input id="ntifCstCityNm"
                                                 v-model="regParams.ntifCstCityNm"
                                                 maxlength="100"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="ntifCstStateNm"
                                                 v-show="tempParams.showNtifCstStateNm"
                                                 v-model="regParams.ntifCstStateNm"
                                                 maxlength="35"
                />
                <select id="ntifCstStateCd"
                        v-show="!tempParams.showNtifCstStateNm"
                        v-model="regParams.ntifCstStateCd"
                        @change="fnStateNmSet(regParams.ntifCstStateCd, 'NTIF')"
                >
                  <option value="">select</option>
                  <option v-for="(cvo, idx) in commonCodes['CS038']" :key="'ntifCstStateCd_' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input id="ntifCstPostNo"
                                                 v-model="regParams.ntifCstPostNo"
                                                 maxlength="6"
                />
              </td>
            </tr>
            <tr>
              <td>
                <e-trans-string-check-cnee-input id="ntifCstPicNm"
                                                 v-model="regParams.ntifCstPicNm"
                                                 maxlength="100"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="ntifCstTelNo"
                                                 v-model="regParams.ntifCstTelNo"
                                                 maxlength="30"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input id="ntifCstFaxNo"
                                                 v-model="regParams.ntifCstFaxNo"
                                                 maxlength="30"
                />
              </td>
              <td>
                <div class="position_relative">
                  <input type="text" id="ntifCstEmlAddr" v-model="regParams.ntifCstEmlAddr" @input="checkValidationNtifCstEmlAddr()" maxlength="100" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt10">
          <table class="tbl_row">
            <colgroup>
              <col style="width:30%">
              <col style="width:30%">
              <col style="width:auto">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">Shipper Company ID</th>
                <td class="full">
                  <select id="actShprIdCatCd" v-model="regParams.actShprIdCatCd" @change="fnChangeCatId(regParams.actShprIdCatCd, 'ACT')">
                    <option value="">select</option>
                    <option v-for="(cvo, idx) in srInitData.actCstId" :key="'actShprIdCatCd_' + idx" :value="cvo.cstIdCatCd">{{ cvo.cstIdCatNm }}</option>
                  </select>
                </td>
                <td>
                  <e-trans-string-check-cnee-input id="actShprIdNo"
                                                   v-model="regParams.actShprIdNo"
                                                   maxlength="200"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <!-- TODO 조건부 필수값 -->
                  Consignee Company ID
                  <span class="txt" v-if="tempParams.consRed">({{ $t('msg.CSBL200.075') }}) <!-- 선택 --></span>
                </th>
                <td class="full">
                  <select id="cneIdCatCd" v-model="regParams.cneIdCatCd" @change="fnChangeCatId(regParams.cneIdCatCd, 'CNE')">
                    <option value="">select</option>
                    <option v-for="(cvo, idx) in srInitData.cneCstId" :key="'cneIdCatCd_' + idx" :value="cvo.cstIdCatCd">{{ cvo.cstIdCatNm }}</option>
                  </select>
                </td>
                <td>
                  <e-trans-string-check-cnee-input id="cneIdNo"
                                                   v-model="regParams.cneIdNo"
                                                   maxlength="200"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Notify Company ID</th>
                <td class="full">
                  <select id="ntifIdCatCd" v-model="regParams.ntifIdCatCd" @change="fnChangeCatId(regParams.ntifIdCatCd, 'NTIF')">
                    <option value="">select</option>
                    <option v-for="(cvo, idx) in srInitData.notiCstId" :key="'ntifIdCatCd_' + idx" :value="cvo.cstIdCatCd">{{ cvo.cstIdCatNm }}</option>
                  </select>
                </td>
                <td>
                  <e-trans-string-check-cnee-input id="ntifIdNo"
                                                   v-model="regParams.ntifIdNo"
                                                   maxlength="200"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[S] 국가코드 인도네이사(ID) 경우 -->
        <div v-if="tempParams.showTaxId == 'ID'" class="mt10">
          <table class="tbl_row">
            <colgroup>
              <col style="width:30%">
              <col style="width:auto">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ tempParams.taxNm }}</th>
                <td>
                  <e-trans-string-check-cnee-input id="idTaxNo"
                                                   v-model="regParams.idTaxNo"
                                                   maxlength="30"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[E] 국가코드 인도네이사(ID) 경우 -->
        <!--[S] 국가코드 베트남(VN) 경우 -->
        <div v-if="tempParams.showTaxId == 'VN'" class="mt10">
          <table class="tbl_row">
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">Importer's Tax ID</th>
                <td>
                  <e-trans-string-check-cnee-input id="idTaxNo"
                                                   v-model="regParams.idTaxNo"
                                                   maxlength="30"
                  />
                </td>
                <td colspan="2">
                  <input type="checkbox" name="wstCgoYn" id="wstCgoYn" value="Y" @click="fnWstCgoYn" :checked="regParams.wstCgoYn == 'Y'">
                  <label for="wstCgoYn">WASTE/SCRAP Cargo</label>
                </td>
              </tr>
              <tr v-if="regParams.wstCgoYn == 'Y'">
                <th scope="row">License No</th>
                <td>
                  <e-trans-string-check-cnee-input id="imLicNo"
                                                   v-model="regParams.imLicNo"
                                                   maxlength="20"
                  />
                </td>
                <th scope="row">Bank Guarantee No</th>
                <td>
                  <e-trans-string-check-cnee-input id="imBnkGurNo"
                                                   v-model="regParams.imBnkGurNo"
                                                   maxlength="20"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--[E] 국가코드 베트남(VN) 경우 -->
      <!--[S] 지역코드가 NSA 또는 국가코드가 IN 경우 -->
      <div v-if="tempParams.showIecId" class="content_box mt10">
        <h2 class="content_title">INDIA Info</h2>
        <table class="tbl_row">
          <colgroup>
            <col style="width:15%">
            <col style="width:35%">
            <col style="width:15%">
            <col style="width:35%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">Importer's IEC No</th>
              <td>
                <e-trans-string-check-cnee-input id="iecNo"
                                                 v-model="regParams.iecNo"
                                                 maxlength="10"
                />
              </td>
              <th scope="row">FWDR/NVOCC PAN No</th>
              <td>
                <e-trans-string-check-cnee-input id="panNo"
                                                 v-model="regParams.panNo"
                                                 maxlength="10"
                />
              </td>
            </tr>
            <tr v-if="tempParams.showIecIdSub">
              <th scope="row">GST No</th>
              <td>
                <e-trans-string-check-cnee-input id="gstNo"
                                                 v-model="regParams.gstNo"
                                                 maxlength="20"
                />
              </td>
              <th scope="row">E-Mail</th>
              <td>
                <div class="position_relative">
                  <input type="text" id="iecPicEmlAddr" v-model="regParams.iecPicEmlAddr" @keyup="checkValidationIecPicEmlAddr()" maxlength="100" />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">Notify's PAN No</th>
              <td>
                <e-trans-string-check-cnee-input id="ntifPanNo"
                                                 v-model="regParams.ntifPanNo"
                                                 maxlength="10"
                />
              </td>
              <th scope="row">Invoice Value</th>
              <td>
                <select style="width:120px;" v-model="regParams.invValCurCd">
                  <option value="">select</option>
                  <option v-for="(cvo, idx) in commonCodes['00003']" :key="'invValCurCd_' + idx" :value="cvo.cd">{{ cvo.cd }}</option>
                </select>
                <e-input-number :is-comma="true" :point="3" v-model="regParams.invValExp" id="invValExp" maxlength="18" class="wid200" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="tempParams.consMsg" class="content_box mt10">
        根据上海海关2018年第15号公告，Consignee Company ID 为必填项。 (To order不需要)
      </div>

      <div class="content_box mt10">
        <div class="flex_box mt20">
          <h2 class="content_title">Container Information</h2>
          <span class="ml_auto">
            <a
              class="button sm"
              @click="demDetInfoPop"
            >
              <span class="btn_icon calculator" />{{ $t('msg.CSBK100.163') }}
            </a> <!-- DEM/DET 계산기 -->
          </span>
        </div>
        <!-- content_box -->
        <table class="tbl_col">
          <colgroup>
            <col style="width:8%">
            <template v-if="isFlagRF">
              <col style="width:20%">
            </template>
            <template v-else>
              <col style="width:12%">
            </template>
            <col style="width:10%">
            <col style="width:10.5%">
            <col style="width:16%">
            <col style="width:10%">
            <col style="width:auto">
            <col style="width:9.5%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" colspan="4">CONTAINER</th>
              <th scope="col" rowspan="2">Special Cargo Type</th>
              <th scope="col" rowspan="2">Pick up Date</th>
              <th scope="col" rowspan="2">Pick up Place</th>
              <th scope="col" rowspan="2"><button class="tbl_icon plus" type="button" @click="addContainer()">plus</button></th>
            </tr>
            <tr>
              <th scope="col">Size</th>
              <th scope="col">Type<button class="tbl_icon help ml10" type="button" @click="fnContainerPop()"><span class="offscreen">도움말</span></button></th><!--버튼 클릭 시 NEWB010P050 팝업 호출-->
              <th scope="col">Q’ty</th>
              <th scope="col">Shipper's Van</th>
            </tr>
          </thead>
          <tbody v-for="(vo, idx) in regParams.containerList" :key="'tr_container_'+ idx + forceUpdate">
            <!-- <tbody v-for="(vo, idx) in regParams.containerList" :key="'tr_container_'+ idx"> -->
            <tr>
              <td>
                <select v-model="vo.cntrSzCd" :id="'cntrSzCd_' + idx" @change="changeCntrSz(vo)">
                  <option v-for="option in cntSzList" :key="option.cd" :value="option.cd">
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td class="tal">
                <div class="position_relative">
                  <template v-if="vo.cntrTypCd != 'RF' && vo.cntrTypCd != 'RH'">
                    <select v-model="vo.cntrTypCd" :id="'cntrTypCd_' + idx" @change="changeCntrTyp(vo, idx)">
                      <option v-for="option in vo.subCntrTypList" :key="option.cd" :value="option.cd" v-show="!option.isShow">
                        {{ option.cdNm }}
                      </option>
                    </select>
                  </template>
                  <template v-else>
                    <!-- select의 옵션이 REEFER or REEFER HIGHCUBE 일 경우 -->
                    <select v-model="vo.cntrTypCd" :id="'cntrTypCd_' + idx"
                            :class="vo.cgoTypCd === '05' ? '' : 'col_5'"
                            @change="changeCntrTyp(vo, idx);checkValidationCntr('rfSetupTmpr', idx)"
                    >
                      <option v-for="option in vo.subCntrTypList" :key="option.cd" :value="option.cd" v-show="!option.isShow">
                        {{ option.cdNm }}
                      </option>
                    </select>
                    <e-input-number
                      v-show="vo.cgoTypCd !== '05'"
                      :id="'rfSetupTmpr_' + idx"
                      v-model="vo.rfSetupTmpr"
                      :class="'col_25p'"
                      :is-minus="true"
                      maxlength="6"
                      :point="1"
                      @input="checkValidationCntr('rfSetupTmpr', idx)"
                    />
                    <select
                      v-show="vo.cgoTypCd !== '05'"
                      class="temperature" v-model="vo.rfTmprUnitCd"
                    >
                      <option value="C">℃</option>
                      <option value="F">°F</option>
                    </select>
                    <div
                      v-show="vo.cgoTypCd !== '05'"
                      class="tbl_form mt5"
                    >
                      <span>
                        <span>| 风扇</span>
                      </span>
                      <span class="tar">
                        <select class="col_7" v-model="vo.cntrRfStsCd" :id="'cntrRfStsCd_' + idx">
                          <option v-for="cvo in cntrRfStsList" :key="cvo.cd" :value="cvo.cd">
                            {{ cvo.cdNm }}
                          </option>
                        </select>
                      </span>
                    </div>
                    <!--// select의 옵션이 REEFER or REEFER HIGHCUBE 일 경우-->
                  </template>
                  <p class="tooltip_essen_box">
                    <span class="tooltip_essen" v-show="false"></span>
                  </p>
                </div>
              </td>
              <td>
                <e-input-number v-model="vo.cntrQty"
                                :id="'cntrQty_' + idx"
                                maxlength="3"
                                @input="checkValidationCntr('cntrQty', idx)"
                />
              </td>
              <td>
                <input
                  :id="'shprVanYn_' + idx"
                  type="checkbox"
                  :checked="regParams.polShtmCd !== '02' && vo.shprVanYn === 'Y'"
                  :disabled="regParams.polShtmCd === '02'"
                  @click="checkShprVanYn($event, vo, idx)"
                >
                <label :for="'shprVanYn_' + idx"><span class="offscreen">화주 컨테이너 체크</span></label>
              </td>
              <td>
                <select v-model="vo.cgoTypCd" :id="'cgoTypCd_' + idx" @change="changeCgoTyp(vo, idx)" :disabled="seqNo != ''">
                  <option value="" v-if="vo.isCgoTypEmpty">---------------------</option>
                  <option v-for="option in vo.subCgoTypList" :key="option.cd" :value="option.cd" v-show="!option.isShow">
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <td class="tal">
                <div class="input_calendar wd100">
                  <e-date-picker v-model="vo.pcupReqDt"
                                 :id="'pcupReqDt_' + idx"
                                 @input="checkValidationCntr('pcupReqDt', idx, 'KEYUP', ...arguments)"
                                 :disabled="isPcupReqDtReadOnly()"
                  />
                  <div class="mt5">
                    <select v-model="vo.pcupReqTm" :id="'pcupReqTm_' + idx" :disabled="isPcupReqDtReadOnly()">
                      <option v-for="option in pcupTimeList" :key="option.cd" :value="option.cd">
                        {{ option.cdNm }}
                      </option>
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <input type="text"
                       :id="'pcupCyNm_' + idx"
                       v-model="vo.pcupCyNm"
                       @click="getPickupPlace(idx)"
                       readonly
                       :disabled="isPcupReqDtReadOnly()"
                >
              </td>
              <td class="tac">
                <button
                  class="tbl_icon minus"
                  type="button"
                  @click="removeContainer(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
            <tr v-if="(vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH') && vo.iotCntrYn === 'Y'">
              <th colspan="2">
                IoT Container&nbsp;
                <button
                  class="tbl_icon help ml5"
                  type="button"
                  @click="openPopup('container-iot-pop')"
                >
                </button>
              </th>
              <td colspan="1">
                <input
                  :id="'iotEqpmYn_' + idx"
                  type="checkbox"
                  v-model="vo.iotEqpmYn"
                  :disabled="vo.iotCntrYn === 'Y' || vo. iotEqpmYn === 'Y' ? false : true"
                  :checked="vo.iotEqpmYn === 'Y' ? true : false"
                  true-value="Y"
                  false-value="N"
                  @click="checkIotYn($event, vo, idx)"
                />
                <label :for="'iotEqpmYn_' + idx"></label>
              </td>
              <td colspan="5">
              </td>
            </tr>
            <!--SR 작업하다가 일단 정지 -->
            <!-- <tr v-if="seqNo != '' && idx != 0">
              <td colspan="8">
                <input type="text">
              </td>
            </tr> -->
            <tr v-if="vo.cgoTypCd !== '' && '01|02|03|06'.indexOf(vo.cgoTypCd) > -1">
              <td colspan="8" class="text-left">
                <!-- dg s -->
                <booking-sr-table-dg
                  v-if="vo.cgoTypCd === '01'"
                  ref="dg"
                  :index="idx"
                  :parent-info="vo"
                  :cas-no-port-yn="casNoPortYn"
                  :tel-ctr-no="usrInfo.telCtrNo"
                  :disabled-yn="disabledYn"
                  :unno-disabled-yn="unnoDisabledYn"
                  :save-flag="saveFlag"
                  :detail-bkg-no="detailBkgNo"
                  @update="updateContainerList"
                />
                <!-- dg e -->

                <!-- oog s -->
                <booking-sr-table-oog
                  v-if="vo.cgoTypCd === '02' || vo.cgoTypCd === '03' || (vo.cgoTypCd === '03' && (vo.cntrTypCd === 'HC' && vo.cntrSzCd === '45'))"
                  ref="oog"
                  :index="idx"
                  :cgo-typ-cd="vo.cgoTypCd"
                  :parent-info="vo"
                  :kmd-vsl-check="kmdVslCheck"
                  :empty-flag="regParams.emptyFlag"
                  :save-flag="saveFlag"
                  :detail-bkg-no="detailBkgNo"
                  :grs-wt-ton="regParams.grsWt"
                  @update="updateContainerList"
                />
                <!-- oog e -->

                <!-- flexi s -->
                <booking-sr-table-flexi
                  v-if="vo.cgoTypCd === '06'"
                  ref="flexi"
                  :parent-info="vo"
                  :index="idx"
                  :save-flag="saveFlag"
                  :loi-down-yn="loiDownYn"
                  :detail-bkg-no="detailBkgNo"
                  @update="updateContainerList"
                />
                <!-- flexi e -->
              </td>
            </tr>
          </tbody>
        </table>
        <!--select의 옵션이 Dry에 cargo 옵션이 HZ 일 경우-->
        <div class="select_opt_area mt20">
          <div class="notice_message">
            <ul>
              <li>Container Type is Reefer : 必修输入 : 冷藏通风量, 设置单位, 设置温度,设置温度.</li>
              <li>Container Type is Dangerous Cargo : 必修输入 : Class, UN No., FlashPoint.</li>
              <li>Container Type is Out Of Gauge : 必修输入 : 前超 ,后超 , 左超 ,右超,超高.</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="content_box mt10">
        <!-- content_box -->

        <h2 class="content_title">Description Information</h2>
        <table class="tbl_col">
          <colgroup>
            <col width="45%">
            <col width="auto">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Mark &amp; No</th>
              <th scope="col">Description</th>
              <th scope="col"><button type="button" class="tbl_icon plus" @click="addDescInfo()">plus</button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in regParams.descInfoList" :key="'markInfo_' + idx">
              <td>
                <ETextarea2 :id="'mrk_' + idx"
                            :max-line="18"
                            :column-length="25"
                            v-model="vo.mrk"
                            :is-upper="true"
                            @input="checkValidationDescInfo()"
                            :padding-right="223"
                />
                <div class="add_mark_info">
                  <span>Mark Information</span>
                  <button type="button" class="tbl_icon plus" @click="addMrkPkg(vo)"></button>
                </div>
              </td>
              <td class="vat">
                <ETextarea2 :id="'dscr_' + idx"
                            :max-line="18"
                            :column-length="37"
                            v-model="vo.dscr"
                            :is-upper="true"
                            @input="checkValidationDescInfo()"
                            :padding-right="320"
                />
              </td>
              <td class="tac">
                <button type="button" class="tbl_icon minus" @click="removeDescInfo(idx)">minus</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10">
        <!-- content_box -->

        <h2 class="content_title">Mark Information</h2>
        <table class="tbl_col">
          <colgroup>
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:20%">
            <col style="width:20%">
            <col style="width:20%">
            <col style="width:auto">
            <col style="width:7%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" rowspan="2">Mark<br> Page</th>
              <th scope="col" rowspan="2">SEQ</th>
              <th scope="col" colspan="2">Marks</th>
              <th scope="col" colspan="2">Description of Goods</th>
              <th scope="col" rowspan="2">Del</th>
            </tr>
            <tr>
              <th scope="col" class="bor-left">Pkg’s</th>
              <th scope="col">Pkg Code</th>
              <th scope="col">Weight</th>
              <th scope="col">CBM</th>
            </tr>
          </thead>
          <template v-for="(vo, idx) in regParams.descInfoList">
            <tbody v-for="(svo, sidx) in vo.markInfoList" :key="'mrkPkg_' + idx + '_' + sidx ">
              <tr>
                <td rowspan="2">
                  {{ idx + 1 }}
                </td>
                <td rowspan="2">
                  {{ sidx + 1 }}
                </td>
                <td>
                  <e-input-number :is-comma="true"
                                  v-model="svo.pkgQty"
                                  :id="'pkgQty_' + idx + '_' + sidx"
                                  maxlength="13"
                                  @input="checkValidationMarkInfo('pkgQty')"
                  />
                </td>
                <td>
                  <select v-model="svo.pkgCd">
                    <template v-for="(cvo, cidx) in commonCodes['01130']">
                      <option v-if="cvo.cd != ' '" :key="'mrkPkg_pkgCd_' + idx + '_' + sidx + '_' + cidx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                    </template>
                  </select>
                </td>
                <td>
                  <e-input-number :is-phone="true"
                                  :digit-max="10"
                                  :point="3"
                                  v-model="svo.netWt"
                                  :id="'netWt_' + idx + '_' + sidx"
                                  maxlength="13"
                                  @input="checkValidationMarkInfo('netWt')"
                  />
                </td>
                <td>
                  <e-input-number :is-phone="true"
                                  :digit-max="4"
                                  :point="4"
                                  v-model="svo.netCbm"
                                  :id="'netCbm_' + idx + '_' + sidx"
                                  maxlength="13"
                                  @input="checkValidationMarkInfo('netCbm')"
                  />
                </td>
                <td class="tac" rowspan="2"><button type="button" class="tbl_icon minus" @click="removeMrkPkg(vo, sidx)">minus</button></td>
              </tr>
              <tr>
                <td class="bor-left" colspan="2">
                  <ETextarea2 :id="'mrkDsc_' + idx + '_' + sidx"
                              :max-line="3"
                              :column-length="37"
                              v-model="svo.mrkDsc"
                              @input="checkValidationMarkInfo('mrkDsc')"
                  />
                </td>
                <td colspan="2">
                  <ETextarea2 :id="'gdsDsc_' + idx + '_' + sidx"
                              :is-upper="true"
                              :max-line="3"
                              :column-length="37"
                              v-model="svo.gdsDsc"
                              @input="checkValidationMarkInfo('gdsDsc')"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </table>
      </div>

      <div class="content_box mt10">
        <!-- content_box -->

        <h2 class="content_title">Request</h2>
        <table class="tbl_col">
          <colgroup>
            <col width="50%">
            <col width="auto">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">CY(Depot) Remark</th>
              <th scope="col">Document Remark</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <textarea v-model="regParams.dorOdrRmk" id="dorOdrRmk" @keyup="checkValidationDorOdrRmk()"></textarea>
              </td>
              <td>
                <textarea v-model="regParams.docmRmk" id="docmRmk"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="content_box mt10">
        <table class="tbl_row mt20" v-if="regParams.podCtrCd === 'IR' || regParams.podCtrCd === 'RU'">
          <!-- 도착지 이란일 경우 생성 -->
          <colgroup>
            <col style="width:25%" />
            <col style="width:15%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row" rowspan="2">B/L SHIPPER</th>
              <th width="150">NAME</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.actShprCstNm"
                  id="ir_actShprCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('actShprCstNm')"
                  @keyup="checkIranValidation('actShprCstNm')"
                  @input="changeActShprCstNm"
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.actShprCstNmC
                    ) && regParams.iranBookingInfo.actShprCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_actShprCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row" style="border-left: 1px solid #e1e3eb;">ADDRESS</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.actShprAddr"
                  id="ir_actShprAddr"
                  type="text"
                  maxlength="100"
                />
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="2">B/L CONSIGNEE</th>
              <th scope="row">NAME</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cneCstNm"
                  id="ir_cneCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('cneCstNm')"
                  @keyup="checkIranValidation('cneCstNm')"
                  @input="regParams.iranBookingInfo.cneCstNm=regParams.iranBookingInfo.cneCstNm.toUpperCase()"
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.cneCstNmC
                    ) && regParams.iranBookingInfo.cneCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_cneCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row" style="border-left: 1px solid #e1e3eb;">ADDRESS</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cneCstAddr"
                  id="ir_cneCstAddr"
                  type="text"
                  maxlength="100"
                />
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="2">B/L NOTIFY</th>
              <th scope="row">NAME</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.ntifCstNm"
                  id="ir_ntifCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('ntifCstNm')"
                  @keyup="checkIranValidation('ntifCstNm')"
                  @input="regParams.iranBookingInfo.ntifCstNm=regParams.iranBookingInfo.ntifCstNm.toUpperCase()"
                />
                <span
                  :class="
                    $ekmtcCommon.isNotEmpty(
                      regParams.iranBookingInfo.ntifCstNmC
                    ) && regParams.iranBookingInfo.ntifCstNmC !== 'N'
                      ? ''
                      : 'd_none'
                  "
                  id="ir_ntifCstNmC"
                >
                  {{ $t("msg.CSBK100.115") }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row" style="border-left: 1px solid #e1e3eb;">ADDRESS</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.ntifCstAddr"
                  id="ir_ntifCstAddr"
                  type="text"
                  maxlength="100"
                />
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">END USER</th>
              <td>
                <div class="">
                  <table class="tbl_col">
                    <colgroup>
                      <col width="10%">
                      <col width="20%">
                      <col width="60%">
                      <col width="10%">
                    </colgroup>
                    <thead>
                      <tr>
                        <th rowspan="2">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                        <th>NAME</th> <!-- Name -->
                        <th>ADDRESS</th> <!-- ADDRESS -->
                        <th rowspan="2"></th>
                      </tr>
                      <tr>
                        <th style="border-left: 1px solid #e1e3eb;">TEL</th> <!-- Name -->
                        <th>EMAIL</th> <!-- EMAIL -->
                      </tr>
                    </thead>
                    <tbody v-for="(vo, idx) in endUserList" :key="'trEndUser_' + idx">
                      <tr>
                        <td rowspan="2">{{ idx + 1 }}</td>
                        <td>
                          <input
                            v-model="vo.endUsrNm"
                            :id="'ir_endUsrNm_' + idx"
                            maxlength="100"
                            type="text"
                            placeholder="NAME"
                            @blur="iranInputCheck('endUsrNm', idx)"
                            @keyup="checkIranValidation('endUsrNm', idx)"
                            @input="regParams.iranBookingInfo.endUsrNm=regParams.iranBookingInfo.endUsrNm.toUpperCase()"
                          >
                          <span
                            :class="
                              regParams.iranBookingInfo.endUsrNmCList &&
                                $ekmtcCommon.isNotEmpty(
                                  regParams.iranBookingInfo.endUsrNmCList[idx]
                                ) && regParams.iranBookingInfo.endUsrNmCList[idx] !== 'N'
                                ? ''
                                : 'd_none'
                            "
                            :id="'ir_endUsrNmC_' + idx"
                          >
                            {{ $t("msg.CSBK100.115") }}
                          </span>
                        </td>
                        <td>
                          <input
                            v-model="vo.endUsrAddr"
                            :id="'ir_endUsrAddr_' + idx"
                            placeholder="ADDRESS"
                            maxlength="100"
                            type="text"
                          >
                        </td>
                        <td rowspan="2">
                          <button
                            v-if="idx === 0"
                            type="button"
                            class="tbl_icon plus"
                            @click="addEndUser()"
                          >
                            plus
                          </button>
                          <button
                            v-if="idx !== 0"
                            type="button"
                            class="tbl_icon minus"
                            @click="removeEndUser(idx)"
                          >
                            minus
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-left: 1px solid #e1e3eb;">
                          <input
                            v-model="vo.endUsrTelNo"
                            :id="'ir_endUsrTelNo_' + idx"
                            placeholder="TEL"
                            maxlength="25"
                            type="text"
                            @input="
                              changeOnlyUpperEng(
                                $event,
                                regParams.iranBookingInfo,
                                'endUsrTelNo'
                              )
                            "
                          >
                        </td>
                        <td>
                          <input
                            v-model="vo.endUsrEmlAddr"
                            :id="'ir_endUsrEmlAddr_' + idx"
                            placeholder="EMAIL"
                            maxlength="100"
                            type="text"
                            @input="
                              changeOnlyUpperEng(
                                $event,
                                regParams.iranBookingInfo,
                                'endUsrEmlAddr'
                              )
                            "
                            @blur="checkValidationIranEndUsrEmlAddr(`ir_endUsrEmlAddr_${idx}`)"
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <!-- <tr>
              <th scope="row">B/L SHIPPER(ENGLISH ONLY)</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.actShprCstNm"
                  id="ir_actShprCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('actShprCstNm')"
                  @keyup="checkIranValidation('actShprCstNm')"
                  @input="changeActShprCstNm"
                >
                <span
                  :class="$ekmtcCommon.isNotEmpty(regParams.iranBookingInfo.actShprCstNmC) && regParams.iranBookingInfo.actShprCstNmC !== 'N' ? '' : 'd_none'"
                  id="ir_actShprCstNmC"
                >
                  {{ $t('msg.CSBK100.115') }}
                </span>
              </td>
            </tr> -->
            <!-- <tr>
              <th scope="row">NAME OF CONSIGNEE</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cneCstNm"
                  id="ir_cneCstNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('cneCstNm')"
                  @keyup="checkIranValidation('cneCstNm')"
                  @input="regParams.iranBookingInfo.cneCstNm=regParams.iranBookingInfo.cneCstNm.toUpperCase()"
                >
                <span
                  :class="$ekmtcCommon.isNotEmpty(regParams.iranBookingInfo.cneCstNmC) && regParams.iranBookingInfo.cneCstNmC !== 'N' ? '' : 'd_none'"
                  id="ir_cneCstNmC"
                >
                  {{ $t('msg.CSBK100.115') }}
                </span>
              </td>
            </tr> -->
            <!-- <tr>
              <th scope="row">END USER</th>
              <td>
                <div class="tbl_form position_relative">
                  <span class="dv col_5">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrNm"
                      id="ir_endUsrNm"
                      type="text"
                      maxlength="100"
                      @blur="iranInputCheck('endUsrNm')"
                      @keyup="checkIranValidation('endUsrNm')"
                      @input="regParams.iranBookingInfo.endUsrNm=regParams.iranBookingInfo.endUsrNm.toUpperCase()"
                    >
                    <span
                      :class="$ekmtcCommon.isNotEmpty(regParams.iranBookingInfo.endUsrNmC) && regParams.iranBookingInfo.endUsrNmC !== 'N' ? '' : 'd_none'"
                      id="ir_endUsrNmC"
                    >
                      {{ $t('msg.CSBK100.115') }}
                    </span>
                  </span>
                  <span class="dv">
                    <input type="checkbox" id="sameas" class="ml20" @click="sameConsigneeName"><label for="sameas">SAME AS CONSIGNEE</label>
                  </span>
                </div>
              </td>
            </tr> -->
            <!-- <tr>
              <th scope="row">END USER INFORMATION</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_2">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrTelNo"
                      id="ir_endUsrTelNo"
                      type="text"
                      placeholder="phone no"
                      maxlength="25"
                    >
                  </span>
                  <span class="dv col_3">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrEmlAddr"
                      id="ir_endUsrEmlAddr"
                      type="text"
                      placeholder="email"
                      maxlength="100"
                    >
                  </span>
                  <span class="dv">
                    <input
                      v-model="regParams.iranBookingInfo.endUsrAddr"
                      id="ir_endUsrAddr"
                      type="text"
                      placeholder="address"
                      maxlength="100"
                    >
                  </span>
                </div>
              </td>
            </tr> -->
            <tr>
              <th colspan="2" scope="row">NAME OF COMMODITY</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.cmdtNm"
                  id="ir_cmdtNm"
                  type="text"
                  maxlength="100"
                  @blur="iranInputCheck('cmdtNm')"
                  @keyup="checkIranValidation('cmdtNm')"
                  @input="regParams.iranBookingInfo.cmdtNm=regParams.iranBookingInfo.cmdtNm.toUpperCase()"
                >
                <span
                  :class="$ekmtcCommon.isNotEmpty(tempParams.cmdtNmC) && tempParams.cmdtNmC !== 'N' ? '' : 'd_none'"
                  id="ir_cmdtNmC"
                >
                  {{ $t('msg.CSBK100.116') }}
                </span>
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">HS CODE</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_5">
                    <e-input-number
                      v-model="regParams.iranBookingInfo.hsCd"
                      id="ir_hsCd"
                      :is-phone="true"
                      maxlength="10"
                      @blur="iranInputCheck('hsCd')"
                      @input="checkIranValidation('hsCd')"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv">
                    <button
                      v-if="saveFlag === 'EDIT' && $ekmtcCommon.isNotEmpty(regParams.bkgNo)"
                      type="button"
                      class="tbl_icon plus"
                      @click="fnIranHsCdMulti"
                    >
                      plus
                    </button>
                  </span>
                </div>
                <span
                  :class="$ekmtcCommon.isNotEmpty(tempParams.hsCdC) && tempParams.hsCdC !== 'N' ? '' : 'd_none'"
                  id="ir_hsCdC"
                >
                  {{ $t('msg.CSBK100.117') }}
                </span>
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">PURPOSE OF USE</th>
              <td>
                <input
                  v-model="regParams.iranBookingInfo.useDestCont"
                  id="ir_useDestCont"
                  type="text"
                  @keyup="checkIranValidation('useDestCont')"
                >
              </td>
            </tr>
            <tr v-if="false">
              <th colspan="2" scope="row">LOI</th>
              <td>
                <a class="button sm" @click="fnIranLOIDown()">LOI DownLoad</a>
                <span class="color_red font_12 ml10">{{ $t('msg.CSBK100.177') }}</span> <!-- 부킹 후 제출(업로드) 부탁드립니다. -->
                <a v-if="saveFlag === 'EDIT'" class="button sm" @click="fnIranUploadPop('01')">LOI Upload</a>
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">SHIPPER REMARK</th>
              <td>
                <textarea
                  v-model="regParams.iranBookingInfo.reqCont"
                  id="reqCont"
                >
                </textarea>
              </td>
            </tr>
            <tr>
              <th colspan="2" scope="row">KMTC REMARK</th>
              <td>
                {{ regParams.iranBookingInfo.apvCont }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_right_wrap mt10" style="overflow: auto;">
        <a class="button blue lg mr5" style="float:left;" @click="fnList()">{{ $t('msg.MAIN100.213') }}</a> <!-- 목록 -->
        <a class="button blue lg mr5" v-if="!['00', '02', '05'].includes(regParams.bkgStsCd) && regParams.cnclYn !== 'Y' && act == 'M'" style="float:left;" @click="soDownloadPop()">S/O Download</a>
        <a class="button blue lg mr5" v-if="act === 'R' || (regParams.cnclYn != 'Y' && (bkgUpdYn !== 'N' && srStat !== 'B'))" @click="saveSrInquery()">{{ $t('msg.CSBK100.178') }}</a> <!-- 저장 -->
        <a class="button blue lg mr5" v-if="regParams.cnclYn !== 'Y' && isVgmSrInquery()" @click="vgmSrInquery()">Input VGM</a> <!-- Input VGM -->
        <a class="button blue lg mr5" v-if="regParams.cnclYn !== 'Y'" @click="cancelSrInquery()">{{ $t('msg.ONEX020P070.001') }} </a> <!-- 취소 -->
        <!-- <a class="button blue lg mr5" v-if="regParams.cnclYn !== 'Y' && act !== 'M'" @click="fnReg()"> {{ $t('msg.CSBK100.234') }}</a> 등록 -->
      </div>
      <div class="booking_new_sr_popup">
        <win-layer-pop>
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="popParams"
            @close="closePoup"
            @selectFunc="selectFunc"
          />
        </win-layer-pop>
      </div>
      <div class="doc_req_pop">
        <win-layer-pop>
          <component
            v-if="uploadComponent"
            :is="uploadComponent"
            @close="closeUploadPopup"
            :parent-info="uploadParam"
            @set-dext-file-info="uploadCallbackFunc"
          />
        </win-layer-pop>
      </div>
      <component
        v-if="commonBookingComponent"
        :is="commonBookingComponent"
        :parent-info="commonBookingParams"
        ref="commonBooking"
        @callback="commonBookingCallback"
      />
      <component
        v-if="commonBLComponent"
        :is="commonBLComponent"
        :parent-info="commonBLParams"
        ref="commonBL"
        @callback="commonBLCallback"
      />
    </form>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import auth from '@/api/services/auth'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import bookings from '@/api/rest/trans/bookings'
import srInquiry from '@/api/services/srInquiry'
import EInputNumber from '@/components/common/EInputNumber'
import EAutoCompleteCommodity from '@/components/common/EAutoCompleteCommodity'
import ETransAddressInput from '@/components/trans/ETransAddressInput'
import ETransAddressTextarea from '@/components/trans/ETransAddressTextarea'
//import ETextareaLineColumn from '@/components/common/ETextareaLineColumn'
import ETransStringCheckInput from '@/components/trans/ETransStringCheckInput'
import ETransStringCheckCneeInput from '@/components/trans/ETransStringCheckCneeInput'
import EDatePicker from '@/components/common/EDatePicker'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import SampleDextUploader from '@/pages/sample/SampleDextUploader'
import ScheduleLegPop from '@/pages/schedule/popup/ScheduleLegPop' // 스케줄 조회 팝업
import VgmPop from '@/pages/trans/popup/VgmPop'/* Vgm 팝업 */
import ReturnChangePopVue from './popup/ReturnChangePop.vue'
import ContainerReturnVue from './ContainerReturn.vue'
import ETextarea2 from '@/components/common/ETextarea2'

//const PCUP_INPT_PSBL_PLC_CDS = ['TAO', 'XGG', 'LYG', 'NBO', 'DLC', 'XMN'] // 기존 픽업지, 픽업일 입력 가능소스 backup (asis 동일)
const PCUP_INPT_PSBL_PLC_CDS = ['TAO', 'XMN', 'SWA', 'FOC', 'FQG', 'DLC', 'SHA', 'NKG'] // 픽업지, 픽업일 입력 가능한 지역코드  <상해(SHA), 심천(SZP, SHK), 광저우(CAN), 대련(DLC)>
//const PCUP_INPT_PSBL_PLC_CDS = ['SHA'] // 2022.09.30 9월 SR - SHA 일때만, 픽업지, 픽업일 입력 가능한 지역코드

export default {
  name: 'BookingNewSr',
  components: {
    ETextarea2,
    EInputNumber,
    EAutoCompleteCommodity,
    ETransAddressInput,
    ETransAddressTextarea,
    //ETextareaLineColumn,
    ETransStringCheckInput,
    ETransStringCheckCneeInput,
    EDatePicker,
    WinLayerPop,
    DemDetCalculatorPop: () => import('@/pages/trans/popup/DemDetCalculatorPop'),
    BookingCallPop: () => import('@/pages/trans/popup/BookingCallPop'),
    SrPickUpPlacePop: () => import('@/pages/trans/popup/SrPickUpPlacePop'),
    BookingSrTableDg: () => import('@/components/trans/BookingSrTableDg.vue'),
    BookingSrTableOog: () => import('@/components/trans/BookingSrTableOog.vue'),
    BookingSrTableFlexi: () => import('@/components/trans/BookingSrTableFlexi.vue'),
    DocUploadPop: () => import('@/pages/trans/popup/DocUploadPop'),
    HsCodeIrPop: () => import('@/pages/trans/popup/HsCodeIRPop'),
    ShippingOrderPop: () => import('@/pages/pdf/popup/ShippingOrderPop'),
    ContainerPop: () => import('@/pages/trans/popup/ContainerPop'),
    CommonBooking: () => import('@/pages/trans/CommonBooking'),
    IranUploadPop: () => import('@/pages/trans/popup/IranUploadPop'),
    CommonBL: () => import('@/pages/trans/CommonBL'),
    'container-iot-pop': () => import('@/pages/trans/popup/ContainerIotPop'),
    SampleDextUploader,
    ScheduleLegPop,
    VgmPop
  },
  props: {
    detailBkgNo: {
      type: String,
      default: ''
    },
    detailSrRno: {
      type: String,
      default: ''
    },
    scheduleObj: {
      type: Object,
      default: null
    },
    seqNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      forceUpdate: 0,
      isFlagRF: false,
      commonBookingComponent: null,
      commonBookingParams: {},
      commonBookingCallback: null,
      parentInfo: {},
      templateNo: '',
      templateYn: 'N',
      bkgDocOver: 'N',
      commonCodes: {},
      defaultCtrCd: [],
      pageSrRno: '',
      cgoCount: 0,
      oogCount: 0,
      reCount: 0,
      srList: [],
      srStat: 'A',
      bkgUpdYn: 'N',
      //tall_cnt로 코드 생성 필요.
      tallycntSzList: [{ cd: 10, cdNm: 10 + ' ` ' }, { cd: 20, cdNm: 20 + ' ` ' }],
      cntSzList: [],
      cntrRfStsList: [],
      srInitData: {
        bkgAgntYn: '',
        findEmail: [],
        bkgAgntList: [],
        custInfo: {},
        actCstId: [],
        cneCstId: [],
        notiCstId: [],
        orgPlcList: []
      },
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' }
      ],
      cgoTypCdList: [
        { cdNm: 'HZ', cd: '01', rmk: 'RF/GP/HC/RH/OT/TK' },
        { cdNm: 'OOG', cd: '02', rmk: 'OT/FR/SR' },
        { cdNm: 'ING', cd: '03', rmk: 'OT/FR/SR/HC' },
        // { cdNm: 'RF', cd: '04', rmk: 'RH' },
        { cdNm: 'NOR', cd: '05', rmk: 'RF/RH' },
        { cdNm: 'FB', cd: '06', rmk: 'GP' }
      ],
      pcupTimeList: [
        { cdNm: '09:00', cd: '0900' },
        { cdNm: '10:00', cd: '1000' },
        { cdNm: '11:00', cd: '1100' },
        { cdNm: '12:00', cd: '1200' },
        { cdNm: '13:00', cd: '1300' },
        { cdNm: '14:00', cd: '1400' },
        { cdNm: '15:00', cd: '1500' },
        { cdNm: '16:00', cd: '1600' },
        { cdNm: '17:00', cd: '1700' }
      ],
      customComponent: null,
      popParams: {},
      selectFunc: null,
      commonBLComponent: 'CommonBL',
      commonBLParams: {},
      commonBLCallback: '',
      uploadComponent: null,
      uploadType: '',
      uploadParam: [],
      uploadCallbackFunc: null,
      usrInfo: {
        usrTel: '',
        usrFax: '',
        telCtrNo: ''
      },
      casNoPortYn: 'N',
      disabledYn: '',
      unnoDisabledYn: '',
      saveFlag: '',
      kmdVslCheck: '',
      // UI 에서만 사용할 변수
      tempParams: {
        cntrSeq: 1,
        tallCntSeq: 1,
        cmdtNmC: '',
        hsCdC: '',
        bizPicEmlAddr: '',
        cneeCopy: false,
        showActShprStateNm: true,
        showCneCstStateNm: true,
        showNtifCstStateNm: true,
        hscdCheckType: '',
        hscdErrorMessage: '',
        showTaxId: '',
        taxNm: '',
        maxlengthPodHsCd: '6',
        showIecId: false,
        showIecIdSub: false,
        consRed: false,
        consMsg: false
      },
      act: 'R', // R : 등록, M : 수정
      regParams: {}, // DB에 저장할 값
      mainData: {}, // 수정시 필요???
      objPlace: {
        lstDestCtrCd: [],
        issCtrCd: []
      },
      testData: {
        testYn: 'N',
        schedule: []
      },
      ShippingOrderPop: {
        testCtrCd: '',
        testUsrNo: '',
        testBkgPlcCd: '',
        testAgtCd: '',
        items: []
      },
      serverYear: '',
      serverMonth: '',
      serverDay: '',
      serverHour: '',
      serverMin: '',
      serverSecond: '',
      editRefresh: false,
      tmpContainerDGList: [],

      endUserList: [],
      endUsrNmCList: [],
      actShprCstList: [],
      devYn: process.env.VUE_APP_MODE !== 'PRD',
      vocYn: '',
      isShipperLOI: false,
      isShipperPOA: false,
      isShipperLOIPlc: true, // Shipper LOI 첨부 가능지역여부
      excelUploadShow: true,
      saveShipperLOI: '',
      saveShipperPOA: '',
      ctrCdList: [],
      pkgInfo: '',
      bkgShprCodAreaYn: 'N',
      loiDownYn: 'Y' // flexi bag loi 다운로드 버튼 노출 여부
    }
  },
  computed: {
    ...rootComputed,
    _containerList () {
      return this.regParams.containerList ? this.regParams.containerList : []
    }
  },
  watch: {
    'regParams.containerList': {
      deep: true,
      handler (val) {
        console.log('change')
      }
    },
    editRefresh: {
      deep: true,
      handler (val) {
        //TEMPLATE 불러오는 화면을 자연스럽게 하기 위해 위에 선언
        if (this.detailSrRno) {
          this.saveFlag = 'EDIT'
          this.templateYn = 'N'
          this.pageSrRno = this.detailSrRno
        } else {
          this.saveFlag = 'ADD'
          this.templateYn = 'Y'
          //Part S/R+ 로 타고 들어온 경우 seqNo를 SrRno로 셋팅
          if (this.seqNo) {
            this.pageSrRno = this.seqNo.substring(0, (this.seqNo.length - 1))
          }
        }
        this.init()
      }
    }
  },
  created () {
    //TEMPLATE 불러오는 화면을 자연스럽게 하기 위해 위에 선언
    if (this.detailSrRno) {
      this.saveFlag = 'EDIT'
      this.templateYn = 'N'
      this.pageSrRno = this.detailSrRno
    } else {
      this.saveFlag = 'ADD'
      this.templateYn = 'Y'
      //Part S/R+ 로 타고 들어온 경우 seqNo를 SrRno로 셋팅
      if (this.seqNo) {
        this.pageSrRno = this.seqNo.substring(0, (this.seqNo.length - 1))
      }
    }
    this.init()
  },
  methods: {
    async getActShprCst () {
      // console.log('getActShprCst bookingAgent @@@@@ ', this.regParams.bookingAgent)
      // console.log('getActShprCst actShprCstNo @@@@@ ', this.regParams.actShprCstNo)
      // console.log('getActShprCst actShprCstNo @@@@@ ', this.regParams.porPlcCd)
      // console.log('getActShprCst actShprCstNo @@@@@ ', this.regParams.dlyPlcCd)

      if (this.regParams.fixdCgoYn === 'Y') {
        if (!this.regParams.porPlcCd && !this.regParams.dlyPlcCd) {
          await this.$ekmtcCommon.asyncAlertMessage({
              message: 'Please select target schedules first.',
              useConfirmBtn: false,
              onCloseSelf: (resolve, $Simplert) => {
                this.regParams.fixdCgoYn = 'N'
                resolve(true)
                $Simplert.close()
              }
          })
        }
      }

      if (this.regParams.bookingAgent && this.regParams.porPlcCd && this.regParams.dlyPlcCd) {
        const etd = this.regParams.etd.substring(0, 8)
        await srInquiry.getActShpr(this.regParams.porPlcCd, this.regParams.dlyPlcCd, etd, this.regParams.podPortCd).then(res => {
            this.actShprCstList = res.data
            console.log(res)

            // const actShprCstList = this.actShprCstList
            // if (actShprCstList.length === 0 && this.regParams.fixdCgoYn === 'Y') {
            //   this.regParams.fixdCgoYn = 'N'
            // }
        })
      }

      // this.actShprCstList.push({
      //   actShprCstNo: 'actShprCstNo',
      //   actShprCstEnm: 'actShprCstEnm'
      // })
    },
    getRandomKey () {
      return Math.random().toString(36).substr(2, 11)
    },
    async init () {
      await this.initCommCodes()
      //SrRno가 존재하거나 Part S/R+ 로 들어온 경우 상세 조회
      if (this.detailSrRno) {
        this.setSrData('INFO')
      } else if (this.seqNo) {
        this.setSrData('REQ')
      } else {
        this.setSrData('NEW')
      }

      if (this.scheduleObj !== null && this.scheduleObj !== undefined) {
        this.callbackSearchSchedule(this.scheduleObj)
      }
      commons.getServerTime().then(res => {
        this.serverYear = res.data.substring(0, 4)
        this.serverMonth = res.data.substring(4, 6)
        this.serverDay = res.data.substring(6, 8)
        this.serverHour = res.data.substring(8, 10)
        this.serverMin = res.data.substring(10, 12)
        this.serverSecond = res.data.substring(12, 14)
      })

      commons.getCommonsCodesCountry().then(res => {
        this.ctrCdList = res
      })

      if (this.memberDetail !== undefined) {
        this.bkgPlcCd = this.memberDetail.bkgPlcCd
        this.usrCtrCd = this.memberDetail.userCtrCd
      }

      // Shipper LOI 첨부 불가지역 체크
      if (['JP', 'TH', 'HK', 'SG', 'IN'].indexOf(this.usrCtrCd) >= 0) {
        this.isShipperLOIPlc = false
      } else if (this.usrCtrCd === 'CN' && ['CAN', 'XMN'].indexOf(this.bkgPlcCd) >= 0) {
        this.isShipperLOIPlc = false
      } else {
        this.isShipperLOIPlc = true
      }

      this.$nextTick(() => {
        console.log('this.regParams.podCtrCd @@@@ ', this.regParams.podCtrCd)
        // if (this.regParams.podCtrCd === 'IR' || this.regParams.podCtrCd === 'RU') {
          console.log('this.endUserList.length @@@@ ', this.endUserList.length)

          if (this.endUserList.length === 0) {
            this.addEndUser()
          }
        // }

        this.initPkgInfo()
      })
    },
    async initCommCodes () {
      const arrCdId = ['01031', '01033', '01038', '01130', 'CS038', '00003', '01291']

      await Promise.all([
        auth.info(this.auth.jwt).then(res => {
          const result = res.data

          if (this.saveFlag === 'ADD') {
            this.initUserInfo(result)
          }
        }),
        // 공통코드 가져오기
        commons.getCommonsCodesCommon(arrCdId).then(res => {
          this.commonCodes = res
          this.cntSzList = this.commonCodes['01031']
          this.cntrRfStsList = this.commonCodes['01291']
          this.commonCodes['01130'] = this.commonCodes['01130'].sort((a, b) => {
            if (b.cdNm > a.cdNm) return -1
            else if (b.cdNm === a.cdNm) return 0
            else return 1
          })
          this.cntrRfStsList = this.cntrRfStsList.sort((a, b) => a.inqOrd - b.inqOrd)
        }),
        // 국가정보 가져오기
        commons.getCommonsCodesCountry('N').then(res => {
          this.defaultCtrCd = res
        }),
        // S/R 등록시 필요한 초기 데이터
        srInquiry.getSrInitData().then(res => {
          this.srInitData = res
          if (this.srInitData.findEmail === null || this.srInitData.findEmail === undefined) {
            this.srInitData.findEmail = []
          }
          if (this.srInitData.custInfo === null || this.srInitData.custInfo === undefined) {
            this.srInitData.custInfo = {}
          }
          if (this.srInitData.bkgAgntYn !== 'Y' && this.memberDetail.bkgPlcCd !== 'NBO') {
            if (this.srInitData.bkgAgntList.length > 0) {
              this.regParams.bookingAgent = this.srInitData.bkgAgntList[0].cstNo
            }
          }
        }),
        transCommon.findUserVocYn().then((res) => {
          this.vocYn = res.data
        })
      ])
    },
    updateContainerList (c, index) {
      console.log('@@@@ enter updateContainerList')
      console.log('@@@@ c: ', c)
      console.log('@@@@ index: ', index)
      console.log('@@@@ this.regParams.containerList[index]: ', this.regParams.containerList[index])
      this.$nextTick(() => {
      let _o = {
        ...this.regParams.containerList[index],
        ...c
      }
      console.log('@@@@ _o: ', _o)
        // this.regParams.containerList[index] = {
        //   ...this.regParams.containerList[index],
        //   ...c
        // }
      })
      this.regParams.containerList[index] = {
          ...this.regParams.containerList[index],
          ...c
        }

      //
    },
    initUserInfo (obj) {
      const regParams = this.regParams
      if (obj !== undefined) {
        const usrTel = obj.tel1 + ')' + obj.tel2 + '-' + obj.tel3 + '-' + obj.tel4
        const usrFax = obj.fax1 + ')' + obj.fax2 + '-' + obj.fax3 + '-' + obj.fax4

        this.usrInfo.usrTel = usrTel
        this.usrInfo.usrFax = usrFax
        this.usrInfo.telCtrNo = obj.tel1

        regParams.bizPicNm = this.auth.userName
        regParams.bizPicTelPlcNo = obj.tel2 || ''
        regParams.bizPicTelOfcNo = (obj.tel3 || '') + (obj.tel4 || '')
        regParams.bizPicFaxPlcNo = obj.fax2 || ''
        regParams.bizPicFaxOfcNo = (obj.fax3 || '') + (obj.fax4 || '')
        regParams.bizPicMbpBsnNo = obj.cel1 || ''
        regParams.bizPicMbpOfcNo = (obj.cel2 || '') + (obj.cel3 || '')
        regParams.bizPicEmlAddr = obj.userEmail
      }
    },
    async setSrData (type) {
      console.log('@@@@ enter setSrData')
      console.log('@@@@ type: ', type)
      // 기본 설정
      const def = {
        emailcheck: 'Y',
        bizPicEmlAddr: this.srInitData.findEmail.length > 0 ? this.srInitData.findEmail[0] : '',
        shprCstAddr: '',
        bkgShprCstEnm: this.srInitData.custInfo.cstEnm || '',
        dorOdrRmk: '',
        docmRmk: '',
        cneCstEnm: '',
        cnePicNm: '',
        cneCstAddr: '',
        cnePicTelNo: '',
        cnePicFaxNo: '',
        cnePicEmlAddr: '',
        consYn: '',
        ntifCstEnm: '',
        ntifCstAddr: '',
        bookingAgent: this.regParams.bookingAgent || this.memberDetail.cstCd,
        bkgShprCstNo: this.memberDetail.cstCd,
        shpmNo: '',
        hsCd: '',
        podHsCd: '',
        chemicalYn: 'N',
        cancelCd: '',
        chemicalFileList: [],
        porCtrCd: '',
        porPlcNm: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyPlcNm: '',
        dlyPlcCd: '',
        vslNm: '',
        voyNo: '',
        vslCd: '',
        obrdDt: '',
        etd: '',
        eta: '',
        hidLegInfo: '',
        legTermHPNT: '',
        podPortCd: '',
        podTrmlCd: '',
        schFrtAppNo: '',
        schLogDtm: '',
        lstDestCtrCd: '',
        lstDestPlcCd: '',
        polShtmCd: '01', // 01 => CY (01033)
        podShtmCd: '01', // 01 => CY (01033)
        issCtrCd: '',
        issPlcCd: '',
        frtPncCd: 'P',
        soNo: '',
        srNo: '',
        pkgCd: '59',
        oprRmk: '',
        emptyFlag: 'F',
        grsWt: '',
        grsCbm: '0',
        actShprCstCtrCd: '',
        actShprCstCityNm: '',
        actShprStateNm: '',
        actShprStateCd: '',
        actShprPostNo: '',
        actShprCstPicNm: '',
        actShprCstTelNo: '',
        actShprCstFaxNo: '',
        actShprCstEmlAddr: '',
        actMin: '',
        actMax: '',
        actShprIdCatCdTemp: '',
        actShprIdCatCd: '',
        actShprIdNo: '',
        cneCstCtrCd: '',
        cneCstCityNm: '',
        cneCstStateNm: '',
        cneCstStateCd: '',
        cneCstPostNo: '',
        cneCstPicNm: '',
        cneCstTelNo: '',
        cneCstFaxNo: '',
        cneCstEmlAddr: '',
        cneMin: '',
        cneMax: '',
        cneIdCatCdTemp: '',
        cneIdCatCd: '',
        cneIdNo: '',
        ntifCstCtrCd: '',
        ntifCstCityNm: '',
        ntifCstStateNm: '',
        ntifCstPostNo: '',
        ntifCstPicNm: '',
        ntifCstTelNo: '',
        ntifCstFaxNo: '',
        ntifCstEmlAddr: '',
        ntifMin: '',
        ntifMax: '',
        ntifIdCatCdTemp: '',
        ntifIdCatCd: '',
        ntifIdNo: '',
        kmdVslCheck: '',
        idTaxNo: '',
        wstCgoYn: '',
        imLicNo: '',
        imBnkGurNo: '',
        iecNo: '',
        gstNo: '',
        iecPicEmlAddr: '',
        panNo: '',
        ntifPanNo: '',
        invValCurCd: '',
        invValExp: '',
        polShaTsYn: '',
        fwdrCstNo: '',
        tallyList: [],
        containerList: [],
        descInfoList: [],
        iranBookingInfo: {
          actShprCstNm: '',
          actShprCstNmC: '',
          cneCstNm: '',
          cneCstNmC: '',
          endUsrNm: '',
          endUsrNmC: '',
          endUsrTelNo: '',
          endUsrEmlAddr: '',
          cmdtNm: '',
          hsCd: '',
          useDestCont: '',
          reqCont: '',
          apvCont: '',
          endUsrNmCList: []
        },
        temp: '',
        fixdCgoYn: 'N',
        actShprCstNo: '',
        frtAppNo: '',
        hcneCstNo: '',
        trdShipperFile: [],
        poaShipperFile: []
      }

      if (type === 'NEW') {
        this.act = 'R'
        this.regParams = { ...def }

        this.tempParams.cntrSeq = 1
        this.addContainer()
        this.addDescInfo()

        if (this.$route.query.form_nation) {
          this.regParams.issCtrCd = this.$route.query.form_nation
        } else {
          this.regParams.issCtrCd = this.memberDetail.userCtrCd
        }

        if (this.memberDetail.bkgPlcCd === 'SZP') {
          this.regParams.issPlcCd = 'SZP'//'SHK'
        } else {
          this.regParams.issPlcCd = this.memberDetail.bkgPlcCd
        }
        this.fnChangeCtrCd('issCtrCd', '')
      } else if (type === 'INFO') {
        const THIS = this
        await srInquiry.getSrInqueryInfo(this.pageSrRno).then(res => {
          const result = res.data

          if (result) {
            //INFO의 경우 다운로드 URL를 따로 셋팅해줄 필요가 있다.
            if (result.chemicalYn === 'Y' && result.chemicalFileList) {
              result.chemicalFileList.map(obj => {
                obj.size = obj.fileSize
                //obj.fileId = 'http://trans.ekmtc:9000/trans/trans/file-download?bkgNo=' + obj.bkgNo + '&atchFileSeq=' + obj.fileSeq + '&type=BOOKING_SR'
              })
            }
            THIS.act = 'M'
            THIS.tempParams.cntrSeq = result.containerList.length
            let enabledFeCatCd = 0
            result.containerList.forEach((item, index) => {
              if (item.feCatCd === 'E') enabledFeCatCd++
            })
            console.log('@@@@ enabledFeCatCd: ', enabledFeCatCd)
            if (enabledFeCatCd > 0) {
              result.emptyFlag = 'E'
            } else {
              result.emptyFlag = 'F'
            }
            console.log('@@@@ result.emptyFlag: ', result.emptyFlag)
            //srStat => A : Booking 만 생성 Mark, DESC 수정 가능, B : Mark, DESC,Tally 수정 가능
            THIS.srStat = result.srStat
            THIS.srList = result.srList
            THIS.bkgUpdYn = result.bkgUpdYn
            THIS.cgoCount = result.cgoCount
            THIS.oogCount = result.oogCount
            THIS.reCount = result.reCount
            const docCloseTime = result.cgoRest
            const localDate = THIS.$ekmtcCommon.getStrToDate(result.localDtm) //현재시간
            const docDate = THIS.$ekmtcCommon.getStrToDate(docCloseTime) //서류마감일시
            if (THIS.$ekmtcCommon.isNotEmpty(docCloseTime)) {
              if (docDate <= localDate) {
                THIS.bkgDocOver = 'Y'
              }
            }
            this.setDetail(def, result)
            //INFO 에서 불러온 값을 AXOIS 호출해서 셋팅
            THIS.regParams.hidCS008I = THIS.regParams.makecs008i
            console.log('@@@@ THIS.regParams: ', THIS.regParams)
            this.casNoPortYn = THIS.regParams.casPortCd === 'Y' ? 'Y' : 'N'
            THIS.fnChangeCtrCd('lstDestCtrCd')
            THIS.fnChangeCtrCd('issCtrCd')
            THIS.fnChangeActCatId(THIS.regParams.actShprCstCtrCd, 'INIT')
            THIS.fnChangeCneCatId(THIS.regParams.cneCstCtrCd, 'INIT')
            THIS.fnChangeNotiCatId(THIS.regParams.ntifCstCtrCd, 'INIT')
          }
        })

        // actual-shipper=cst 값이 있으면 넣어줌
        await this.getActShprCst()
      } else if (type === 'REQ') {
        const THIS = this
        await srInquiry.getSrInqueryReqInfo(this.pageSrRno).then(res => {
          const result = res.data
          if (result) {
            THIS.act = 'R'
            THIS.setDetail(def, result)
            THIS.fnChangeCtrCd('issCtrCd')
          }
        })
        THIS.addDescInfo()
      } else if (type === 'TEMPLATE') {
        const srRno = this.templateNo
        if (this.$ekmtcCommon.isEmpty(srRno)) {
          return
        }
        const THIS = this
        await srInquiry.getTemplateInfo(srRno).then(res => {
          const result = res.data
          if (result) {
            //TEMPLATE 경우 cemicalYn이 Y더라도 무조건 N으로 파일 첨부 없는 걸로 설정
            result.containerList.forEach((item, index) => {
              if (item.rfTmprUnitCd === ' ' || this.$ekmtcCommon.isEmpty(item.rfTmprUnitCd)) {
                item.rfTmprUnitCd = 'C'
              }
            })
            result.chemicalYn = 'N'
            THIS.act = 'R'
            THIS.setDetail(def, result, type)
            THIS.fnChangeActCatId(THIS.regParams.actShprCstCtrCd, 'INIT')
            THIS.fnChangeCneCatId(THIS.regParams.cneCstCtrCd, 'INIT')
            THIS.fnChangeNotiCatId(THIS.regParams.ntifCstCtrCd, 'INIT')
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.383'))
            this.templateNo = ''
          }
        })
      }
      // this.$route.query.form_nation

      const TH = this

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.polPortCd)) {
        srInquiry.checkCnSrBkgShipCodeArea(this.regParams.polPortCd).then(res => {
          const data = res.data
          TH.bkgShprCodAreaYn = data
          // console.log('checkCnSrBkgShipCodeArea data @@@@@@ ', data)
        })
      }

      if (this.regParams.trdShipperFile.length > 0) {
        const trdShipperFile = this.regParams.trdShipperFile[0]
        if (this.$ekmtcCommon.isNotEmpty(trdShipperFile) && this.$ekmtcCommon.isNotEmpty(trdShipperFile.reqRno)) {
          this.saveShipperLOI = 'Y'
        }
      }

      if (this.regParams.poaShipperFile.length > 0) {
        const poaShipperFile = this.regParams.poaShipperFile[0]
        if (this.$ekmtcCommon.isNotEmpty(poaShipperFile) && this.$ekmtcCommon.isNotEmpty(poaShipperFile.reqRno)) {
          this.saveShipperPOA = 'Y'
        }
      }
    },
    setDetail (def, srVo, type) {
      console.log('@@@@ enter setDetail')
      console.log('@@@@ def: ', def)
      console.log('@@@@ srVo: ', srVo)
      console.log('@@@@ type: ', type)
      this.regParams = {
        ...def,
        ...srVo
       }

       this.$set(this, 'regParams', {
        ...def,
        ...srVo
       })

      this.regParams.containerList.some(obj => {
        if (obj.cntrTypCd === 'RF' || obj.cntrTypCd === 'RH') {
          this.isFlagRF = true
          return true
        }
      })

      console.log('@@@@ this.regParams: ', this.regParams)
      this.regParams.bookingAgent = srVo.fwdrCstNo
      // Shipper, Consignee, Notify
      this.fnAddInfoStateSet(this.regParams.actShprCstCtrCd, 'ACT', 'INIT')
      this.fnAddInfoStateSet(this.regParams.cneCstCtrCd, 'CNE', 'INIT')
      this.fnAddInfoStateSet(this.regParams.ntifCstCtrCd, 'NTIF', 'INIT')

      if (this.regParams.dlyPlcCd === 'NSA') {
        console.log('@@@@ NSA')
        this.tempParams.showIecId = true
        this.tempParams.showIecIdSub = true // Gst, Email
      } else if (this.regParams.dlyCtrCd === 'IN') {
        console.log('@@@@ IN')
        this.tempParams.showIecId = true
        this.tempParams.showIecIdSub = false // Gst, Email
      } else {
        console.log('@@@@ else')
        this.tempParams.showIecId = false
        this.tempParams.showIecIdSub = false
      }
      // Shipper, Consignee, Notify
      if (this.regParams.containerList.length === 0) {
        this.tempParams.cntrSeq = 1
        this.addContainer()
      } else {
        this.regParams.containerList.forEach(cvo => {
          cvo.cgoTypCd = cvo.cgoTypCd || ''

          // 픽업일 보관
          if (this.$ekmtcCommon.isNotEmpty(cvo.pcupReqDt)) {
            //info.pcupReqDtBak = info.pcupReqDt
            cvo.pcupReqDtOld = cvo.pcupReqDt
          }

          if (type === 'TEMPLATE') {
            cvo.uploadFileList = null
            cvo.reqRno = ''
            cvo.scgAppNo = '' // 위험물관리번호
            cvo.apvCont = ''
            cvo.apvStsNm = '' // 템플릿 불러오는 경우 status 항목 초기화 하여 화면 노출 안함. 2023.06.08
            cvo.apvStsCd = '' // 템플릿 불러온 후 저장시 status 코드 초기화 되도록 수정함. 2023.06.08
            cvo.reqConts = '' // 템플릿 불러오는 경우 Request Remark 항목 화면 노출 및 저장값 초기화. 2023.06.08
          }

          if (cvo.containerDGList === undefined) {
            cvo.containerDGList = []
          }
          console.log('@@@@ casNoList 처리')

          cvo.containerDGList.forEach(dgVo => {
            const casNoObjList = []
            console.log('@@@@ dgVo.casNoList: ', dgVo.casNoList)
            if (dgVo.casNoList !== undefined) {
              console.log('@@@@ this.casNoPortYn Y 세팅')
              // this.casNoPortYn = 'Y' // 템플릿 불러오기시 CasNo 가 있는경우 일단 CasNo 사용 포트로 설정
              this.casNoPortYn = this.regParams.casPortCd
              dgVo.casNoList.forEach(cno => {
                casNoObjList.push({ casNo: cno })
              })
            } else {
              casNoObjList.push({ casNo: '', rto: '' })
              dgVo.casNoList = [{
                casNo: '',
                rto: ''
              }]
            }
            this.$set(dgVo, 'casNoObjList', casNoObjList)
            // dgVo.casNoObjList = casNoObjList
          })
          this.setCntrTypList(cvo)
          this.setCgoTypList(cvo)
          this.$nextTick(() => {
            const iranBookingInfo = this.regParams.iranBookingInfo
            if (iranBookingInfo !== undefined && this.$ekmtcCommon.isNotEmpty(iranBookingInfo.hsCd)) {
              const frm = document.querySelector('#srForm')
              frm.querySelector('#ir_hsCd').disabled = false // HS CODE 존재시 수정 불가처리
            }
          })
        })
      }

      this.getActShprCst()
      this.fnCheckLoiDownEnable(this.regParams)

      // 이란, 러시아 부킹일때 END_USER 가져오기
      if (this.regParams.podCtrCd === 'IR' || this.regParams.podCtrCd === 'RU') {
       if (this.regParams.iranBookingInfo.endUserList && this.regParams.iranBookingInfo.endUserList.length > 0) {
          this.endUserList = this.regParams.iranBookingInfo.endUserList
        }
          this.regParams.iranBookingInfo.endUsrNmCList = this.regParams.iranBookingInfo.endUsrNmCList || []
      }
    },
    templateEnter (e) {
      if (e.keyCode === 13) {
        this.templateSearch()
      }
    },
    templateSearch () {
      this.popParams = {
        templateNo: this.templateNo,
        templateFlag: 'SR',
        podCtrCd: this.regParams.podCtrCd,
        podPortCd: this.regParams.podPortCd,
        polCtrCd: this.regParams.polCtrCd,
        polPortCd: this.regParams.polPortCd
      }
      this.selectFunc = this.getTemplateInfo
      this.openPopup('BookingCallPop')
    },
    getTemplateInfo (obj) {
      const srRno = obj.srRno
      this.templateNo = srRno
      this.pageSrRno = srRno
      this.setTemplateInfo()
    },
    setTemplateInfo () {
      console.log('@@@@ enter setTemplateInfo')
      this.regParams.containerList = []
      const THIS = this
      THIS.setSrData('TEMPLATE')
    },
    getPickupPlace (idx) {
      if (this.isPcupReqDtReadOnly()) {
        return
      }

      const cvo = this._containerList[idx]

      this.popParams = {
        cntrSize: cvo.cntrSzCd,
        cntrType: cvo.cntrTypCd,
        pickUpDate: cvo.pcupReqDt,
        selectRow: idx,
        iotCntrYn: cvo.iotEqpmYn
      }
      const TH = this
      this.selectFunc = (obj) => {
        cvo.pcupCyNm = obj.cyNm
        cvo.pcupCyCd = obj.cyCd
        TH.checkValidationCntr('pcupCyNm', idx)
      }
      this.openPopup('SrPickUpPlacePop')
    },
    fnChangeBL (e) {
      const val = e.target.value
      if (val === '') {
        return
      }
      this.pageSrRno = val
      this.setSrData('INFO')
    },
    fnList () {
      this.$router.push('/working/progress')
    },
    fnAddSrNumber (type) {
      if (type !== 'PBL') return
      const msg = 'Part BL ?'
      this.$ekmtcCommon.confirmCallBack(msg, () => { this.nextPartBlNo() })
    },
    nextPartBlNo () {
      srInquiry.postNextPartBlNo(this.pageSrRno).then(res => {
        const result = res.data
        if (result) {
          this.$router.push({ name: 'booking-new', params: { seqNo: result } }).catch(() => {})
        }
      })
    },
    // 스케줄 팝업 호출
    searchSchedule () {
      //Part S/R+ 팝업 호출 못하게 막는다
      if (this.seqNo !== '') {
        return
      }

      this.selectFunc = this.callbackSearchSchedule

      const polCtrCd = this.regParams.porCtrCd !== '' ? this.regParams.porCtrCd : this.memberDetail.userCtrCd
      const polPlcCd = this.regParams.porPlcCd !== '' ? this.regParams.porPlcCd : this.memberDetail.bkgPlcCd
      const podCtrCd = this.regParams.dlyCtrCd !== '' ? this.regParams.dlyCtrCd : 'KR'
      const podPlcCd = this.regParams.dlyPlcCd !== '' ? this.regParams.dlyPlcCd : 'PUS'

      this.popParams = {
        ScheduleLegPop: 'Y',
        podCtrCd: podCtrCd,
        podPlcCd: podPlcCd,
        polCtrCd: polCtrCd,
        polPlcCd: polPlcCd
      }
      this.openPopup('ScheduleLegPop')
    },
    callbackSearchSchedule (obj) {
      console.log('@@@@ obj: ', obj)
      if (obj.params === undefined) {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005')) // 조회에 실패했습니다. 관리자에게 문의해주세요.
        return
      }

      this.setScheduleCommon(obj.params)
    },
    // 스케줄 팝업에서 데이터 전송
    setScheduleCommon (schResultVo) {
      console.log('@@@@ enter setScheduleCommon')

      const TH = this

      if (schResultVo === null || schResultVo === undefined) {
        return
      }

      if (this.act === 'M') {
        if (this.regParams.dlyCtrCd !== 'IR' && schResultVo.podCtrCd === 'IR') {
          alert('目的港从其他地区无法改成伊朗（IRAN)。 如有疑问请联系相关业务部门 。')
          return
        }
      }
      this.regParams.porCtrCd = schResultVo.polCtrCd
      this.regParams.porPlcNm = schResultVo.polName
      this.regParams.porPlcCd = schResultVo.polPortCd
      this.regParams.dlyCtrCd = schResultVo.podCtrCd
      this.regParams.dlyPlcNm = schResultVo.podName
      this.regParams.dlyPlcCd = schResultVo.podPortCd

      this.regParams.vslNm = schResultVo.vslNm
      this.regParams.voyNo = schResultVo.voyNo
      this.regParams.vslCd = schResultVo.vslCd
      //this.regParams.obrdDt = schResultVo.eta
      // 220721 선박명이 Feeder 일때와 같이 ETA가 없는 경우는 ETD를 세팅
      this.regParams.obrdDt = this.$ekmtcCommon.isNotEmpty(schResultVo.eta) ? schResultVo.eta : schResultVo.etd
      this.regParams.etd = schResultVo.etd + schResultVo.etdTm
      this.regParams.eta = schResultVo.finalEta
      this.regParams.cgoRest = schResultVo.cgoRest
      this.regParams.hidCS008I = schResultVo.hidCS008I
      this.regParams.hidLegInfo = schResultVo.hidCS008I
      this.regParams.scheduleEditYn = 'Y'
      this.regParams.legTermHPNT = 'N'
      this.fnTermcheckHPNT(schResultVo.hidCS008I) // 터미널 HPNT 인지 체크 하여 HPNT이면 팝업창 뛰움.

      this.regParams.podPortCd = schResultVo.podPortCd
      this.regParams.podTrmlCd = schResultVo.podTrmlCd
      this.regParams.schFrtAppNo = schResultVo.frtAppNo
      this.regParams.schLogDtm = schResultVo.localDtm

      if (this.act === 'M') {
        if (this.$ekmtcCommon.isNotEmpty(schResultVo.eta)) {
          this.regParams.podEta = schResultVo.eta.length > 8 ? schResultVo.eta.substring(0, 8) : schResultVo.eta
        }
        this.regParams.bkgDt = schResultVo.bkgDt
      } else {
        if (this.$ekmtcCommon.isNotEmpty(schResultVo.finalEta)) {
          this.regParams.podEta = schResultVo.finalEta.length > 8 ? schResultVo.finalEta.substring(0, 8) : schResultVo.finalEta
        }
        this.regParams.bkgDt = schResultVo.localDtm.length > 8 ? schResultVo.localDtm.substring(0, 8) : schResultVo.localDtm
      }

      const podPortCd = this.fnPodPortSet(schResultVo.hidCS008I)

      if (podPortCd) {
        this.fnPodCtrCd(podPortCd)
      } else {
        this.fnPodCtrCd(schResultVo.podPortCd)
      }

      this.regParams.polCtrCd = schResultVo.polCtrCd
      this.regParams.polPortNm = schResultVo.polName
      this.regParams.polPortCd = schResultVo.polPortCd
      this.regParams.polTrmlCd = schResultVo.polTrmlCd

      if (this.$ekmtcCommon.isNotEmpty(schResultVo.polPortCd)) {
        srInquiry.checkCnSrBkgShipCodeArea(this.regParams.polPortCd).then(res => {
          const data = res.data
          TH.bkgShprCodAreaYn = data
          // console.log('checkCnSrBkgShipCodeArea data @@@@@ @ ', data)
        })
      }

      //컨테이너에 위험물이 존재 하는지 체크
      const strPOL = this.regParams.podPortCd
      const strPOD = this.regParams.polPortCd
      let dgCnt = 0
      if (this.regParams.containerList) {
        this.regParams.containerList.forEach(c => {
          if (c.cgoTypCd === '01') {
            dgCnt++
          }
        })
      }

      if ((strPOL === 'SUB' || strPOD === 'SUB') && dgCnt > 0) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.024'))
      }
      trans.getKmdVslCheck(schResultVo.hidCS008I)
        .then(res => {
          const rtnStr = res.data
          this.regParams.kmdVslCheck = rtnStr === 'N' ? 'N' : 'Y'
        })

      // POD HS Code 길이
      this.tempParams.maxlengthPodHsCd = '6'
      this.tempParams.hscdCheckType = ''

      //
      if (this.regParams.polCtrCd === 'ID') {
        this.tempParams.showTaxId = 'ID'
        this.tempParams.taxNm = 'Exporter\'s Tax No'
        this.regParams.idTaxNo = ''
        this.regParams.wstCgoYn = ''
        this.regParams.imLicNo = ''
        this.regParams.imBnkGurNo = ''
      } else if (this.regParams.dlyCtrCd === 'ID') {
        this.tempParams.showTaxId = 'ID'
        this.tempParams.taxNm = 'Importer\'s Tax No'
        this.regParams.idTaxNo = ''
        this.regParams.wstCgoYn = ''
        this.regParams.imLicNo = ''
        this.regParams.imBnkGurNo = ''

        this.tempParams.maxlengthPodHsCd = '8' // POD HS Code 길이
        this.tempParams.hscdCheckType = 'ID' // POD HS Code 체크 구분
      } else if (this.regParams.dlyCtrCd === 'VN') {
        this.tempParams.showTaxId = 'VN'
        this.regParams.idTaxNo = ''
      } else {
        this.tempParams.showTaxId = ''
        this.regParams.idTaxNo = ''
        this.regParams.wstCgoYn = ''
        this.regParams.imLicNo = ''
        this.regParams.imBnkGurNo = ''
      }

      if (this.regParams.dlyPlcCd === 'NSA') {
        console.log('@@@@ NSA')
        this.tempParams.showIecId = true
        this.tempParams.showIecIdSub = true // Gst, Email

        this.tempParams.maxlengthPodHsCd = '8' // POD HS Code 길이
        this.tempParams.hscdCheckType = 'IN' // POD HS Code 체크 구분
      } else if (this.regParams.dlyCtrCd === 'IN') {
        console.log('@@@@ IN')
        this.tempParams.showIecId = true
        this.tempParams.showIecIdSub = false // Gst, Email
        this.regParams.gstNo = ''
        this.regParams.iecPicEmlAddr = ''

        this.tempParams.maxlengthPodHsCd = '8' // POD HS Code 길이
        this.tempParams.hscdCheckType = 'IN' // POD HS Code 체크 구분
      } else {
        console.log('@@@@ else')
        this.tempParams.showIecId = false
        this.tempParams.showIecIdSub = false

        this.regParams.iecNo = ''
        this.regParams.panNo = ''
        this.regParams.gstNo = ''
        this.regParams.iecPicEmlAddr = ''
        this.regParams.ntifPanNo = ''
        this.regParams.invValCurCd = ''
        this.regParams.invValExp = ''
      }

      // SHA 발 Company ID 필수 체크를 여부 판단
      if (this.regParams.porPlcCd === 'SHA') {
        this.tempParams.consRed = true
        this.tempParams.consMsg = true
      } else {
        this.tempParams.consRed = false
        this.tempParams.consMsg = false
        this.regParams.polShaTsYn = 'N'

        transCommon.getPolShaTsYn(schResultVo.hidCS008I)
          .then(res => {
            if (res.data === 'Y') {
              this.tempParams.consRed = true
              this.tempParams.consMsg = true
              this.regParams.polShaTsYn = 'Y'
            }
          })
      }
      this.findIotCntrYn('S')
      this.getActShprCst()
      this.fnChangeActCatId(schResultVo.polCtrCd)
      this.fnChangeCneCatId(schResultVo.podCtrCd)
      this.fnChangeNotiCatId(schResultVo.podCtrCd)
      this.fnCasPortChk(schResultVo.hidCS008I)
      this.fnFrtPncChange()
      //this.findIotCntrYn('S')
      //this.collectHcneeSet()
      this.fnCheckLoiDownEnable(schResultVo)
    },
    changeEmptyFlag (e) {
      if (e.target.checked && this.regParams.polShtmCd !== '02') {
        this.changeShprVanYn('Y', true)
      } else if (!e.target.checked && this.regParams.polShtmCd !== '02') {
        this.changeShprVanYn('N', false)
      }

      Array.isArray(this.regParams.containerList) && this.regParams.containerList.forEach((item, index) => {
        console.log('@@@@ item: ', item)
        if (e.target.checked) {
          item.feCatCd = 'E'
        } else {
          item.feCatCd = 'F'
        }
      })
    },
    changePolShtmCd (e) {
      if (e.target.value === '02') {
        this.changeShprVanYn('N', false)
      }
    },
    changeShprVanYn (val, isChk) {
      const frm = document.querySelector('#srForm')
      const containerList = this.regParams.containerList
      containerList.forEach((c, idx) => {
        frm.querySelector('#shprVanYn_' + idx).checked = isChk
        c.shprVanYn = val
      })
    },
    changeCnee (e) {
      if (e.target.checked) {
        this.regParams.consYn = 'Y'
        this.regParams.ntifCstEnm = this.regParams.cneCstEnm
        this.regParams.ntifCstAddr = this.regParams.cneCstAddr
      } else {
        this.regParams.consYn = 'N'
        this.regParams.ntifCstEnm = ''
        this.regParams.ntifCstAddr = ''
      }
    },
    // 베트남 Inbound Manifest 관련 요청
    fnWstCgoYn (e) {
      if (e.target.checked) {
        this.regParams.wstCgoYn = 'Y'
      } else {
        this.regParams.wstCgoYn = 'N'
        this.regParams.imLicNo = ''
        this.regParams.imBnkGurNo = ''
      }
    },
    fnChangeActCatId (ctrCd, type) {
      if (ctrCd === '') {
        ctrCd = this.mainData.polCtrCd || ''
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (type !== 'INIT') {
            this.regParams.actMin = ''
            this.regParams.actMax = ''
            this.regParams.actShprIdCatCdTemp = ''
            this.regParams.actShprIdCatCd = ''
          }
          this.srInitData.actCstId = res.data
        })
    },
    fnChangeCneCatId (ctrCd, type) {
      if (ctrCd === '') {
        ctrCd = this.mainData.podCtrCd || ''
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (type !== 'INIT') {
            this.regParams.cneMin = ''
            this.regParams.cneMax = ''
            this.regParams.cneIdCatCdTemp = ''
            this.regParams.cneIdCatCd = ''
          }
          this.srInitData.cneCstId = res.data
        })
    },
    fnChangeNotiCatId (ctrCd, type) {
      if (ctrCd === '') {
        ctrCd = this.mainData.podCtrCd || ''
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (type !== 'INIT') {
            this.regParams.ntifMin = ''
            this.regParams.ntifMax = ''
            this.regParams.ntifIdCatCdTemp = ''
            this.regParams.ntifIdCatCd = ''
          }
          this.srInitData.notiCstId = res.data
        })
    },
    fnCasPortChk (hidCS008I) {
      console.log('@@@@ enter fnCasPortChk: ', hidCS008I)
      if (!hidCS008I) {
        // CAS_PORT_CD == 'Y' 체크로직이 있는데
        // 소스상에 CAS_PORT_CD 에 Y 값이 들어갈수 없어 제외함
        this.casNoPortYn = 'N'
        return
      }
      transCommon.getCasNoPortCheck(hidCS008I)
        .then(res => {
          const rtnStr = res.data
          this.casNoPortYn = rtnStr === 'Y' ? 'Y' : 'N'
        })
    },
    fnAddInfoStateSet (ctrCd, flag, evtType) {
      if (flag === 'ACT') {
        if (evtType === 'CHANGE') {
          this.regParams.actShprStateCd = ''
          this.regParams.actShprStateNm = ''
        }
        if (ctrCd === 'IN') {
          this.tempParams.showActShprStateNm = false
        } else {
          this.tempParams.showActShprStateNm = true
        }
      } else if (flag === 'CNE') {
        if (evtType === 'CHANGE') {
          this.regParams.cneCstStateCd = ''
          this.regParams.cneCstStateNm = ''
        }
        if (ctrCd === 'IN') {
          this.tempParams.showCneCstStateNm = false
        } else {
          this.tempParams.showCneCstStateNm = true
        }
      } else if (flag === 'NTIF') {
        if (evtType === 'CHANGE') {
          this.regParams.ntifCstStateCd = ''
          this.regParams.ntifCstStateNm = ''
        }
        if (ctrCd === 'IN') {
          this.tempParams.showNtifCstStateNm = false
        } else {
          this.tempParams.showNtifCstStateNm = true
        }
      }
    },
    fnStateNmSet (val, flag) {
      let cdNm = ''
      if (val !== '') {
        const codeList = this.commonCodes.CS038 || []
        const vo = codeList.find(vo => vo.cd === val)
        cdNm = vo !== undefined ? vo.cdNm : ''
      }

      if (flag === 'ACT') {
        this.regParams.actShprStateNm = cdNm
      } else if (flag === 'CNE') {
        this.regParams.cneCstStateNm = cdNm
      } else if (flag === 'NTIF') {
        this.regParams.ntifCstStateNm = cdNm
      }
    },
    fnChangeCatId (val, flag) {
      if (flag === 'ACT') {
        if (val !== '') {
          const vo = this.srInitData.actCstId.find(vo => vo.cstIdCatCd === val) || {}
          this.regParams.actShprIdCatCdTemp = val
          this.regParams.actMin = vo.minInpLen || ''
          this.regParams.actMax = vo.maxInpLen || ''
        } else {
          this.regParams.actShprIdCatCdTemp = ''
          this.regParams.actMin = ''
          this.regParams.actMax = ''
        }
      } else if (flag === 'CNE') {
        if (val !== '') {
          const vo = this.srInitData.cneCstId.find(vo => vo.cstIdCatCd === val) || {}
          this.regParams.cneIdCatCdTemp = val
          this.regParams.cneMin = vo.minInpLen || ''
          this.regParams.cneMax = vo.maxInpLen || ''
        } else {
          this.regParams.cneIdCatCdTemp = ''
          this.regParams.cneMin = ''
          this.regParams.cneMax = ''
        }
      } else if (flag === 'NTIF') {
        if (val !== '') {
          const vo = this.srInitData.notiCstId.find(vo => vo.cstIdCatCd === val) || {}
          this.regParams.ntifIdCatCdTemp = val
          this.regParams.ntifMin = vo.minInpLen || ''
          this.regParams.ntifMax = vo.maxInpLen || ''
        } else {
          this.regParams.ntifIdCatCdTemp = ''
          this.regParams.ntifMin = ''
          this.regParams.ntifMax = ''
        }
      }
    },
    isEmailReadonly (type) {
      if (type === 'select') {
        return this.regParams.emailcheck === 'N'
      } else {
        return this.regParams.emailcheck === 'N' || this.tempParams.bizPicEmlAddr !== ''
      }
    },
    fnEmailInput () {
      this.tempParams.bizPicEmlAddr = ''
      this.regParams.bizPicEmlAddr = ''

      const frm = document.querySelector('#srForm')
      const selector = frm.querySelector('#bizPicEmlAddr')
      this.$ekmtcCommon.hideErrorTooltip(selector)
    },
    fnChangeEmail () {
      this.regParams.bizPicEmlAddr = this.tempParams.bizPicEmlAddr
      const frm = document.querySelector('#srForm')
      const selector = frm.querySelector('#bizPicEmlAddr')
      this.$ekmtcCommon.hideErrorTooltip(selector)
    },
    chkHscd (text) {
      const checkType = this.tempParams.hscdCheckType
      this.tempParams.hscdErrorMessage = ''

      if (checkType === '') {
        return
      }

      let val = ''
      if (checkType === 'ID' || checkType === 'IN') {
        val = text.replace(/,/g, '')

        if (val === '') {
          return
        }
        const regex = /[^0-9]/g
        if (regex.test(val) || isNaN(parseInt(val))) {
          this.tempParams.hscdErrorMessage = this.$t('msg.CSBL200.175') // HS CODE를 확인하세요.
          return
        }
        if (val.length !== 4 && val.length !== 6 && val.length !== 8) {
          this.tempParams.hscdErrorMessage = this.$t('msg.CSBL200.175') // HS CODE를 확인하세요.
          return
        }

        if (val !== '') {
          transCommon.getHscdValidation({
            hsCd: val,
            hsFlag: checkType
          }).then(res => {
            const result = res.data
            if (result.returnFlag === 'N') {
              this.tempParams.hscdErrorMessage = this.$t('msg.CSBL200.175')
            } else {
              this.tempParams.hscdErrorMessage = ''
            }
          })
        }
      }
    },
    changeCntrSz (cvo) {
      console.log('@@@@ enter changeCntrSz')
      if (this.memberDetail.bkgPlcCd === 'TAO' || this.memberDetail.bkgPlcCd === 'DLC' || this.memberDetail.bkgPlcCd === 'XMN') {
        cvo.pcupCyNm = ''
        cvo.pcupCyCd = ''
      }

      const subCntrTypList = this.getCntrTypList(cvo.cntrSzCd)
      const cntrTypVo = subCntrTypList.find(vo => vo.cd === cvo.cntrTypCd)
      if (cntrTypVo === undefined || cntrTypVo.disabled) {
        if (cvo.cntrSzCd === '45') {
          cvo.cntrTypCd = 'HC'
        } else {
          cvo.cntrTypCd = 'GP'
        }
      }

      this.setCntrTypList(cvo)
      this.setCgoTypList(cvo)
      // this.fnChangeContSz()

      this.forceUpdate++
    },
   changeCntrTyp (cvo, idx) {
      // Special Cargo Type 체크 초기화
      const frm = document.querySelector('#srForm')
      const id = 'cgoTypCd_' + idx
      let selector = frm.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      console.log('@@@@ enter changeCntrTyp: ', cvo)
      if (PCUP_INPT_PSBL_PLC_CDS.indexOf(this.memberDetail.bkgPlcCd) >= 0) { // 픽업 정보 수정 가능한 지역만 체크하게
        cvo.pcupCyNm = ''
        cvo.pcupCyCd = ''
      }

      if (this.regParams.polShtmCd === '02') {
        if (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') {
          alert('冷冻箱订舱不接受CFS条款')
          cvo.cntrTypCd = ''
          return
        }
      }

      const containnerList = this.regParams.containerList
      this.isFlagRF = false
      containnerList.some(obj => {
        if (obj.cntrTypCd === 'RF' || obj.cntrTypCd === 'RH') {
          this.isFlagRF = true
          return true
        }
      })

      cvo.shprVanYn = 'N'
      cvo.cgoTypCd = ''
      cvo.cntrRfStsCd = ''
      this.setCgoTypList(cvo)

      if (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') {
        console.log('@@@@ here cntrRfStsCd')
        cvo.cntrRfStsCd = '01'
        this.findIotCntrYn('C', idx, cvo)
      }
    },
    async changeCgoTyp (cvo, idx) {
      console.log('@@@@ enter changeCgoTyp')
      console.log('@@@@ this.act:', this.act)
      console.log('@@@@ cvo: ', cvo)
      console.log('@@@@ idx: ', idx)
      console.log('@@@@ cvo.cgoTypCd: ', cvo.cgoTypCd)
      let isOk = true
      let messageShow = ''

      if (cvo.containerDGList.length > 0) {
         this.tmpContainerDGList = cvo.containerDGList
      }

      console.log('@@@@ tmpContainerDGList ', this.tmpContainerDGList)

      // DG cargo 일때, 팝업
      if (cvo.cgoTypCd === '01' && this.casNoPortYn === 'Y') {
        this.$ekmtcCommon.alertDefault(`Please include compulsory data for CAS No.
                                        For cargoes destined for or passing through Shanghai, both MSDS and Chinese MSDS
                                        with compositions reflecting 100% are required.`)
      }

      if (cvo.cgoTypCd === '05') {
        // 값 초기화
        cvo.rfTmprUnitCd = ''
        cvo.rfSetupTmpr = ''
        cvo.cntrRfStsCd = ''
      } else {
        // 값 초기화
        cvo.rfTmprUnitCd = 'C'
      }

      if (cvo.cgoTypCd === '') {
        cvo.containerDGList = []
      } else {
        // if (this.tmpContainerDGList.length > 0) {
        //   cvo.containerDGList = this.tmpContainerDGList
        // }
      }

      if (cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') {
        // const isOk = await this.checkBookingRF(this._containerList)
        this.regParams.containerList.forEach(c => {
          console.log('@@@@ c.cntrTypCd: ', c.cntrTypCd)
          if ((c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') && c.cgoTypCd === '01') {
            messageShow = 'Y'
          }
        })

        await bookings.validationBookingRF(this.regParams.hidCS008I).then(res => {
          const result = res.data

          if (result.rtnCode === 'Y' && messageShow === 'Y') {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.131'))
            isOk = false
          }
        })
        this.findIotCntrYn('C', idx, cvo)
      }

      console.log('@@@@ isOk:', isOk)

      this.$nextTick(() => {
        this.regParams.containerList[idx].cgoTypCd = isOk ? cvo.cgoTypCd : ''
        this.forceUpdate++
      })
      // this.regParams.containerList[idx].cgoTypCd = cvo.cgoTypCd
      // if (this.act === 'M') {
      //   //갱신 해주기 위함...이게 맞는건가..
      //   this.regParams.containerList = this.regParams.containerList.filter(obj => { return obj })
      // }
    },
    checkShprVanYn (e, vo, idx) {
      const frm = document.querySelector('#srForm')
      if (e.target.checked) {
        vo.shprVanYn = 'Y'
        if (!this.isPcupReqDtReadOnly()) {
          vo.pcupReqDt = ''
          vo.pcupReqTm = '0900'
          vo.pcupCyNm = ''
          vo.pcupCyCd = ''
        }
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#pcupReqDt_' + idx))
      } else {
        if (this.regParams.emptyFlag === 'E') {
          this.$ekmtcCommon.alertDefault(this.$t('js.booking.030'))
          vo.shprVanYn = 'Y'
          // e.target.checked = true
          e.preventDefault()
        } else {
          vo.shprVanYn = 'N'
        }
      }
    },
    setCntrTypList (cvo) {
      const subCntrTypList = this.getCntrTypList(cvo.cntrSzCd)
      this.$set(cvo, 'subCntrTypList', subCntrTypList)
    },
    getCntrTypList (cntrSzCd) {
      const polShtmCd = this.regParams.polShtmCd
      const subCntrTypList = this.cntrTypeList.map(vo => {
          if (vo.rmk.indexOf(cntrSzCd) > -1) {
            vo.isShow = false
          } else {
            vo.isShow = true
          }
          if (polShtmCd === '02' && (vo.cd === 'RF' || vo.cd === 'RH')) {
            vo.isShow = true
          }
          return vo
        })
      return subCntrTypList
    },
    setCgoTypList (cvo) {
      const polShtmCd = this.regParams.polShtmCd
      let subCgoTypList = []

      cvo.isCgoTypEmpty = true

      if (cvo.cntrTypCd !== '') {
        subCgoTypList = this.cgoTypCdList.map(vo => {
          if (vo.rmk.indexOf(cvo.cntrTypCd) > -1) {
            vo.isShow = false
          } else {
            vo.isShow = true
          }
          return vo
        })

        if (polShtmCd === '02') {
          subCgoTypList = this.cgoTypCdList.map(vo => {
              if (vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== '01') {
                vo.isShow = false
              } else {
                vo.isShow = true
              }
              return vo
            })
        }

        const arrCheck = [['40', 'GP', '06'], ['40', 'HC', '03'], ['40', 'RH', '04']]

        arrCheck.forEach(obj => {
          if (cvo.cntrSzCd === obj[0] && cvo.cntrTypCd === obj[1]) {
            subCgoTypList = this.cgoTypCdList.map(vo => {
                if (vo.rmk.indexOf(cvo.cntrTypCd) > -1 && vo.cd !== obj[2]) {
                  vo.isShow = false
                } else {
                  vo.isShow = true
                }
                return vo
              })
              return false
          }
        })
        // v-if="vo.cntrTypCd !== 'OT' && vo.cntrTypCd !== 'FR' && vo.cntrTypCd !== 'SR'"
        // if (cvo.cntrTypCd === 'OT') {
        // }
      }

      this.$set(cvo, 'subCgoTypList', subCgoTypList)
    },
    async findSrInfo () {
      let srRno = ''
      await trans.findBkgBlInfo(this.detailBkgNo).then(res => {
        const result = res.data
        if (result !== undefined) {
          srRno = result.srRno
        }
      })
      return srRno
    },
    async checkBooking () {
      let isOk = true
      const regParams = this.regParams
      await bookings.validationBooking(regParams).then(async res => {
        const result = res.data

        if (result !== undefined) {
          const apcl = result.apcl
          const remark = result.remark
          const restList = result.restList

          if (apcl === 'Y' && this.$ekmtcCommon.isEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK186') + this.$t('art.CMATK185'))
            isOk = false
          } else if (apcl === 'Y' && this.$ekmtcCommon.isNotEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(remark)
            isOk = false
          } else if (apcl === 'A' && this.$ekmtcCommon.isEmpty(remark)) {
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK186') + this.$t('art.CMATK185'))
            isOk = false
          } else if (apcl === 'A' && this.$ekmtcCommon.isNotEmpty(remark)) {
            // if (!await this.$ekmtcCommon.asyncAlertMessage({ message: remark + '\n' + this.$t('msg.CSBK100.114'), useConfirmBtn: true })) {
            //   isOk = false
            // }
            let forOk = true

            // 부킹 제약 메시지 중복 추가
            for (var item of restList) {
              if (
                !(await this.$ekmtcCommon.asyncAlertMessage({
                  message: item.rmk + '\n' + this.$t('msg.CSBK100.114'),
                  useConfirmBtn: true,
                  onCloseSelf: (resolve, $Simplert) => {
                    resolve(false)
                    $Simplert.close()

                    forOk = false
                  }
                }))
              ) {
                isOk = false
              }

              if (!forOk) {
                return
              }
            }
          }
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK186') + this.$t('art.CMATK185'))
          isOk = false
        }
      })
      return isOk
    },
    async fnBkgCstMaxControl () {
      let flag = 'ADD'
      if (this.act !== 'R') {
        flag = 'EDIT'
      }
      const regParams = this.regParams
      const cntrList = regParams.containerList
      let sumQty20 = 0
      let sumQty40 = 0
      let sumTeu = 0
      let cgoYn = 'N'

      cntrList.forEach(c => {
        if (flag === 'ADD') {
          if (c.cntrSzCd === '20') {
            sumQty20 += Number(c.cntrQty)
            sumTeu += Number(c.cntrQty)
          } else if (c.cntrSzCd === '40') {
            sumQty40 += Number(c.cntrQty)
            sumTeu += Number(c.cntrQty) * 2
          }

          if (c.cntrSzCd === '45' || (c.cntrTypCd !== 'GP' && c.cntrTypCd !== 'HC')) {
            cgoYn = 'Y'
          }
        } else if (flag === 'EDIT') {
          if (c.cntrSzCd !== '45' || (c.cntrTypCd === 'GP' && c.cntrTypCd === 'HC')) {
            if (c.cntrSzCd === '20') {
              sumQty20 += Number(c.cntrQty)
              sumTeu += Number(c.cntrQty)
            } else if (c.cntrSzCd === '40') {
              sumQty40 += Number(c.cntrQty)
              sumTeu += Number(c.cntrQty) * 2
            }
          }
        }
      })

      if (cgoYn === 'Y') {
        return true
      }

      const params = {
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        porCtrCd: regParams.polCtrCd,
        dlyCtrCd: regParams.podCtrCd,
        porPlcCd: regParams.polPortCd,
        dlyPlcCd: regParams.podPortCd,
        hidCS008I: regParams.hidCS008I,
        scheduleEditYn: regParams.scheduleEditYn,
        bkgNo: regParams.bkgNo,
        uiFlag: flag,
        sumQty20: sumQty20,
        sumQty40: sumQty40,
        sumTeu: sumTeu
      }

      let isResult = false

      await bookings.bkgCstMaxControlChk(params).then(res => {
        const result = res.data
        if (result !== undefined && result.rtnCode === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.194')) // 담당영업사원에게 확인바랍니다.
          isResult = false
        } else {
          isResult = true
        }
      })
      return isResult
    },
    soDownloadPop () {
      console.log('soDownloadPop srNo >>>>> ', this.regParams.srRno)

      this.ShippingOrderPop.items = []
      this.ShippingOrderPop.items.push({ bkgNo: this.detailBkgNo, srNo: this.regParams.srRno || '', gubun: 3 })
      this.selectFunc = ''
      this.popParams = this.ShippingOrderPop
      this.customComponent = 'ShippingOrderPop'
      this.$ekmtcCommon.layerOpen('.booking_new_sr_popup .popup_dim')
    },
    fnReg () {
      this.$router.push({ name: 'booking-new', params: {} }).catch(() => {})
    },
    isCancelSrInquery () {
      const arrPortCd = ['SHA', 'TAO', 'SZP', 'DCB', 'YTN', 'SHK', 'NBO', 'XGG', 'XMN', 'FQG', 'SWA', 'FOC']
      if (this.act === 'M' && arrPortCd.includes(this.regParams.polPortCd)) {
        return true
      }
      return false
    },
    async cancelSrInquery  (type) {
      if (type !== 'CANCEL') {
        await this.destoryCommonBookingComponent()

        this.commonBookingParams = {
          bkgNo: this.regParams.bkgNo,
          srRno: this.pageSrRno,
          action: 'CANCEL',
          callType: 'VIEW'
        }
        const THIS = this
        this.commonBookingCallback = (status) => {
          if (status === 'succ') {
            THIS.$router.push('/working/progress')
          }
        }
        this.commonBookingComponent = 'common-booking'
      }
    },
    destoryCommonBookingComponent () {
        this.commonBookingComponent = null // CommonBooking 영역 초기화
    },
    isVgmSrInquery () {
      if (this.regParams.dlyCtrCd === 'IR' && this.regParams.polPortCd === 'NBO' && this.bkgUpdYn === 'N') {
        return false
      }
      const arrPortCd = ['SHA', 'SZP']
      if (this.act === 'M' && arrPortCd.includes(this.memberDetail.bkgPlcCd) && (this.regParams.cnclYn !== 'Y' || (this.regParams.bkgAcpnMethCd !== '01' && (this.bkgUpdYn === 'N' || this.srStat === 'B')))) {
        return true
      }
      return false
    },
    vgmSrInquery  () {
      if (this.regParams.polPortCd === 'HKG' || this.regParams.polPortCd === 'HKM') {
        alert("Cargoes loading in Hong Kong, that mean POL is Hong Kong please use below link to access Hong Kong's Web VGM system:\nhttp://www.kmtc.com.hk:8080/VGM")
        return
      }

      this.selectFunc = ''
      this.popParams = {
        blNo: this.pageSrRno,
        methCd: this.regParams.bkgAcpnMethCd,
        type: 'BL',
        uiFlag: 'SR'
      }
      this.customComponent = 'VgmPop'
      this.$ekmtcCommon.layerOpen('.booking_new_sr_popup .popup_dim')
    },
    async saveSrInquery () {
      console.log('@@@@ saveSrInquery')
      console.log('@@@@ _containerList: ', this._containerList)
      const THIS = this
      const regParams = this.regParams
      const isIran = (regParams.dlyCtrCd === 'IR' && regParams.polPortCd === 'NBO' && this.bkgUpdYn === 'N')
      let isOk = true
      //이란일때는 필요없는 체크 로직
      if (!isIran) {
        if (this.bkgDocOver === 'Y' && regParams.podCtrCd === 'IR') {
          this.$ekmtcCommon.alertDefault(this.$t('js.booking.036'))
          return
        }
        //선박 미입력 시
        if (this.$ekmtcCommon.isEmpty(regParams.vslCd) || this.$ekmtcCommon.isEmpty(regParams.voyNo)) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.102') })
          this.searchSchedule()
          return
        }
        const arrRefs = ['shprCstEnm', 'shprCstAddr', 'cneCstEnm', 'cneCstAddr', 'ntifCstEnm', 'ntifCstAddr']

        arrRefs.forEach(key => {
          if (!THIS.$refs[key].isValid()) {
            isOk = false
          }
        })
        let arrOne = ['issCtrCd', 'issPlcCd', 'pkgQty', 'grsWt', 'grsCbm', 'bizPicEmlAddr', 'soNo']
        //상태값이 B일 경우에는 TALLY DATA 추가
        if (this.srStat === 'B') {
          arrOne = [...arrOne, 'tallWeight']
        }
        arrOne.forEach(key => {
          if (!THIS.checkValidationOne(key)) {
            isOk = false
          }
        })

        if (!await THIS.checkSanction('shprCstEnm') || !await THIS.checkSanction('cneCstEnm') || !await THIS.checkSanction('ntifCstEnm')) {
          isOk = false
        }
      }

      if (regParams.frtPncCd === 'C') {
        let cheeChk = await this.collectHcneeSet()
        if (!cheeChk) {
          this.regParams.hcneCstNo = ''
          this.regParams.frtAppNo = ''
          this.regParams.soNo = ''
          document.querySelector('#soNo').value = ''
          document.querySelector('#soNo').focus()
          return
        }
      } else if (regParams.frtPncCd !== 'C') {
        regParams.soNo = ''
      }

      const arrAuto = ['oprRmk']
      arrAuto.forEach(key => {
        if (!this.checkValidationAutocomplete(key)) {
          isOk = false
        }
      })

      if (!this.checkValidationDescInfo() || !this.checkValidationMarkInfo('ALL')) {
        isOk = false
      }

      if ((regParams.podCtrCd === 'IR' || regParams.podCtrCd === 'RU') && !this.checkIranValidationAll()) {
        isOk = false
      }

      const containerList = regParams.containerList
      const dg = THIS.$refs.dg
      const oog = THIS.$refs.oog
      const flexi = THIS.$refs.flexi
      let cntrSumValue = 0
      let cntrQty = 0
      let dgCnt = 0
      let oogCnt = 0
      let rfCnt = 0
      let flexiCnt = 0

      let res = await this.checkValidationCntrAll()
      console.log('@@@@ res: ', res)
      if (res === false) {
        console.log('@@@@ checkValidationCntrAll error')
        isOk = false
      }
      console.log('@@@@ isOk checkValidationCntrAll: ', isOk)

      //파키스탄 향 일때 pod hs code 체크
      if (regParams.podCtrCd === 'PK') {
        if (this.$ekmtcCommon.isEmpty(regParams.podHsCd)) {
          this.$ekmtcCommon.showErrorTooltip('#podHsCd', 'Please Input POD HS Code ')
          isOk = false
        }
      }

      // 컨테이너 종류별로 grouping
      const containerMap = containerList.reduce((carry, c) => {
        cntrSumValue += Number(c.cntrSzCd) * Number(c.cntrQty)
        cntrQty += Number(c.cntrQty)
        if (c.cgoTypCd === '01') {
          dgCnt++
        } else if (c.cgoTypCd === '02') {
          oogCnt++
        } else if (c.cgoTypCd === '06') {
          flexiCnt++
        }

        if (c.cntrTypCd === 'RF' || c.cntrTypCd === 'RH') {
          rfCnt++
        }

        carry[c.cntrSeq] = c
        return carry
      }, {})
      //~ 컨테이너 종류별로 grouping

      if (dg !== undefined) {
        await dg.forEach(async d => {
          const c = containerMap[d.cntrSeq]

          if (c === undefined) {
            return
          }

          //d.setCasNoList()
          c.containerDGList = d.dgInfo.containerDGList
          c.reqConts = d.dgInfo.reqConts
          c.reqRno = d.dgInfo.reqRno
          c.uploadFileList = d.dgInfo.uploadFileList

          if (!await d.checkValidationDGAll()) {
            isOk = false
          }
        })
      }
      if (oog !== undefined) {
        oog.forEach(o => {
          const c = containerMap[o.cntrSeq]

          if (c === undefined) {
            return
          }

          const oogObj = o.oogInfo
          c.orgLength = oogObj.orgLength
          c.orgWidth = oogObj.orgWidth
          c.orgHeight = oogObj.orgHeight
          c.itemQty = oogObj.itemQty
          c.underDckCd = oogObj.underDckCd
          c.bulkYn = oogObj.bulkYn
          c.cmdtCd = oogObj.cmdtCd
          c.cmdtDsc = oogObj.cmdtDsc
          c.oogCmdtNm = oogObj.oogCmdtNm
          c.frgtWt = oogObj.frgtWt
          c.tareWtYn = oogObj.tareWtYn
          c.itemCont = oogObj.itemCont
          c.reqRno = oogObj.reqRno
          c.uploadFileList = oogObj.uploadFileList

          if (!o.checkValidationOOGAll()) {
            isOk = false
          }
        })
      }

      let fbYn = 'N'

      if (flexi !== undefined) {
        flexi.forEach(f => {
          const c = containerMap[f.cntrSeq]

          if (c === undefined) {
            return
          }

          const flexiObj = f.flexiInfo
          c.cmdtDsc = flexiObj.cmdtDsc
          c.mfcoNm = flexiObj.mfcoNm
          c.dgYn = flexiObj.dgYn
          c.mpltYn = flexiObj.mpltYn
          c.newFbYn = flexiObj.newFbYn
          c.flsTmpr = flexiObj.flsTmpr
          c.fbCapa = flexiObj.fbCapa
          c.perMax = flexiObj.perMax
          c.fbGrsWt = flexiObj.fbGrsWt
          c.flexiCont = flexiObj.flexiCont
          c.reqRno = flexiObj.reqRno
          c.uploadFileList = flexiObj.uploadFileList

          fbYn = 'Y'

          if (!f.checkValidationFlexiAll()) {
            isOk = false
          }
        })
      }

      // if (this.devYn) {
        let rtnVal = this.checkValidationActShprCst()

        if (!rtnVal.isOk) {
          if (rtnVal.flag === 'alert') {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: ' B/L shippers do not match. Will you proceed?', useConfirmBtn: true })) {
              return
            }
          } else {
            isOk = false
          }
        }
      // }

      if (!isOk) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.238') })
        const frm = document.querySelector('#srForm')
        const arrEl = frm.querySelectorAll('.input_error')
        if (arrEl.length > 0) {
          arrEl[0].focus()
        }
        return
      }

      // 기존소스 코멘트 => 베트남 Inbound Manifest 관련(Tax ID, Consigne 담당자 전화번호, 이메일 필수)
      // Consigne 담당자 전화번호, 이메일 필수  <= 이거 체크하는 로직은 없었음
      if (regParams.dlyCtrCd === 'VN') {
        let vnMsgYn = 'N'
        if (regParams.wstCgoYn !== 'Y') {
          if (regParams.oprRmk.includes('WAST') || regParams.oprRmk.includes('SCRAP')) {
            vnMsgYn = 'Y'
          } else if (regParams.descInfoList.length > 0) {
            regParams.descInfoList.forEach(d => {
              if (d.dscr.includes('WAST') || d.dscr.includes('SCRAP')) {
                vnMsgYn = 'Y'
              }
            })
          }
        }

        if (vnMsgYn === 'Y') {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.178'), useConfirmBtn: true })) {
            return
          }
        }
      }

      if (regParams.legTermHPNT === 'Y') {
        //this.fnMessageHPNT()
      }

      const bkgPlcCd = this.memberDetail.bkgPlcCd

      if (bkgPlcCd === 'NBO') {
        let fwdrCstNo = ''

        // await transCommon.getNboAgentCd({ hidCS008I: regParams.hidLegInfo, bkgNo: '' }).then(async res => {
        //   fwdrCstNo = res.data
        // })

        // if (fwdrCstNo === 'N') {
        //   await this.$ekmtcCommon.asyncAlertMessage({ message: 'The shipping agent information of route you selected is not settled.  Please contact KMTC NBO office.' })
        //   return
        // }

        // regParams.fwdrCstNo = fwdrCstNo
        regParams.fwdrCstNo = this.memberDetail.cstCd
      } else {
        // if (this.srInitData.bkgAgntYn === 'N') {
          regParams.fwdrCstNo = regParams.bookingAgent
        // }
      }

      if (dg !== undefined) {
        for (const d of dg) {
          const isDgMaxOk = await d.checkValidationDGMax()
          if (!isDgMaxOk) {
            return
          }
        }

        if (this.serviceLang === 'CHN') {
          const dgVolChk = await this.checkValidationDGVol()

          if (dgVolChk === 'Y') {
            await this.$ekmtcCommon.asyncAlertMessage({ message: 'This schedule have DG volume.\nExceeded the Volume limit now\nSo please change target vessel schedule.' })
            return
          }
        }
      }

      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT022'), useConfirmBtn: true })) {
        return
      }
      // 부킹 제약 체크.
      if (fbYn === 'Y') {
        const fbAttach = this.checkValidationFbAttach()
        if (fbAttach === 'N') {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: 'If the required documents are not attached, the application will state \'check by shipper\'\nuntil the attachment register.', useConfirmBtn: true })) {
            return
          }
        }
      }

      //금지문구 기반 선적제한 기능개선. Commodity입력된 경우만 체크.
      if (this.$ekmtcCommon.isNotEmpty(regParams.oprRmk)) {
        let isOkRtnPhbt = await this.rtnPhbtChrCheck()
        if (!isOkRtnPhbt) {
          return
        }
      }
      //부킹제약조건 체크
       const isOkChk = await this.checkBooking()
       if (!isOkChk) {
         return
       }

      //Max Booking Control by Customer 적용 확대
      let isOkMax = await this.fnBkgCstMaxControl()
      if (!isOkMax) {
        return
      }
      if (process.env.VUE_APP_MODE === 'LOCAL' && !confirm('OK?')) {
        return
      }

      //현재 공통으로 쓰고 있음에도 불구하고 어디는 fileId가 String 이고 어떤 곳은 int임.
      //일단 공통으로 통일되기 전에는 String에서 임으로 사용했다가 임의로 삭제
      this.regParams.chemicalFileList.map(obj => {
        if (isNaN(obj.fileId)) {
          obj.fileId = 0
        }
      })
      this.regParams.containerList.forEach(obj => {
        if (obj.uploadFileList && obj.uploadFileList.length > 0) {
          obj.uploadFileList.map(obj => {
            if (isNaN(obj.fileId)) {
              obj.fileId = 0
            }

            if (isNaN(obj.atchFileSeq)) {
              obj.newFlag = 'Y'
            }
          })
        }
      })

      regParams.containerList.forEach((item, index) => {
        if (item.rfSetupTmpr === '') {
          item.rfTmprUnitCd = ''
        }
        console.log('@@@@ item.containerDGList: ', item.containerDGList)
        item.containerDGList.forEach((_item) => {
          console.log('@@@@ flsTmpr: ', _item.flsTmpr)
          if (_item.flsTmpr === '' || this.$ekmtcCommon.isEmpty(_item.flsTmpr)) {
            _item.tmprUnitCd = ''
          }
        })
      })

      // 이런,러시아 부킹 저장일때, END_USER 넘기기
      if (regParams.podCtrCd === 'IR' || regParams.podCtrCd === 'RU') {
        this.regParams.iranBookingInfo.endUserList = this.endUserList
      }

      if (this.act === 'R') {
        //Part S/R+ seqNo가 넘어온 경우 srNo 대체 값으로 전달한다.
        if (this.seqNo !== '') {
          regParams.srNo = this.seqNo
        }
        srInquiry.postSrInquery(regParams)
        .then(res => {
           console.log('@@@@ res:', res)
          this.$ekmtcCommon.alertCallback(this.$t('tem.CMAT013'), () => {
            THIS.$router
              .push({
                name: 'booking-edit',
                params: { bkgNo: res.data.bkgNo, srRno: res.data.srNo, blNo: '', srCnclYn: 'N' }
              })
              .catch(() => {})
              .finally(async () => {
              if (this.$ekmtcCommon.isNotEmpty(res.data.bkgNo)) {
                this.fnVocPop(res.data.bkgNo)
              }
        })
          })
        })
        .catch(err => {
          alert('Error')
          console.log(err)
        })
      } else {
        /* regParams.chemicalFileList.map(obj => {
          obj.fileId = ''
        }) */
        srInquiry.putSrInquery(regParams)
        .then(res => {
          this.$ekmtcCommon.alertCallback(this.$t('tem.CMAT013'), () => {
            this.editRefresh = !this.editRefresh
          })
        })
        .catch(err => {
          alert('Error')
          console.log(err)
        })
      }
    },
    checkValidationOne (id) {
      const regParams = this.regParams
      const frm = document.querySelector('#srForm')
      const selector = frm.querySelector('#' + id)
      let isOk = true
      let val, msg

      this.$ekmtcCommon.hideErrorTooltip(selector)

      switch (id) {
        case 'issCtrCd':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input B/L Issue Country Code.')
            isOk = false
          }
          break
        case 'issPlcCd':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input B/L Issue Place Code.')
            isOk = false
          }
          break
        case 'pkgQty':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input Package Quantity.')
            isOk = false
          }
          break
        case 'grsWt':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input Gross Weight.')
            isOk = false
          } else {
            val = selector.value.replaceAll(',', '')
            msg = ''
            if (val.includes('.')) {
              const arr = val.split('.')
              if (arr[0].length > 10) {
                isOk = false
                msg = 'Please input as digit 10'
              }
            } else {
              if (val.length > 10) {
                isOk = false
                msg = 'Please input as digit 10'
              }
            }
            if (!isOk) {
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
            }
          }
          break
        case 'grsCbm':
          console.log('@@@@ enter grsCbm')

          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input Measurement.')
            isOk = false
          } else {
            val = selector.value.replaceAll(',', '')
            const arr = val.split('.')
            console.log('@@@@ grsCbm arr: ', arr)
            console.log('@@@@ val: ', val)
            msg = ''
            if (val.includes('.')) {
              if (arr[0].length > 4) {
                isOk = false
                msg = 'Please input as digit 4'
              }
            } else {
              if (val.length > 4) {
                isOk = false
                msg = 'Please input as digit 4'
              }
            }
            if (!isOk) {
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
            }
          }
          break
        case 'bizPicEmlAddr':
          if (regParams.emailcheck === 'Y') {
            if (this.$ekmtcCommon.isEmpty(selector.value)) {
              this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input E-Mail.')
              isOk = false
            } else {
              if (selector.value.indexOf(',') > -1) {
                if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
                  isOk = false
                }
              } else {
                if (!this.$ekmtcCommon.checkEmail(selector.value)) {
                  isOk = false
                }
              }
              if (!isOk) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.VOSD100.043')) // 이메일 체크
              }
            }
          }
          break
        case 'cnePicEmlAddr':
          val = this.$ekmtcCommon.removeHangle(selector.value)
          if (val !== '' && !this.$ekmtcCommon.checkEmail(val)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.VOSD100.043')) // 이메일 체크
            isOk = false
          }
          this.regParams.cnePicEmlAddr = val
          break
        case 'chemicalYn':
          // console.log('chemical', regParams.chemicalYn, regParams.chemicalFileList.length)
          if (regParams.chemicalYn === 'Y' && regParams.chemicalFileList.length === 0) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please Upload the Chemical.')
            isOk = false
          }
          break
        case 'soNo':
          if (regParams.frtPncCd === 'C') {
            if (this.$ekmtcCommon.isEmpty(selector.value)) {
              this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input SC No.')
              isOk = false
            } else {
              const soNo = selector.value
              if (soNo.length !== 9) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.386'))
                isOk = false
              } else {
                const firstCheck = soNo.substring(0, 2)
                const lastCheck = soNo.substring(2, 9)

                const firstResult = firstCheck.replace(/[A-Z]/g, '')
                const lastResult = lastCheck.replace(/[0-9]/g, '')

                if (firstResult !== '' || lastResult !== '') {
                  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.386'))
                  isOk = false
                }
              }
            }
          }
          break
        case 'tallWeight' :
          val = selector.value.replaceAll(',', '')
          msg = ''
          if (val.includes('.')) {
            const arr = val.split('.')
            if (arr[1].length > 3) {
              isOk = false
              msg = 'Please input up to the places of 3 decimals.'
            }
          } else {
            if (val.length > 10) {
              isOk = false
              msg = 'Please input as digit 10'
            }
          }
          if (!isOk) {
            this.$ekmtcCommon.showErrorTooltip(selector, msg)
          }
          break
      }
      return isOk
    },
    checkValidationActShprCst () {
      const regParams = this.regParams
      const frm = document.querySelector('#srForm')
      const selector = frm.querySelector('#actShprCstNo')
      let rtnVal = {
          isOk: true,
          flag: 'alert'
      }

      let val, msg
      this.$ekmtcCommon.hideErrorTooltip(selector)

      // case 'actShprCstNo':
        // console.log('chemical', regParams.chemicalYn, regParams.chemicalFileList.length)
        if (regParams.fixdCgoYn === 'Y' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, 'Please Select actual-shipper.')
          rtnVal.isOk = false
          rtnVal.flag = 'tooltip'
        } else if (regParams.fixdCgoYn === 'Y' && !this.$ekmtcCommon.isEmpty(selector.value) && this.actShprCstList.length > 0) {
          console.log('actShprCstNo actShprCstList @@@@@')
          const actShprCstList = this.actShprCstList

          actShprCstList.forEach(el => {
            if (selector.value === el.actShprCstNo) { // 코드가 같은 값중..
              const shprCstEnmArr = []
              const actShprCstEnmArr = []

              let shprCstEnm = this.regParams.shprCstEnm
              let actShprCstEnm = el.actShprCstEnm
              let str
              let c

              // 특수문자 체크
              for (let i = 0; i < shprCstEnm.length; i++) {
                str = shprCstEnm.charAt(i)
                c = shprCstEnm.charCodeAt(i)
                if ('#?~`$_|{}[]!^\\"-\\+='.indexOf(str) < 0 && ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))) {
                  shprCstEnmArr.push('' + str)
                }
              }

              for (let i = 0; i < actShprCstEnm.length; i++) {
                str = actShprCstEnm.charAt(i)
                c = actShprCstEnm.charCodeAt(i)
                if ('#?~`$_|{}[]!^\\"-\\+='.indexOf(str) < 0 && ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))) {
                  actShprCstEnmArr.push('' + str)
                }
              }

              shprCstEnm = shprCstEnmArr.join('')
              actShprCstEnm = actShprCstEnmArr.join('')

              if (shprCstEnm.toUpperCase() !== actShprCstEnm.toUpperCase()) {
                // this.$ekmtcCommon.showErrorTooltip(selector, 'Actual Shipper Name and Shipper Name(Shipper Information) do not match.')
                // this.$ekmtcCommon.alertDefault('Actual Shipper Name and Shipper Name(Shipper Information) do not match.')
                rtnVal.isOk = false
                rtnVal.flag = 'alert'
              }
            }
          })
        } else {
          rtnVal.isOk = true
          this.$ekmtcCommon.hideErrorTooltip(selector)
        }
      // break

      return rtnVal
    },
    checkValidationDescInfo () {
      console.log('@@@@ enter checkValidationDescInfo')
      const regParams = this.regParams
      const descInfoList = regParams.descInfoList
      const len = descInfoList.length
      const frm = document.querySelector('#srForm')
      let isOk = true
      let vo

      for (let i = 0; i < len; i++) {
        vo = descInfoList[i]
        if (this.$ekmtcCommon.isEmpty(vo.mrk) && this.$ekmtcCommon.isEmpty(vo.dscr)) {
          this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#mrk_' + i), 'Please Input Description Information.')
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#mrk_' + i))
        }
      }
      return isOk
    },
    checkValidationMarkInfo (type) {
      const regParams = this.regParams
      const descInfoList = regParams.descInfoList
      const len = descInfoList.length
      const frm = document.querySelector('#srForm')
      let isOk = true
      let vo, markInfoList, markLen

      for (let i = 0; i < len; i++) {
        markInfoList = descInfoList[i].markInfoList
        markLen = markInfoList !== undefined ? markInfoList.length : 0

        for (let j = 0; j < markLen; j++) {
          vo = markInfoList[j]

          // Pkg's
          if (type === 'ALL' || type === 'pkgQty') {
            if (this.$ekmtcCommon.isEmpty(vo.pkgQty)) {
              this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#pkgQty_' + i + '_' + j), 'Please Input Pakage Quantity.')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#pkgQty_' + i + '_' + j))
            }
          }
          // Weight
          if (type === 'ALL' || type === 'netWt') {
            if (this.$ekmtcCommon.isEmpty(vo.netWt)) {
              this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#netWt_' + i + '_' + j), 'Please Input Net Weight.')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#netWt_' + i + '_' + j))
            }
          }
          // CBM
          if (type === 'ALL' || type === 'netCbm') {
            if (this.$ekmtcCommon.isEmpty(vo.netCbm)) {
              this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#netCbm_' + i + '_' + j), 'Please Input Net CBM.')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#netCbm_' + i + '_' + j))
            }
          }
          // Marks
          if (type === 'ALL' || type === 'mrkDsc') {
            if (this.$ekmtcCommon.isEmpty(vo.mrkDsc)) {
              this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#mrkDsc_' + i + '_' + j), 'Please Input Marks.')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#mrkDsc_' + i + '_' + j))
            }
          }
          // Description of Goods
          if (type === 'ALL' || type === 'gdsDsc') {
            if (this.$ekmtcCommon.isEmpty(vo.gdsDsc)) {
              this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#gdsDsc_' + i + '_' + j), 'Please Input Description of Goods.')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#gdsDsc_' + i + '_' + j))
            }
          }
        }
      }

      return isOk
    },
    checkValidationAutocomplete (id) {
      const regParams = this.regParams
      const frm = document.querySelector('#srForm')
      const selector = frm.querySelector('#' + id + ' #autocomplete-form-input')
      let isOk = true

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (id === 'oprRmk') {
        const orgPlcList = this.srInitData.orgPlcList

        // 2022.10.07 POL,'SHA' 추가
        orgPlcList.push('SHA')

        const isCheck = this.$ekmtcCommon.isNotEmpty(regParams.polPortCd) && orgPlcList.includes(regParams.polPortCd)

        if (isCheck && this.$ekmtcCommon.isEmpty(regParams.oprRmk) && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input Commodity.')
          isOk = false
        // } else if (regParams.oprRmk !== selector.value) {
        //   this.$ekmtcCommon.showErrorTooltip(selector, '값을 정확히 입력해 주세요.')
        //   isOk = false
        }
      }

      return isOk
    },
    async checkValidationCntrAll () {
      console.log('@@@@ enter checkValidationCntrAll')
      let isOk = true
      let prefix = ['cntrQty', 'rfSetupTmpr', 'cgoTypCd']

      // const arrPlcCd = ['TAO', 'LYG', 'XMN']
      const bkgPlcCd = this.memberDetail.bkgPlcCd
      // if (arrPlcCd.includes(bkgPlcCd)) {
      if (PCUP_INPT_PSBL_PLC_CDS.indexOf(bkgPlcCd) >= 0) { // 픽업 정보 수정 가능한 지역만 체크하게
        prefix = [...prefix, 'pcupReqDt', 'pcupCyNm']
      }
      const containerList = this.regParams.containerList
      let idx = 0
      let errCnt = 0

      for (const c of containerList) {
        for (const el of prefix) {
          const result = await this.checkValidationCntr(el, idx, 'SAVE')
          console.log('@@@@ el: ', el)
          console.log('@@@@ result : ', result)
          if (!result) {
            errCnt++
            isOk = false
            // return false
          }
        }

        idx++
      }

      console.log('@@@@ errCnt: ', errCnt)
      return !(errCnt > 0)
    },
    async checkValidationCntr (prefix, idx, flag, payload = null) {
      console.log('@@@@ prefix: ', prefix)
      console.log('@@@@ idx: ', idx)
      console.log(`contianerList[${idx}].${prefix}: `, this._containerList[idx][prefix])
      console.log('@@@@ checkValidationCntr ', payload)

      let isOk = true
      const frm = document.querySelector('#srForm')
      const id = prefix + '_' + idx
      let selector = frm.querySelector('#' + id)
      await this.$nextTick(() => {
        selector = frm.querySelector('#' + id)
      })
      console.log('@@@@ selector: ', selector)
      console.log('@@@@ typeof payload: ', typeof payload)

      const regParams = this.regParams
      let cntrInfo = regParams.containerList[idx]

      if (typeof payload === 'object') {
        if (payload === null) {
          cntrInfo = { ...this._containerList[idx] }
        } else {
          cntrInfo = payload
        }
      } else {
        cntrInfo = { ...this._containerList[idx] }
        cntrInfo[prefix] = payload
      }

      this.$ekmtcCommon.hideErrorTooltip(selector)

      let cntrTypCd, cgoTypCd

      switch (prefix) {
        case 'cntrQty':
          if (this.$ekmtcCommon.isEmpty(selector.value.trim()) || Number(selector.value) < 1) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input Q`ty.')
            isOk = false
          }
          break
        case 'rfSetupTmpr':
          cntrTypCd = frm.querySelector('#cntrTypCd_' + idx).value
          cgoTypCd = frm.querySelector('#cgoTypCd_' + idx).value

          if (cntrTypCd !== 'RF' && cntrTypCd !== 'RH') {
            return true
          }

          if (this.$ekmtcCommon.isEmpty(selector.value.trim()) && cgoTypCd !== '05') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.012'))
            isOk = false
          }
          break
        case 'pcupReqDt':
          if (this.$ekmtcCommon.isNotEmpty(selector.value.trim())) {
            let pcupReqDt = ''

            // 픽업일자 지정하지 않고 저장 버튼 누름 -> 픽업일자 지정 후 다시 저장 버튼 누르면
            // cntrInfo.pcupReqDt 값이 undefined로 넘어와 아래 isValidDate 에서 응답없이 튕기는 버그가 있어 방지하기 위한 코드임 2023.06.08
            if (cntrInfo.pcupReqDt === undefined) {
              pcupReqDt = selector.value.trim().replace(/\./g, '')
            } else {
              pcupReqDt = cntrInfo.pcupReqDt
            }

            const pcupReqDate = this.$ekmtcCommon.getStrToDate(pcupReqDt)
            const today = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
            const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate())

            if (this.$ekmtcCommon.isNotEmpty(cntrInfo.pcupReqDtOld)) {
              if (pcupReqDt.length === 8 && cntrInfo.pcupReqDtOld.length === 8 && pcupReqDt === cntrInfo.pcupReqDtOld) {
                return true
              }
            }

            if (!this.$ekmtcCommon.isValidDate(pcupReqDt, 8)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.bookingValidation.022'))
              return false
            }

            if (pcupReqDate < today1) {
              if (regParams.polShtmCd === '01' || regParams.polShtmCd === '03') {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.011'))
              } else {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.010'))
              }
              return false
            }

            if (this.$ekmtcCommon.isNotEmpty(regParams.cgoRest)) {
              const cgoDate = this.$ekmtcCommon.getStrToDate(regParams.cgoRest.substring(0, 8))
              if (cntrInfo.cntrTypCd === 'RF' || cntrInfo.cntrTypCd === 'RH') {
                if (this.$ekmtcCommon.isNotEmpty(cgoDate) && cgoDate <= pcupReqDate) {
                  this.$ekmtcCommon.alertDefault(cntrInfo.cntrTypCd + this.$t('msg.CSBK100.190')) // 컨테이너는 서류마감일 하루 전까지 입력 가능합니다.
                  this.$ekmtcCommon.showErrorTooltip(selector, cntrInfo.cntrTypCd + this.$t('msg.CSBK100.190'))
                  return false
                }
              } else {
                if (this.$ekmtcCommon.isNotEmpty(cgoDate) && cgoDate < pcupReqDate) {
                  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.006'))
                  return false
                }
              }
            }
          } else {
            const bkgPlcCd = this.memberDetail.bkgPlcCd
            if (PCUP_INPT_PSBL_PLC_CDS.indexOf(bkgPlcCd) >= 0) { // 픽업 정보 수정 가능한 지역만 체크하게
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.booking.021'))
              return false
            }
          }
          break
        case 'pcupCyNm':
          if (this.$ekmtcCommon.isEmpty(selector.value.trim()) || Number(selector.value) < 1) {
            const bkgPlcCd = this.memberDetail.bkgPlcCd
            if (PCUP_INPT_PSBL_PLC_CDS.indexOf(bkgPlcCd) >= 0) { // 픽업 정보 수정 가능한 지역만 체크하게
              this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input Pick up Place.')
              return false
            }
          }
          break
        case 'cgoTypCd':
          if (cntrInfo.cntrTypCd === 'OT' || cntrInfo.cntrTypCd === 'FR') {
            if (this.$ekmtcCommon.isEmpty(selector.value.trim()) || Number(selector.value) < 1) {
                this.$ekmtcCommon.showErrorTooltip(selector, 'Please Input Special Cargo Type')
                isOk = false
            }
          }
          break
      }

      return isOk
    },
    checkValidationActShprCstEmlAddr (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#srForm')
      }

      const elem = frm.querySelector('#actShprCstEmlAddr')
      const val = this.$ekmtcCommon.removeHangle(elem.value)
      let isOk = true
      let msg = ''

      if (val !== '' && !this.$ekmtcCommon.checkEmail(val)) {
        isOk = false
        msg = this.$t('msg.VOSD100.043')
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      this.regParams.actShprCstEmlAddr = val
      return isOk
    },
    checkValidationCneCstEmlAddr (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#srForm')
      }

      const elem = frm.querySelector('#cneCstEmlAddr')
      const val = this.$ekmtcCommon.removeHangle(elem.value)
      let isOk = true
      let msg = ''

      if (val !== '' && !this.$ekmtcCommon.checkEmail(val)) {
        isOk = false
        msg = this.$t('msg.VOSD100.043')
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      this.regParams.cneCstEmlAddr = val
      return isOk
    },
    checkValidationNtifCstEmlAddr (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#srForm')
      }

      const elem = frm.querySelector('#ntifCstEmlAddr')
      const val = this.$ekmtcCommon.removeHangle(elem.value)
      let isOk = true
      let msg = ''

      if (val !== '' && !this.$ekmtcCommon.checkEmail(val)) {
        isOk = false
        msg = this.$t('msg.VOSD100.043')
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      this.regParams.ntifCstEmlAddr = val
      return isOk
    },
    checkValidationIecPicEmlAddr (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#srForm')
      }

      const elem = frm.querySelector('#iecPicEmlAddr')
      const val = this.$ekmtcCommon.removeHangle(elem.value)
      let isOk = true
      let msg = ''

      if (val !== '' && !this.$ekmtcCommon.checkEmail(val)) {
        isOk = false
        msg = this.$t('msg.VOSD100.043')
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      this.regParams.iecPicEmlAddr = val
      return isOk
    },
    checkValidationDorOdrRmk (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#srForm')
      }

      const elem = frm.querySelector('#dorOdrRmk')
      const val = elem.value
      let isOk = true
      let msg = ''

      if (val !== '' && !this.$ekmtcCommon.checkTextByte(val, 2000)) {
        isOk = false
        msg = 'Too large text to create S/R.'
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    fnPodCtrCd (plcCd) {
      const params = {
        plcCd: plcCd
      }

      transCommon.getCtrInfo(params).then(res => {
        const result = res.data
        this.regParams.podCtrCd = result.ctrCd
        this.regParams.podPortNm = result.plcNm

        // this.regParams.podCtrCd = 'IR' // IR(이란) 테스트 하드코딩 (데이터가 없어서...)
      })
    },
    // inland 구간 pod 리턴
    fnPodPortSet (str) {
      try {
        const arr = str.split('@_@')
        const len = arr.length - 1
        let rtnPodCd = ''
        let col, tnsKndCd

        for (let i = 1; i < len; i++) {
          col = arr[i].split(':')
          tnsKndCd = col[8]
          if (tnsKndCd === '01' || tnsKndCd === '02' || tnsKndCd === '03' || tnsKndCd === '04') {
            rtnPodCd = col[3]
          }
        }
        return rtnPodCd
      } catch (e) {
        return ''
      }
    },
    fnChangeCtrCd (id, eventType) {
      const THIS = this
      let ctrCd = ''

      if (id === 'lstDestCtrCd') {
        ctrCd = this.regParams.lstDestCtrCd
        if (eventType === 'onchange') {
          this.regParams.lstDestPlcCd = ''
        }
      } else if (id === 'issCtrCd') {
        ctrCd = this.regParams.issCtrCd
        if (eventType === 'onchange') {
          this.regParams.issPlcCd = ''
        }
      }

      this.objPlace[id] = []

      if (ctrCd === '') {
        return
      }

      commons.getCommonsPlacesOfCountry(ctrCd)
        .then(res => {
          THIS.objPlace[id] = res.data
        })
    },
    addContainer () {
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.035'))
        return
      }

      const containerList = this.regParams.containerList
      const containerLen = containerList.length
      const newData = {
        cntrSeq: this.tempParams.cntrSeq++,
        cntrSzCd: '20',
        cntrQty: '',
        pcupReqDt: '',
        pcupReqTm: '0900',
        pcupReqDtBak: '',
        pcupReqDtOld: '',
        rfHpmgUrl: '',
        pickUpPlace: '',
        pcupCyCd: '',
        pcupCyNm: '',
        pickUpMan: '',
        pickUpTel: '',
        shprVanYn: this.regParams.emptyFlag === 'E' ? 'Y' : 'N',
        feCatCd: this.regParams.emptyFlag === 'E' ? 'E' : 'F',
        isCgoTypEmpty: true,
        cgoTypCd: '',
        cntrTypCd: 'GP',
        rfSetupTmpr: '',
        rfTmprUnitCd: 'C',
        cntrRfStsCd: '',
        containerDGList: [],
        chkNOR: '',
        subCntrTypList: [],
        subCgoTypList: [],
        apvNo: '',
        iotCntrYn: 'N'
      }
      this.setCntrTypList(newData)
      this.setCgoTypList(newData)

      containerList.push(newData)
    },
    removeContainer (idx) {
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.002'))
        return
      }

      if (document.querySelector('#cntrSzCd_' + idx).disabled) {
        return
      }

      const containerList = this.regParams.containerList
      containerList.splice(idx, 1)

      const containerLen = containerList.length

      if (containerLen === 0) {
        this.addContainer()
      }

      // this.fnChangeContSz()

      this.forceUpdate++
    },
    addTally () {
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.002'))
        return
      }
      const tallyList = this.regParams.tallyList
      const tallyLen = tallyList.length
      const newData = {
        tallCntSeq: this.tempParams.tallCntSeq++,
        tallCntNo: '',
        tallCntrSize: '20',
        tallCntrTypCd: 'GP',
        tallPkgCd: '',
        tallPkgQty: '',
        tallWeight: '',
        tallSealNo: ''
      }
      tallyList.push(newData)
    },
    removeTally (idx) {
      alert(this.disabledYn)
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.002'))
        return
      }

      if (document.querySelector('#tallyList_' + idx).disabled) {
        return
      }

      const tallyList = this.regParams.tallyList
      tallyList.splice(idx, 1)

      const tallyLen = tallyList.length

      if (tallyLen === 0) {
        this.addTally()
      }
    },
    // Description Information 추가
    addDescInfo () {
      const descInfoList = this.regParams.descInfoList

      const newData = {
        mrk: '',
        dscr: '',
        markInfoList: []
      }

      descInfoList.push(newData)
    },
    // Description Information 삭제
    removeDescInfo (idx) {
      const descInfoList = this.regParams.descInfoList
      descInfoList.splice(idx, 1)

      const descInfoLen = descInfoList.length

      if (descInfoLen === 0) {
        this.addDescInfo()
      }
    },
    // Mark Information 삭제
    addMrkPkg (vo) {
      const markInfoList = vo.markInfoList

      const newData = {
        pkgQty: '',
        pkgCd: '59',
        netWt: '',
        netCbm: '',
        mrkDsc: '',
        gdsDsc: ''
      }

      markInfoList.push(newData)
    },
    // Mark Information 삭제
    removeMrkPkg (vo, idx) {
      const markInfoList = vo.markInfoList
      markInfoList.splice(idx, 1)
    },
    // Term이 HPNT (Hyundai New Port Terminal), HJNC (Hanjin New Port) 이면 팝업 뛰움
    fnTermcheckHPNT (str) {
      const legTerm = str.split('@_@')
      const cnt = legTerm.length
      let legSplit

      for (let i = 0; i < cnt; i++) {
        legSplit = legTerm[i].split(':')

        if (legSplit[11] === 'HPNT' || legSplit[12] === 'HPNT' || legSplit[11] === 'HJNC' || legSplit[12] === 'HJNC') {
          this.regParams.legTermHPNT = 'Y'
          //this.fnMessageHPNT()
        }
      }
    },
    // 부산신항 HPNT Message
    fnMessageHPNT () {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: '닫기',
        title: this.$t('msg.CSBK100.092'), // - 홍콩 MIDSTREAM 기항 알림-
        message: this.$t('msg.CSBK100.093'), // * 고객님께서 선택하신 스케줄의 모선은 <font  color="#FF0000"> 홍콩 MIDSTREAM</font>을 기항합니다. <br><br>  스케줄 선택 시 유의하여 주시기 바랍니다.<br><br> 감사합니다.
        type: 'success'
      }
      this.$ekmtcCommon.alert(obj)
    },
    isPcupReqDtReadOnly () {
      //const arrPlcCd = ['TAO', 'XGG', 'LYG', 'NBO', 'DLC', 'XMN']
      const bkgPlcCd = this.memberDetail.bkgPlcCd
      //return !arrPlcCd.includes(bkgPlcCd)
      let isReadOnly = (PCUP_INPT_PSBL_PLC_CDS.indexOf(bkgPlcCd) === -1)
      return isReadOnly
    },
    checkIranValidationAll () {
      let isOk = true
      const prefix = ['actShprCstNm',
        'cneCstNm',
        // 'endUsrNm',
        'cmdtNm',
        'hsCd',
        'useDestCont',
        'actShprAddr',
        'cneCstAddr',
        'ntifCstNm',
        'ntifCstAddr'
      ]

      prefix.forEach(el => {
        if (!this.checkIranValidation(el)) {
          isOk = false
        }
      })

      this.endUserList.forEach((el, idx) => {
        if (!this.checkIranValidation('endUsrNm', idx)) {
          isOk = false
        }
      })

      return isOk
    },
    checkIranValidation (flag, idx) {
      let isOk = true
      const frm = document.querySelector('#srForm')
      const elemActShprCstNm = frm.querySelector('#ir_actShprCstNm')
      const elemCneCstNm = frm.querySelector('#ir_cneCstNm')
      //const elemNtifCstNm = frm.querySelector('#ntifCstNm')
      const elemEndUsrNm = frm.querySelector('#ir_endUsrNm_' + idx)
      const elemEndUsrAddr = frm.querySelector('#ir_endUsrAddr_' + idx)
      const elemHsCd = frm.querySelector('#ir_hsCd')
      const elemCmdtNm = frm.querySelector('#ir_cmdtNm')
      const elemNtifCstNm = frm.querySelector('#ir_ntifCstNm')

      let selector = frm.querySelector('#ir_' + flag)

      if (flag === 'endUsrNm' || flag === 'endUsrAddr') {
        selector = frm.querySelector('#ir_' + flag + '_' + idx)
      }

      this.$ekmtcCommon.hideErrorTooltip(selector)

      const selectorInfo = {
        actShprCstNm: { message: 'B/L SHIPPER.', upperCase: 'Y' },
        actShprAddr: { message: 'B/L SHIPPER ADDRESS.', upperCase: 'Y' },
        cneCstNm: { message: 'B/L CONSIGNEE.', upperCase: 'Y' },
        cneCstAddr: { message: 'B/L CONSIGNEE ADDRESS.', upperCase: 'Y' },
        ntifCstNm: { message: 'B/L NOTIFY.', upperCase: 'Y' },
        ntifCstAddr: { message: 'B/L NOTIFY ADDRESS.', upperCase: 'Y' },
        endUsrNm: { message: 'END USER.', upperCase: 'Y' },
        //endUsrAddr: { message: 'END USER ADDRESS.' },
        cmdtNm: { message: 'NAME OF COMMODITY.', upperCase: 'Y' },
        hsCd: { message: 'HS CODE.' },
        useDestCont: { message: 'PURPOSE OF USE.' }
      }

      if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
        this.$ekmtcCommon.showErrorTooltip(selector, 'Please input the ' + selectorInfo[flag].message)
        isOk = false
      }

      const elemActShprCstNmC = document.querySelector('#ir_actShprCstNmC')
      const elemCneCstNmC = document.querySelector('#ir_cneCstNmC')
      // const elemEndUsrNmC = document.querySelector('#ir_endUsrNmC')

      let elemEndUsrNmC = document.querySelector('#ir_actShprCstNmC')

      if (flag === 'endUsrNm') {
        elemEndUsrNmC = document.querySelector('#ir_endUsrNmC_' + idx)
      }

      const elemHsCdC = document.querySelector('#ir_hsCdC')
      const iranInfo = this.regParams.iranBookingInfo
      const elemCmdtNmC = document.querySelector('#ir_cmdtNmC')
      const elemNtifCstNmC = document.querySelector('#ir_ntifCstNmC')

      if (elemActShprCstNmC.style.display !== 'none' && (iranInfo.actShprCstNmC === 'Y' || iranInfo.actShprCstNmC === 'A')) {
        this.$ekmtcCommon.showErrorTooltip(elemActShprCstNm, 'Please Check the B/L SHIPPER.')
        isOk = false
      }

      if (elemCneCstNmC.style.display !== 'none' && (iranInfo.cneCstNmC === 'Y' || iranInfo.cneCstNmC === 'A')) {
        this.$ekmtcCommon.showErrorTooltip(elemCneCstNm, 'Please Check the NAME OF CONSIGNEE.')
        isOk = false
      }

      if (elemNtifCstNmC.style.display !== 'none' && (iranInfo.ntifCstNmC === 'Y' || iranInfo.ntifCstNmC === 'A')) {
        this.$ekmtcCommon.showErrorTooltip(elemNtifCstNm, 'Please Check the B/L NOTIFY.')
        isOk = false
      }

      if (elemEndUsrNmC.style.display !== 'none' && iranInfo.endUsrNmCList && (iranInfo.endUsrNmCList[idx] === 'Y' || iranInfo.endUsrNmCList[idx] === 'A')) {
        this.$ekmtcCommon.showErrorTooltip(elemEndUsrNm, 'Please Check the END USER.')
        isOk = false
      }

      if (elemHsCdC.style.display !== 'none' && this.tempParams.hsCdC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemHsCd, 'Please check HS Code.')
        isOk = false
      }
      if (elemCmdtNmC.style.display !== 'none' && this.tempParams.cmdtNmC === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(elemCmdtNm, 'Please check NAME OF COMMODITY.')
        isOk = false
      }

      if (isOk) {
        if (
          (elemActShprCstNmC.style.display !== 'none' && iranInfo.actShprCstNmC === 'A') ||
          (elemCneCstNmC.style.display !== 'none' && iranInfo.cneCstNmC === 'A') ||
          (elemEndUsrNmC.style.display !== 'none' && iranInfo.endUsrNmC === 'A')
        ) {
          const onConfirm = () => {
            isOk = false
          }

          // Saction List에 포함된 업체가 존재합니다.\n계속 진행하시겠습니까?
         // this.$ekmtcCommon.confirmCallBack(this.$t('msg.CSBK100.195'), onConfirm, undefined)
        }
      }

      return isOk
    },
    iranInputCheck (flag, idx) {
      const params = {
        kndCd: '',
        hsCd: '',
        cont: ''
      }

      const frm = document.querySelector('#srForm')
      const iranInfo = this.regParams.iranBookingInfo
      let selector = frm.querySelector('#ir_' + flag)

      if (flag === 'endUsrNm') {
        selector = frm.querySelector('#ir_' + flag + '_' + idx)
      }

      if (flag === 'actShprCstNm' || flag === 'cneCstNm' || flag === 'endUsrNm' || flag === 'ntifCstNm') {
        params.kndCd = '1'
        params.cont = selector.value
      } else if (flag === 'cmdtNm') {
        params.kndCd = 2
        params.cont = selector.value
      } else if (flag === 'hsCd') {
        params.kndCd = 3
        params.hsCd = selector.value
      }

      transCommon.getIranCheck(params).then(res => {
        const result = res.data

        let clsNm = result === 'Y' ? 'font-size:12px; color:red;font-weight: 700; display:' : 'font-size:12px; color: black;font-weight: 700;display:'

        if (result === 'R') {
          clsNm = ''
        }

        if (flag === 'actShprCstNm') {
          iranInfo.actShprCstNmC = result
        } else if (flag === 'cneCstNm') {
          iranInfo.cneCstNmC = result
        } else if (flag === 'endUsrNm') {
          iranInfo.endUsrNmCList[idx] = result
        } else if (flag === 'cmdtNm') {
          this.tempParams.cmdtNmC = result
        } else if (flag === 'hsCd') {
          this.tempParams.hsCdC = result
        } else if (flag === 'ntifCstNm') {
          iranInfo.ntifCstNmC = result
        }

        if (result === 'N') {
          clsNm = 'display: none'
        }

        let msgSelector = frm.querySelector('#ir_' + flag + 'C')
        if (flag === 'endUsrNm') {
          msgSelector = frm.querySelector('#ir_' + flag + 'C' + '_' + idx)
        }
        msgSelector.classList.remove(...msgSelector.classList)
        msgSelector.style = clsNm
      })
    },
    async checkSanction (flag) {
      let isOk = true

      const params = {
        kndCd: '',
        hsCd: '',
        cont: ''
      }

      const frm = document.querySelector('#srForm')
      const bookingInfo = this.regParams.bookingInfo
      let selector = frm.querySelector('#' + flag)

      params.kndCd = '1'
      params.cont = selector.value

      await transCommon.getIranCheck(params).then(res => {
        const result = res.data
        let msgSelector = frm.querySelector('#' + flag)

        if (result === 'Y') {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.115'))
          isOk = false
        }
      })

      return isOk
    },
    sameConsigneeName (e) {
      const iranInfo = this.regParams.iranBookingInfo
      if (e.target.checked) {
        iranInfo.endUsrNm = iranInfo.cneCstNm
      } else {
        iranInfo.endUsrNm = ''
      }
    },
    changeActShprCstNm (e) {
      this.$ekmtcCommon.onlyEngValue(e)
      this.regParams.iranBookingInfo.actShprCstNm = this.regParams.iranBookingInfo.actShprCstNm.toUpperCase()
    },
    fnValEngCheck (text) {
      const p = /[^a-zA-Z.~`!@#$%^&*()_+=|?\\'\\"?/><,-\\[\\]{};:^0-9\s]/g
      if (p.test(text) && text !== ' ') {
        return false
      }
      return true
    },
    fnIranHsCdMulti () {
      const bkgNo = this.regParams.bkgNo

      this.popParams = {
        bkgNo: bkgNo
      }

      this.selectFunc = ''
      this.openPopup('HsCodeIrPop')
    },
    fnContainerPop () {
      this.selectFunc = ''
      this.openPopup('ContainerPop')
    },
    fnIranUploadPop (type) {
      this.popParams = {
        reqRno: this.regParams.bkgNo,
        count: 0,
        type: type
      }

      this.selectFunc = ''
      this.openPopup('IranUploadPop')
    },
    async fnIranLOIDown () {
      //const fileName = 'Letter_of_Indemnity_IR.docx'
      const fileName = '(kmtc) Sanction LOI form.xlsx'

      const result = await bookings.excelDownload(fileName)
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.booking_new_sr_popup .popup_dim')
    },
    closePoup () {
      this.customComponent = null
      this.selectFunc = null
      this.$ekmtcCommon.layerClose('.booking_new_sr_popup .popup_dim')
    },
    openUploadPopup (type) {
      const THIS = this
      this.uploadType = type
      if (this.uploadType === 'chemical') {
        this.uploadParam = [...[], ...this.regParams.chemicalFileList]
      }

      this.uploadCallbackFunc = (fileList) => {
        if (THIS.uploadType === 'chemical') {
          THIS.regParams.chemicalFileList = [...[], ...fileList]
          THIS.checkValidationOne('chemicalYn')
        }
      }

      this.uploadComponent = 'doc-upload-pop'
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    isChemicalFileUpload () {
      if (this.regParams.chemicalFileList !== undefined && this.regParams.chemicalFileList.length > 0) {
        return true
      }
      return false
    },
    checkValidationFbAttach () {
      console.log('@@@@ enter checkValidationFbAttach')
      const regParams = this.regParams
      const kmdVslCheck = regParams.kmdVslCheck
      const containerList = regParams.containerList
      const len = containerList.length
      let c, flen
      let catCd, fbcat01, fbcat02, fbcat03, fbcat04, fbcat05

      for (let i = 0; i < len; i++) {
        c = containerList[i]
        if (c.cgoTypCd !== '06') {
          continue
        }

        fbcat01 = 'N'
        fbcat02 = 'N'
        fbcat03 = 'N'
        fbcat04 = 'N'
        fbcat05 = 'N'
        flen = c.uploadFileList !== undefined ? c.uploadFileList.length : 0

        if (flen === 0) {
          return 'N'
        }

        for (let j = 0; j < flen; j++) {
          catCd = c.uploadFileList[j].category
          if (catCd === '01') {
            fbcat01 = 'Y'
          } else if (catCd === '02') {
            fbcat02 = 'Y'
          } else if (catCd === '03') {
            fbcat03 = 'Y'
          } else if (catCd === '04') {
            fbcat04 = 'Y'
          } else if (catCd === '05') {
            fbcat05 = 'Y'
          }
        }

        if (kmdVslCheck === 'Y') {
          if (fbcat01 !== 'Y' || fbcat02 !== 'Y' || fbcat03 !== 'Y') {
            return 'N'
          }
        } else {
          if (fbcat01 !== 'Y' || fbcat02 !== 'Y' || fbcat03 !== 'Y' || fbcat04 !== 'Y' || fbcat05 !== 'Y') {
            return 'N'
          }
        }
      }

      return 'Y'
    },
    async rtnPhbtChrCheck () {
      let flag = 'ADD'
      if (this.act !== 'R') {
        flag = 'EDIT'
      }
      const regParams = this.regParams
      const etd = this.$ekmtcCommon.isNotEmpty(regParams.etd) ? regParams.etd.substring(0, 8) : ''
      const eta = this.$ekmtcCommon.isNotEmpty(regParams.eta) ? regParams.eta.substring(0, 8) : ''
      const params = {
        bkgNo: regParams.bkgNo,
        etd: etd,
        eta: eta,
        porCtrCd: regParams.polCtrCd,
        porPortCd: regParams.polPortCd,
        polCtrCd: regParams.polCtrCd,
        polPortCd: regParams.polPortCd,
        podCtrCd: regParams.podCtrCd,
        podPortCd: regParams.podPortCd,
        podTrmlCd: regParams.podTrmlCd,
        dlyCtrCd: regParams.podCtrCd,
        dlyPortCd: regParams.podPortCd,
        hidCS008I: regParams.hidLegInfo,
        cmdtDsc: regParams.oprRmk,
        scheduleEditYn: regParams.scheduleEditYn,
        uiFlag: flag
      }

      let isOk = true

      await trans.getRtnPhbtChrChk(params).then(async res => {
        const result = res.data

        if (result.rtnCode === 'Y') {
          this.$ekmtcCommon.alertDefault(result.rtnMessage)
          isOk = false
        } else if (result.rtnCode === 'A') {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: result.rtnMessage, useConfirmBtn: true })) {
            isOk = false
          }
        }
      })

      return isOk
    },
    async checkValidationDGVol () {
      const regParams = this.regParams
      const containerList = regParams.containerList
      const len = containerList.length
      const params = {
        vslCd: regParams.vslCd,
        voyNo: regParams.voyNo,
        polPortCd: regParams.polPortCd,
        podPortCd: regParams.podPortCd,
        slotExchCsNo: 'KMD',
        cntrQty: '',
        cntrSzCd: '',
        imdun: '',
        cls: ''
      }

      let dgVolErrYn = 'N'
      let c, dgList, dgLen

      for (let i = 0; i < len; i++) {
        c = containerList[i]
        if (c.cgoTypCd !== '01') {
          continue
        }

        params.cntrQty = c.cntrQty
        params.cntrSzCd = c.cntrSzCd

        dgList = c.containerDGList
        dgLen = c.containerDGList.length

        for (let j = 0; j < dgLen; j++) {
          params.imdum = dgList[j].imdunNo
          params.cls = dgList[j].imoClasCd

          await transCommon.getDgVolumnCheck(params)
            .then(async res => {
              dgVolErrYn = res.data
            })

          if (dgVolErrYn === 'Y') {
            break
          }
        }
      }

      return dgVolErrYn
    },
    closeUploadPopup () {
      console.log('close -> file popup')
      // this.uploadComponent = null
      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    scheduleTest (e) {
      const val = e.target.value

      if (val === '') {
        return
      }
      const vo = this.testData.schedule.find(vo => vo.key === val)

      if (vo === undefined) {
        return
      }

      const arr = vo.val.split(';')

      const testObj = {
        vslCd: arr[0], // [0]선명코드
        voyNo: arr[1], // [1]항차
        vslNm: arr[2], // [2]선명
        polName: arr[3], // [3]출발지 포트명
        podName: arr[4], // [4]도착지 포트명
        etd: arr[5], // [5]입항일
        etdTm: arr[6], // [6]입항시간
        eta: arr[7], // [7]출항일
        etaTm: arr[8], // [8]출항시간
        polPortCd: arr[9], // [9]출발지 포트코드
        podPortCd: arr[10], // [10]도착지 포트코드
        polCtrCd: arr[11], // [11]출발지 국가코드
        podCtrCd: arr[12], // [12]도착지 국가코드
        cct: arr[13], // [13] ?
        docCls: arr[14], // [14]서류마감
        docPic: arr[15], // [15]서류마감 담당자
        polTrmlCd: arr[16], // [16]출발지 터미널 코드
        podTrmlCd: arr[17], // [18]도착지 터미널 코드
        mrnNo: arr[18], // [18]MRN Number
        callSign: arr[19], // [19]Call sign
        hidCS008I: arr[20], // [20]info (@_@로 붙어있는 정보)
        mfCls: arr[21], // [21]EDI 신고마감
        cgoCls: arr[22], // [22]반입마감
        cgoPic: arr[23], // [23]반입마감 담당자
        finalEta: arr[24], // [24]최종ETA
        cfsCls: arr[25], // [25]cfs 반입마감
        cfsPic: arr[26], // [26]cfs 반입마감 담당자
        bkgCloseYn: arr[27], // [27] booking close 여부
        prrmSgEtd: arr[28], // [28] ?
        polTrmlNm: arr[29], // [29] 출발지 터미널명
        railCheck: arr[30], // [30] rail booking 여부 ?
        frtAppNo: arr[31], // [31] 운임 APPLICATION 번호
        logDtm: arr[32], // [32]
        srRest: arr[33], // [33] SR 생성여부
        localDtm: arr[34], // [34] 현재 시간
        vslRest: arr[35], // [35] 선박 close 여부
        cgoRest: arr[36] // [36] 반입 마감시한
      }

      this.setScheduleCommon(testObj)
    },
    demDetInfoPop () {
      let isOk = true
      const prefix = ['cntrTypCd', 'cntrQty', 'pcupReqDt']
      const regParams = this.regParams
      const containerList = regParams.containerList

      containerList.forEach((c, idx) => {
        prefix.forEach(el => {
          const result = this.checkValidationCntr(el, idx, 'KEYUP')
          if (!result) {
            isOk = false
          }
        })
      })

      if (isOk) {
        const arrParamCntr = []
        containerList.forEach((c, idx) => {
          const obj = {
            cgoTypCd: c.cgoTypCd,
            cntrQty: c.cntrQty,
            cntrTypCd: c.cntrTypCd,
            cntrSzCd: c.cntrSzCd,
            pcupReqDt: this.$ekmtcCommon.changeDatePattern(c.pcupReqDt, '-')
          }
          arrParamCntr.push(obj)
        })

        const cgoCls = this.$ekmtcCommon.isNotEmpty(this.cgoCls) ? this.cgoCls.substring(0, 8) : ''

        this.popParams = {
          containerList: arrParamCntr,
          etd: this.$ekmtcCommon.changeDatePattern(cgoCls, '-'),
          ctrCd: regParams.polCtrCd,
          portCd: regParams.polPortCd
        }

        this.selectFunc = ''
        this.openPopup('DemDetCalculatorPop')
      }
    },
    fnCommonBtnAction (type) {
      if (type === 'TRD_SHIPPER') {
        this.commonBLParams = {
          action: 'TRD_SHIPPER',
          srRno: this.pageSrRno,
          bkgNo: this.regParams.bkgNo,
          reqCatCd: '22'
        }
        this.commonBLCallback = this.setShipperRegInfo
      } else if (type === 'POA_SHIPPER') {
        this.commonBLParams = {
          action: 'POA_SHIPPER',
          srRno: this.pageSrRno,
          bkgNo: this.regParams.bkgNo,
          reqCatCd: '23'
        }
        this.commonBLCallback = this.setShipperPoaInfo
      } else if (type === 'TRD_SHIPPER_FILE') {
        this.commonBLParams = {
          action: 'TRD_POA_SHIPPER_FILE',
          bkgNo: this.regParams.bkgNo,
          reqCatCd: '22',
          trdFileInfo: this.regParams.trdShipperFile[0]
        }
      } else if (type === 'POA_SHIPPER_FILE') {
        this.commonBLParams = {
          action: 'TRD_POA_SHIPPER_FILE',
          bkgNo: this.regParams.bkgNo,
          reqCatCd: '23',
          poaFileInfo: this.regParams.poaShipperFile[0]
        }
      } else {
        this.commonBLParams = {
          action: 'LOG',
          srRno: this.pageSrRno,
          bkgNo: this.regParams.bkgNo
        }
      }
      this.$nextTick(() => {
        this.$refs.commonBL.doAction()
      })
    },
    setShipperRegInfo (vo) {
      if (this.$ekmtcCommon.isNotEmpty(vo.reqRno)) {
        this.saveShipperLOI = 'Y'
        this.regParams.trdShipperFile = [] // 조회된 파일정보 Clear (재확인 하기 위해)
      }
    },
    setShipperPoaInfo (vo) {
      if (this.$ekmtcCommon.isNotEmpty(vo.reqRno)) {
        this.saveShipperPOA = 'Y'
        this.regParams.poaShipperFile = [] // 조회된 파일정보 Clear (재확인 하기 위해)
      }
    },
    fnCopy () {
      const inp = document.createElement('input')
      document.body.appendChild(inp)
      inp.value = this.detailBkgNo//this.regParams.bkgNo
      inp.select()
      document.execCommand('copy')
      document.body.removeChild(inp)
      this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.090'))
    },
    addEndUser () {
      const endUserList = this.endUserList
      const seq = endUserList.length === 0 ? 1 : Number(endUserList[endUserList.length - 1].seq) + 1
      const obj = {
        seq: seq,
        hsCd: ''
      }

      this.endUserList.push(obj)
    },
    checkShpperPOA () {
      if (this.$ekmtcCommon.isNotEmpty(this.detailBkgNo)) {
        const regParams = this.regParams

        const shprCstAddr = regParams.shprCstAddr.replace(/ /g, '')
        const ctrInfo = this.ctrCdList.filter(vo => vo.ctrCd === regParams.polCtrCd)[0]

        if (ctrInfo !== undefined) {
          const ctrNm = ctrInfo.ctrEnm.toUpperCase().replace(/ /g, '')
          if (shprCstAddr.indexOf(ctrNm) === -1) {
            this.isShipperLOI = true
          } else {
            this.isShipperLOI = false
          }
        }

        if (regParams.polCtrCd === 'CN') {
          const shprCstEnm = regParams.shprCstEnm.replace(/ /g, '')

          if (shprCstEnm.includes('O/B') || shprCstEnm.includes('ONBEHALFOF') ||
            shprCstEnm.includes('C/O') || shprCstEnm.includes('CAREOF') ||
            shprCstEnm.includes('ASAGENTOF') || shprCstAddr.includes('O/B') || shprCstAddr.includes('ONBEHALFOF') ||
            shprCstAddr.includes('C/O') || shprCstAddr.includes('CAREOF') ||
            shprCstAddr.includes('ASAGENTOF')) {
            this.isShipperPOA = true
          } else {
            this.isShipperPOA = false
          }
        }
      }

      this.checkSanction('shprCstEnm')
    },
    removeEndUser (idx) {
      const endUserList = this.endUserList

      endUserList.splice(idx, 1)

      // this.endUserList.forEach((h, i) => {
      //   this.checkValidation(i)
      // })
    },
    checkValidationIranEndUsrEmlAddr (idx, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#srForm')
      }

      const elem = frm.querySelector('#' + idx)
      const val = this.$ekmtcCommon.removeHangle(elem.value)
      let isOk = true
      let msg = ''

      if (val !== '' && !this.$ekmtcCommon.checkEmail(val)) {
        isOk = false
        msg = this.$t('msg.VOSD100.043')
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      this.regParams.actShprCstEmlAddr = val
      return isOk
    },
   async collectHcneeSet () {
      let rtn = true
      if (this.$ekmtcCommon.isNotEmpty(this.regParams.soNo) && this.regParams.frtPncCd === 'C') {
       await srInquiry.getCollectFrtAppNoHcnee(this.regParams).then(res => {
          console.log(res)
          if (this.$ekmtcCommon.isEmpty(res.data)) {
             this.$ekmtcCommon.alertDefault('该约号已失效，请联系目的港。')
             rtn = false
          } else {
              this.regParams.hcneCstNo = res.data.hcneCstNo
              this.regParams.frtAppNo = res.data.frtAppNo
             rtn = true
          }
        }).catch(() => {
           rtn = true
        })
        return rtn
      }
      return true
    },
    fnFrtPncChange () {
      if (this.regParams.frtPncCd === 'P') {
        this.regParams.soNo = ''
        if (document.querySelector('#soNo')) {
         document.querySelector('#soNo').value = ''
        }
      }
      this.findIotCntrYn('S')
    },
    findIotCntrYn (flag, idx, _cvo) {
    if (flag === 'C') {
      const params = {
        bkgCtrCd: this.regParams.vslCd,
        polCtrCd: this.regParams.polCtrCd,
        podCtrCd: this.regParams.podCtrCd,
        polPortCd: this.regParams.polPortCd,
        podPortCd: this.regParams.podPortCd,
        cntrTypCd: _cvo.cntrTypCd,
        cntrSzCd: _cvo.cntrSzCd,
        cgoTypCd: _cvo.cgoTypCd,
        frtPncCd: this.regParams.frtPncCd,
        frtAppNo: this.regParams.soNo
      }
      bookings.bkgIotYn(params).then((res) => {
        const result = res.data
        if (result !== undefined && result === 'Y') {
          _cvo.iotCntrYn = 'Y'
        } else {
          _cvo.iotCntrYn = 'N'
        }
      })
    } else {
     this.regParams.containerList.forEach((c, index) => {
        const params = {
          bkgCtrCd: this.regParams.vslCd,
          polCtrCd: this.regParams.polCtrCd,
          podCtrCd: this.regParams.podCtrCd,
          polPortCd: this.regParams.polPortCd,
          podPortCd: this.regParams.podPortCd,
          cntrTypCd: this.regParams.containerList[index].cntrTypCd,
          cntrSzCd: this.regParams.containerList[index].cntrSzCd,
          cgoTypCd: this.regParams.containerList[index].cgoTypCd,
          frtPncCd: this.regParams.frtPncCd,
          frtAppNo: this.regParams.soNo
        }
        if (this.regParams.containerList[index].cntrTypCd === 'RF' || this.regParams.containerList[index].cntrTypCd === 'RH') {
           bookings.bkgIotYn(params).then((res) => {
            const result = res.data
            if (result !== undefined && result === 'Y') {
             c.iotCntrYn = 'Y'
            } else {
             c.iotCntrYn = 'N'
            }
          })
         }
      })
    }
  },
  async checkIotYn (e, vo, idx) {
   if (PCUP_INPT_PSBL_PLC_CDS.indexOf(this.memberDetail.bkgPlcCd) >= 0) { // 픽업 정보 수정 가능한 지역만 체크하게
      vo.pcupCyNm = ''
      vo.pcupCyCd = ''

      const params = {
        bkgCtrCd: this.regParams.vslCd,
        polCtrCd: this.regParams.polCtrCd,
        podCtrCd: this.regParams.podCtrCd,
        polPortCd: this.regParams.polPortCd,
        podPortCd: this.regParams.podPortCd,
        cntrTypCd: vo.cntrTypCd,
        cntrSzCd: vo.cntrSzCd,
        cgoTypCd: vo.cgoTypCd,
        frtPncCd: this.regParams.frtPncCd,
        frtAppNo: this.regParams.soNo
      }

      await bookings.getIotUseYn(params).then((res) => {
        const result = res.data
        if (result !== undefined && result === 'N') {
          this.$ekmtcCommon.alertCallback(this.$t('js.booking.041'), function () {
            e.target.disabled = true
            e.target.checked = false
          })
        } else {
          e.target.disabled = false
        }
      })
   }
  },
  fnVocPop (bkgNo) {
      if (this.vocYn === 'N') {
        const url = '#/open-popup/voc-pop?docNo=' + bkgNo
        const method = 'GET'

        let form = $('<form>')
          .attr({ target: 'vocPop', method: method, action: url })
          .appendTo(document.body)

        let p = window.open(
          '',
          'vocPop',
          'width=480,height=343,menubar=no,status=status,scrollbars=yes resizable=yes'
        )

        form.submit()
      }
  },
  fnChangeContSz () {
      let dscr = this.regParams.descInfoList[0].dscr
      let flagChange = 'N'
      let cn2 = ''
      let cn4 = ''
      let cn5 = ''

      let sz20 = 0
      let sz40 = 0
      let sz45 = 0
      let cntr20Qty = 0
      let cntr40Qty = 0
      let cntr45Qty = 0
      let retValue = ''

      let arrDscr = []

      if (this.$ekmtcCommon.isNotEmpty(dscr)) {
        arrDscr = dscr.split('\n')
      } else {
        dscr = ' '
      }

      if (arrDscr.length > 0) {
        const szInfo = arrDscr[0]
        if (szInfo.indexOf('X20\'') > -1) {
          cn2 = szInfo.split('X20\'')[1]
          if (arrDscr[1].indexOf('X40\'') > -1) {
            cn4 = dscr.split('\n')[1].split('X40\'')[1]
          }
        } else if (szInfo.indexOf('X40\'') > -1) {
          cn4 = szInfo.split('X40\'')[1]
        } else if (szInfo.indexOf('X45\'') > -1) {
          cn5 = szInfo.split('X45\'')[1]
        }
      }

      if (!cn2) cn2 = ''
      if (!cn4) cn4 = ''
      if (!cn5) cn5 = ''

      const regParams = this.regParams
      const containerList = regParams.containerList

      containerList.forEach(c => {
        if (c.cntrQty !== '' && c.cntrSzCd !== '' && c.cntrTypCd !== '') {
          if (c.cntrSzCd === '20') {
            sz20++
            cntr20Qty += parseInt(c.cntrQty)
          } else if (c.cntrSzCd === '40') {
            sz40++
            cntr40Qty += parseInt(c.cntrQty)
          } else if (c.cntrSzCd === '45') {
            sz45++
            cntr45Qty += parseInt(c.cntrQty)
          }
        }
      })

      if (sz20 > 0) {
        retValue += cntr20Qty + 'X20\'' + cn2 + '\n'
      }

      if (sz40 > 0) {
        retValue += cntr40Qty + 'X40\'' + cn4 + '\n'
      }

      if (sz45 > 0) {
        retValue += cntr45Qty + 'X45\'' + cn5 + '\n'
      }

      let cntrDscrLen = 0
      let calCdscr = ''
      let pkgDscrVal = ''
      let jj = ''
      let text = ''

      arrDscr.forEach((item, i) => {
          if ((item.indexOf('X20') !== -1 || item.indexOf('X40') !== -1 || item.indexOf('X45') !== -1)) {
            ++cntrDscrLen
          } else {
            if (parseInt(item.substring(0, 1).charAt(0)) >= 0 && parseInt(item.substring(0, 1).charAt(0)) <= 9) {
              // Package 수량 체크는 끝 라인까지만확인
              if (i < 4 && flagChange === 'N') {
                calCdscr = dscr.replace(item, retValue)
                pkgDscrVal += item
                flagChange = 'Y'
              } else {
                // 기존값 숫자 있을때
                text += '\n' + item
              }
            } else {
              // 기존값 문자 있을때
              text += '\n' + item
            }

            // if (i !== (d.length - 1)) {
            //   jj += '\n'
            // }
          }
      })

      if (arrDscr.length === cntrDscrLen) {
        calCdscr = retValue + pkgDscrVal
      } else {
        if (this.$ekmtcCommon.isNotEmpty(retValue)) {
          calCdscr = retValue + pkgDscrVal + text
        } else {
          if (this.$ekmtcCommon.isNotEmpty(pkgDscrVal)) {
            calCdscr = pkgDscrVal + text
          } else {
            calCdscr = retValue + text
          }
        }
      }

      regParams.descInfoList[0].dscr = calCdscr
    },
    fnPackage (actionFlag) {
      const regParams = this.regParams
      const pkgQty = regParams.pkgQty
      const pkgCd = regParams.pkgCd

      const pkgInfo = this.commonCodes['01130'].filter(vo => vo.cd === pkgCd)[0]

      let retValue = ''
      let pkgNm = ''

      if (pkgInfo !== undefined) {
        pkgNm = pkgInfo.cdNm
      }

      let flagChange = 'N'
      let cntrDscrVal = ''
      let cntrDscrLen = 0
      let jj = ''
      let calCdscr = ''
      let text = ''

      const dscr = this.regParams.descInfoList[0].dscr
      // regParams.descInfoList[0].dscr = dscr.replace(this.pkgInfo, '')

      if (this.$ekmtcCommon.isNotEmpty(pkgQty) && this.$ekmtcCommon.isNotEmpty(pkgCd)) {
        retValue = this.$ekmtcCommon.changeNumberFormat(pkgQty, { isComma: true }) + pkgNm
      }

      const arrDscr = dscr.split('\n')
      var str = arrDscr[0].replace(new RegExp('[(0-9)]', 'gi'), '').trim()
      let pkgCodes = []

      if (str.indexOf(',') === 0) {
        str = str.replace(',', '').trim()
      }

      for (const code of this.commonCodes['01130']) {
        pkgCodes.push(code.cdNm)
      }

      if (str !== '') {
        if (pkgCodes.includes(str)) {
          arrDscr.splice(0, 1)
          arrDscr.unshift(retValue)
        } else {
          str = str.replace(new RegExp(',', 'g'), '')
          arrDscr.unshift(retValue)
        }
      } else {
        // 숫자만 일때,
        arrDscr.unshift(retValue)
      }

      arrDscr.forEach((item, i) => {
          if ((item.indexOf('X20') !== -1 || item.indexOf('X40') !== -1 || item.indexOf('X45') !== -1 ||
              item.indexOf('X 20') !== -1 || item.indexOf('X 40') !== -1 || item.indexOf('X 45') !== -1)) {
            cntrDscrVal += item + '\n'
            ++cntrDscrLen
          } else {
            if (i === 0) {
              text += item
            } else {
              text += '\n' + item
            }
          }
      })

      if (arrDscr.length === cntrDscrLen) {
        calCdscr = cntrDscrVal + retValue
      } else {
        if (this.$ekmtcCommon.isNotEmpty(retValue)) {
          calCdscr = cntrDscrVal + text
        } else {
          calCdscr = dscr
        }
      }

      regParams.descInfoList[0].dscr = calCdscr

      if (actionFlag === 'CHANGE') {
        this.pkgInfo = retValue
      }
    },
    initPkgInfo () {
      const regParams = this.regParams
      const pkgQty = this.regParams.pkgQty
      const pkgCd = this.regParams.pkgCd

      const pkgInfo = this.commonCodes['01130'].filter(vo => vo.cd === pkgCd)[0]
      let pkgNm = ''

      if (pkgInfo !== undefined) {
        pkgNm = pkgInfo.cdNm
      }

      if (this.$ekmtcCommon.isNotEmpty(pkgQty) && this.$ekmtcCommon.isNotEmpty(pkgCd)) {
        this.pkgInfo = this.$ekmtcCommon.changeNumberFormat(pkgQty, { isComma: true }) + pkgNm
      }
    },
    getBkgShpr () {
      const params = {
        bkgShprCstNo: this.$ekmtcCommon.isNotEmpty(this.regParams.bkgShprCstNo) ? this.regParams.bkgShprCstNo : '',
        bkgNo: this.$ekmtcCommon.isNotEmpty(this.regParams.bkgNo) ? this.regParams.bkgNo : ''
      }

      srInquiry.getBkgShpr(params).then(res => {
        const data = res.data
        // console.log('getBkgShpr data @@@@@@ ', data)

        const bkgShprRk = data.bkgShprRk
        this.regParams.bkgShprCstNo = data.bkgShprCstNo
        this.regParams.bkgShprCstEnm = data.bkgShprCstEnm

        if (bkgShprRk !== '1') {
          this.$ekmtcCommon.alertDefault(this.$t('js.booking.042'))
        }
      })
    },
    fnCheckLoiDownEnable (vo) {
      const params = {
        polPortCd: vo.polPortCd,
        podPortCd: vo.podPortCd,
        vslCd: vo.vslCd,
        voyNo: vo.voyNo
      }

      bookings.checkLoiDownEnable(params).then((res) => {
        const result = res.data
        if (result !== undefined && result === '0') {
          this.loiDownYn = 'Y'
        } else {
          this.loiDownYn = 'N'
        }
      })
    }
 }
}
</script>

<style scoped>
  .div_req_byte {text-align:right; margin-top:2px;}
  .wid50p {width:50% !important;}
  .readonly {background: #f6f6f6;color: #b7b7b7;}
  .tooltip_wrap {min-width:250px; right:0; top:24px; display:none; text-align: left;}
</style>
